import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaginationQueryConfig } from "api/models";
import { InsightsFilters } from "./InsightsFilters";
import { PRIVATE_ROUTES } from "../../router/Router.config";
import { TabFilter } from "./InsightsProjectTabFilter";
import { TabScreen } from "../../ui/tabs/TabScreen";
import { InsightActivitiesTable } from "./InsightActivitiesTable";
import { InsightSalesTable } from "./InsightSalesTable";
import { ProjectFilter } from "../../projects/projectType";
import {
  applyPaginationFiltersToSearch,
  onParamChangeHandler,
  parseSearch,
} from "../../api/paginationQueryHandler";
import { useHistory, useLocation } from "react-router-dom";
import { useParamsState } from "../../api/paginationHelpers";
import qs from "query-string";
import ErrorFallback from "ui/ErrorFallback";
import { InsightsProjectSteps } from "../InsightsUtils";
import Tour from "../../ui/tour/tour";
import { MenuTutorialPage } from "ui/tour/tourConstant";

const { ErrorBoundary } = require("react-error-boundary");

export enum TabOptions {
  Activities = "activities",
  Sales = "sales",
}

const TAB_OPTIONS = Object.values(TabOptions);

export const InsightsProjectScreen = () => {
  const { t } = useTranslation();
  const steps = InsightsProjectSteps(t);
  const location = useLocation();
  const history = useHistory();
  const [tab, setTab] = useState<TabOptions>();
  const [params, setParams] = useParamsState(location.search);

  const selectedFilters = useMemo(() => {
    const values = parseSearch(location.search);
    return values as ProjectFilter;
  }, [location.search]);

  const handleTabChange = useCallback(
    (newTab: string) => {
      if (tab !== newTab) {
        setParams({});
        setTab(newTab as TabOptions);
      }
    },
    [setParams, tab]
  );

  const onQueryParametersReceived = useCallback(
    (config: PaginationQueryConfig) => {
      applyPaginationFiltersToSearch(
        location.search,
        location.pathname,
        history,
        config
      );
    },
    [history, location.pathname, location.search]
  );
  const tabExtraContentActivities = useMemo(
    () => (
      <TabFilter
        onParamsChange={onQueryParametersReceived}
        tab={TabOptions.Activities}
      />
    ),
    [onQueryParametersReceived]
  );

  const tabExtraContentSales = useMemo(
    () => (
      <TabFilter
        onParamsChange={onQueryParametersReceived}
        tab={TabOptions.Sales}
        allowClear={false}
      />
    ),
    [onQueryParametersReceived]
  );

  const handleQueryStringUpdate = useCallback(
    (values: PaginationQueryConfig) => {
      onParamChangeHandler(
        location.search,
        location.pathname,
        history,
        { ...selectedFilters, ...values },
        newValues => {
          setParams(newValues);
        }
      );
    },
    [history, location.pathname, location.search, selectedFilters, setParams]
  );

  useEffect(() => {
    setParams(qs.parse(location.search, { arrayFormat: "comma" }));
  }, [location.search, setParams]);

  const tabDefinitions = useMemo(
    () => [
      {
        localizedTabKey: "insightsProject.tabActivities",
        key: TabOptions.Activities,
        Component: InsightActivitiesTable,
        extraProps: {
          params,
          onQueryParametersReceived: handleQueryStringUpdate,
        },
      },
      {
        localizedTabKey: "insightsProject.tabSales",
        key: TabOptions.Sales,
        Component: InsightSalesTable,
        extraProps: {
          params,
          onQueryParametersReceived: handleQueryStringUpdate,
        },
      },
    ],
    [handleQueryStringUpdate, params]
  );
  const filterContent = useMemo(
    () => (
      <InsightsFilters
        onQueryParametersReceived={onQueryParametersReceived}
        selectedFilters={selectedFilters}
      />
    ),
    [onQueryParametersReceived, selectedFilters]
  );
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Tour steps={steps} id={MenuTutorialPage.INSIGHTS_PROJECT_SCREEN} />
      <div className="tour-step-main">
        <TabScreen
          subHeaderProps={{
            title: t("insightsProject.headerTitle"),
          }}
          filterContent={filterContent}
          routePath={PRIVATE_ROUTES.INSIGHTS_PROJECT_SCREEN.path}
          tabDefinitions={tabDefinitions}
          tabProps={{
            tabBarExtraContent:
              tab === TabOptions.Sales
                ? tabExtraContentSales
                : tabExtraContentActivities,
          }}
          tabOptions={TAB_OPTIONS}
          defaultTab={TabOptions.Activities}
          onTabChange={handleTabChange}
        />
      </div>
    </ErrorBoundary>
  );
};
