import { OrganizationOffices } from "../identity/permissions/permissionConst";

export const ViewCurrencies = [OrganizationOffices.READ];

export const CreateModifyCurrencies = [
  OrganizationOffices.READ,
  OrganizationOffices.WRITE,
];

export const DeleteCurrencies = [OrganizationOffices.DELETE];

export const ViewCurrencyExchangeRates = [OrganizationOffices.READ];

export const CreateModifyCurrencyExchangeRates = [
  OrganizationOffices.READ,
  OrganizationOffices.WRITE,
];

export const DeleteCurrencyExchangeRates = [OrganizationOffices.DELETE];
