import React, { FunctionComponent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DateFormats, Periods } from "../../date/dateConst";
import DateFilter, { DateFilterDate } from "../../ui/DateFilter/DateFilter";
import moment from "moment";
import { getPeriodFilters } from "../../date/dateFilters";
import { getStartEndDates } from "../../date/dateHelper";
import { TabOptions } from "./InsightsProjectScreen";
import { PaginationQueryConfig } from "../../api/models";
import { parseSearch } from "../../api/paginationQueryHandler";
import { useLocation } from "react-router-dom";

interface Props {
  tab: TabOptions;
  onParamsChange: (config: PaginationQueryConfig) => void;
  allowClear?: boolean;
}

const PERIOD_SALES = [Periods.ALL, Periods.WEEK, Periods.MONTH, Periods.YEAR];
const PERIOD_ACTIVITIES = [Periods.CUSTOM];

export const TabFilter: FunctionComponent<Props> = ({
  tab,
  onParamsChange,
  allowClear = true,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const defaultDate: DateFilterDate = useMemo(() => {
    switch (tab) {
      case TabOptions.Activities:
        return [moment().add(-1, "days"), moment()];
      case TabOptions.Sales:
        return moment();
    }
  }, [tab]);
  const date: DateFilterDate | undefined = useMemo(() => {
    const params = parseSearch(location.search);
    switch (tab) {
      case TabOptions.Activities:
        return params.from && params.to
          ? [
              moment(params.from, DateFormats.API_DATE),
              moment(params.to, DateFormats.API_DATE),
            ]
          : defaultDate;
      case TabOptions.Sales:
        return params.from
          ? moment(params.from, DateFormats.API_DATE)
          : defaultDate;
    }
  }, [defaultDate, location.search, tab]);
  const period: Periods = useMemo(() => {
    const params = parseSearch(location.search);
    switch (tab) {
      case TabOptions.Activities:
        return Periods.CUSTOM;
      case TabOptions.Sales:
        return (params.period_length as Periods) || Periods.ALL;
    }
  }, [location.search, tab]);
  const periods = useMemo(() => {
    const options = tab === TabOptions.Sales ? PERIOD_SALES : PERIOD_ACTIVITIES;
    return getPeriodFilters({
      t,
      period,
      options: options,
    });
  }, [t, period, tab]);
  const onDateChange = useCallback(
    (date: DateFilterDate | null, periodOverride?: Periods) => {
      // utilize periodOverride to handle period change and state
      // not getting the latest
      const newPeriod = periodOverride || period;
      // if all time, dont use from and to.
      if (newPeriod === Periods.ALL) {
        onParamsChange({
          period_length: tab === TabOptions.Sales ? newPeriod : undefined,
        });
      } else {
        const { startDate, endDate } = getStartEndDates(date, newPeriod, true);
        if (startDate && endDate) {
          onParamsChange({
            from: startDate,
            to: endDate,
            period_length: tab === TabOptions.Sales ? newPeriod : undefined,
          });
        }
      }
    },
    [period, onParamsChange, tab]
  );
  const onPeriodChange = useCallback(
    (period: Periods) => {
      // when a period changes, reset the date.
      onDateChange(defaultDate, period);
    },
    [onDateChange, defaultDate]
  );
  return (
    <DateFilter
      onDateChange={onDateChange}
      onPeriodChange={onPeriodChange}
      periods={periods}
      defaultDate={date}
      onlyInPast={true}
      allowClear={allowClear}
    />
  );
};
