import * as rolesApi from "roles/rolesApi";
import { DEFAULT_API_PARAMS } from "api/apiConst";
import { PaginationQueryParams } from "api/models";
import {
  capturedLoadingRematchModel,
  CapturedState,
  removeCapturedListItem,
  updateCapturedLoadingModel,
} from "../loading";
import { SettingsParams } from "../settings/settingsConst";
import { RoleType } from "./roleType";

/**
 * Project roles.
 */
export const projectRolesModel = capturedLoadingRematchModel(
  "projectRolesModel",
  async () => await rolesApi.getProjectAllRoles(),
  {
    reducers: {
      removeRole: (state, roleId: number) => {
        return {
          ...state,
          model: removeCapturedListItem(state.model, "id", roleId),
        };
      },
      "editProjectRoleSubmitModel/setmodelSuccess": (
        state,
        payload: CapturedState<EditProjectRoleFormParams, RoleType>
      ) => ({
        ...state,
        model: updateCapturedLoadingModel(state.model, "id", payload, "roleId"),
      }),
    },
    effects: (dispatch: any) => ({
      deleteProjectRole: async ({
        roleId,
      }: Pick<EditProjectRoleFormParams, "roleId">) => {
        if (roleId) {
          await rolesApi.deleteProjectRole(roleId);
          dispatch.projectRolesModel.removeRole(roleId);
        }
      },
    }),
  }
);

export const userRolesModel = capturedLoadingRematchModel(
  "userRolesModel",
  async () => rolesApi.getUserRoles()
);

export const rolesModel = capturedLoadingRematchModel(
  "rolesModel",
  async (params: PaginationQueryParams) =>
    rolesApi.getProjectRoles({
      ...DEFAULT_API_PARAMS,
      ...params,
    })
);

export const allRolesModel = capturedLoadingRematchModel(
  "allRolesModel",
  async (params: PaginationQueryParams) => rolesApi.getAllProjectRoles()
);

export const allPracticeRolesModel = capturedLoadingRematchModel(
  "allPracticeRolesModel",
  async (params: PaginationQueryParams) => rolesApi.getAllPracticeRoles()
);

export const editProjectRoleModel = capturedLoadingRematchModel(
  "editProjectRoleModel",
  async ({ roleId }: { roleId: number }) => {
    const { all_localizations } = SettingsParams;
    return rolesApi.getAdminProjectRole(roleId, { all_localizations });
  }
);

export interface EditProjectRoleFormParams {
  roleId: number | undefined;
  values: RoleType;
}

export const editProjectRoleSubmitModel = capturedLoadingRematchModel(
  "editProjectRoleSubmitModel",
  async ({ roleId, values }: EditProjectRoleFormParams) =>
    roleId
      ? rolesApi.editProjectRole(roleId, values)
      : rolesApi.createProjectRole(values)
);
