import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { formatRoute } from "react-router-named-routes";
import { useHistory, useRouteMatch } from "react-router-dom";
import { TabParams } from "../../router/routerConst";

/**
 * Helper method to replace the current route when tabs change.
 * @param routePath - The path of the route to replace.
 * @param tabOptions - The set of tab options that exist.
 * @param defaultTab - The default tab to select.
 * @return returns when {@link useState} returns to query current tab
 * and set the current tab.
 */
export const useTabRoutes = <T extends string>(
  routePath: string,
  tabOptions: T[],
  defaultTab: T
): [T, Dispatch<SetStateAction<T>>] => {
  const history = useHistory();
  const match = useRouteMatch<TabParams<T>>();
  // if match tab is included within list of tab options, otherwise use default
  // tab if not found. The tabs must be same exact value (and casing)
  // as the expected route, otherwise
  // going to non-default tab will redirect back to default.
  const [tab, setTab] = useState<T>(
    ((tabOptions.includes(match.params.tab as T) && match.params.tab) as T) ||
      defaultTab
  );
  useEffect(() => {
    const newRoute = formatRoute(routePath, {
      tab: tab.toLowerCase(),
    });
    // If the url does not start with the new route, we navigate there.
    // This is because on initial load of page if it has params, it will
    // override the intended route. When switching tabs, the url will become
    // different.
    if (!match.url.startsWith(newRoute)) {
      history.replace(newRoute);
    }
  }, [
    tab,
    history,
    routePath,
    match.params.itemId,
    match.params.id,
    match.url,
  ]);
  return [tab, setTab];
};
