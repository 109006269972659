import { Form, Select } from "antd";
import { FormContext } from "ui/forms/Form";
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ProjectFilter } from "./projectType";
import { ConfigType, NameKey } from "api/apiType";
import { useStore } from "redux/hooks";
import { startCase } from "lodash";

const { Option } = Select;

interface Props {
  selectedFilters?: ProjectFilter;
}

export const ProjectStageFilter = ({ selectedFilters }: Props) => {
  const { t } = useTranslation();
  const { select } = useStore();
  const form = useContext(FormContext);

  const configs: ConfigType | undefined = useSelector(
    select.configModel.optionalSuccess
  );
  const projectStages = configs?.projects.stages || [];

  const stageOptions = projectStages.map((stage: NameKey) => (
    <Option key={stage.key}>{startCase(stage.name)}</Option>
  ));

  const setFieldsValue = form.setFieldsValue;
  useEffect(() => {
    setFieldsValue({
      stages: selectedFilters?.stages,
    });
  }, [selectedFilters, setFieldsValue]);

  return (
    <FormContext.Consumer>
      {({ getFieldDecorator }) => (
        <Form.Item label={t("projectsOverview.stage")}>
          {getFieldDecorator("stages", {
            initialValue: selectedFilters?.stages,
          })(
            <Select
              allowClear
              mode="multiple"
              showSearch
              optionFilterProp="children"
            >
              {stageOptions}
            </Select>
          )}
        </Form.Item>
      )}
    </FormContext.Consumer>
  );
};
