import BrowserTitle from "../BrowserTitle";
import SubHeader, { SubHeaderProps } from "../SubHeader/SubHeader";
import Wrapper from "../Wrapper/Wrapper";
import { Card } from "antd";
import Tabs, { TabPane } from "./Tabs";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTabRoutes } from "./tab_hooks";
import { TabsProps } from "antd/lib/tabs";
import { useTranslation } from "react-i18next";

export interface DefaultTabProps {
  key: string;
  tab: string;
  isActive?: boolean;
}

export interface TabDefinition<T extends string, P> {
  /**
   * Its option key.
   */
  key: T;
  /**
   * Specify the key within localization for title.
   */
  localizedTabKey: string;

  /**
   * Specify the render component for this tab, which must be a {@link TabPane}
   * and it must have {@link DefaultTabProps} for its tab pane.
   */
  Component: React.ComponentType<DefaultTabProps & P>;
  /**
   * If true, will not show.
   */
  hidden?: boolean;

  /**
   * Any extra props to this component must be provided.
   */
  extraProps?: P;
}

export interface TabScreenProps<T extends string> {
  /**
   * The main path of the screen i.e. the router url such as
   * PRIVATE_ROUTES.SETTINGS_GEOGRAPHY_SCREEN.path. This is used as base url
   * when switching tabs.
   */
  routePath: string;

  /**
   * The list of tab definitions to make up the tabs.
   */
  tabDefinitions: TabDefinition<T, any>[];

  /**
   * The valid set of tab options for the screen.
   */
  tabOptions: T[];

  /**
   * Which tab to render first.
   */
  defaultTab: T;

  /**
   * Specify props here to display a {@link SubHeader} component. If omitted,
   * no subheader is rendered.
   */
  subHeaderProps?: SubHeaderProps;

  /**
   * Specify any extra {@link TabsProps} you need.
   */
  tabProps?: Omit<TabsProps, "children" | "defaultActiveKey" | "onChange">;

  /**
   * Called when tab changes and on initial load.
   * @param tab - The tab that's changed.
   */
  onTabChange?: (tab: T) => void;

  filterContent?: React.ReactNode;
}

/**
 * Template Component that provides basic functionality for a tab screen.
 * When navigating between tabs, this component handles routing, tabs UI, and
 * displaying content in a template, while still retaining customization.
 */
export const TabScreen = <T extends string>({
  tabDefinitions,
  routePath,
  tabOptions,
  defaultTab,
  subHeaderProps,
  tabProps,
  onTabChange,
  filterContent,
}: TabScreenProps<T>) => {
  const { t } = useTranslation();
  const [tabActiveKey, setActiveTabKey] = useTabRoutes<T>(
    routePath,
    tabOptions,
    defaultTab
  );
  const getTabTitle = useCallback(
    (tabKey: string) =>
      t(tabDefinitions.find(t => t.key === tabKey)?.localizedTabKey || ""),
    [tabDefinitions, t]
  );
  const handleTabChange = useCallback(
    (newTab: string) => {
      if (newTab !== tabActiveKey && tabOptions.includes(newTab as T)) {
        setActiveTabKey(newTab as T);
        if (onTabChange) {
          onTabChange(newTab as T);
        }
      }
    },
    [onTabChange, setActiveTabKey, tabActiveKey, tabOptions]
  );

  const renderedTabs = useMemo(() => {
    return tabDefinitions
      .filter(t => !t.hidden)
      .map(({ Component, key, extraProps, localizedTabKey }) => {
        const isActive = tabActiveKey === key;
        return isActive ? (
          <Component
            key={key}
            tab={t(localizedTabKey)}
            isActive={isActive}
            {...extraProps}
          />
        ) : (
          <TabPane key={key} tab={t(localizedTabKey)} />
        );
      });
  }, [t, tabActiveKey, tabDefinitions]);

  useEffect(() => {
    if (onTabChange) {
      onTabChange(tabActiveKey);
    }
  }, [onTabChange, tabActiveKey]);

  return (
    <>
      <BrowserTitle title={getTabTitle(tabActiveKey)} />
      {subHeaderProps && <SubHeader {...subHeaderProps} />}
      {filterContent}
      <Wrapper>
        <Card className="tour-step-tab">
          <Tabs
            defaultActiveKey={tabActiveKey}
            onChange={handleTabChange}
            activeKey={tabActiveKey}
            {...tabProps}
          >
            {renderedTabs}
          </Tabs>
        </Card>
      </Wrapper>
    </>
  );
};
