import { lazy } from "react";
import { IMenuItemType } from "../../router/routerType";
import { ChangePassword, ViewUserProfile } from "./profile.permissions";

const Password = lazy(() =>
  import("./Password").then(({ Password }) => ({ default: Password }))
);
const Profile = lazy(() =>
  import("./Profile").then(({ Profile }) => ({ default: Profile }))
);

/** Screen: User Profile Page */
export const USERS_PROFILE_SCREEN: IMenuItemType = {
  id: "profile",
  path: "/profile",
  component: Profile,
  permissions: ViewUserProfile,
};

export const USERS_CHANGE_PASSWORD_SCREEN: IMenuItemType = {
  id: "update-password",
  path: "/profile/password",
  component: Password,
  permissions: ChangePassword,
};
