import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import values from "lodash/values";
import DefaultLayout from "ui/DefaultLayout/DefaultLayout";
import { PRIVATE_ROUTES, PUBLIC_ROUTES, SIDER_MENU } from "./Router.config";
import { isAuthenticated } from "identity/authHelper";
import NotFoundScreen from "router/NotFoundScreen";
import RedirectScreen from "router/RedirectScreen";
import ErrorScreen from "router/ErrorScreen";
import { UserType } from "users/userType";
import { LOCAL_STORAGE_REDIRECT_URL } from "app/utils/storageConst";
import { useStore } from "redux/hooks";
import { PermissionAccessGate } from "../identity/permissions/Permission";
import { checkPrivateUrl } from "router/router.utils";
import ErrorFallback from "ui/ErrorFallback";
import { PreloadScreen } from "ui/PreloadScreen";

const { ErrorBoundary } = require("react-error-boundary");

const Router = () => {
  const { select } = useStore();
  const user: UserType | undefined = useSelector(
    select.userModel.optionalSuccess
  );

  /** Only render routes that have a component */
  const filteredRoutes = values(PRIVATE_ROUTES).filter(item => item.component);

  // check redirect url, otherwise go to timesheet.
  let redirectUrl = localStorage.getItem(LOCAL_STORAGE_REDIRECT_URL);
  if (!redirectUrl) {
    redirectUrl = PRIVATE_ROUTES.MY_TIMESHEET_SCREEN.path;
  }

  return user != null && isAuthenticated() && checkPrivateUrl() ? (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<PreloadScreen />}>
        <Switch>
          {filteredRoutes.map(item => (
            <Route
              key={item.id}
              exact
              path={item.path}
              render={props => (
                <DefaultLayout {...props} siderMenu={SIDER_MENU}>
                  <PermissionAccessGate required={item.permissions || []}>
                    <item.component {...props} />
                  </PermissionAccessGate>
                </DefaultLayout>
              )}
            />
          ))}
          <Redirect exact from="/" to={redirectUrl} />
          <Redirect from={PUBLIC_ROUTES.JUMPCLOUD_AUTH.path} to={redirectUrl} />
          <Route
            key="error"
            path="/error"
            render={props => (
              <DefaultLayout {...props} siderMenu={SIDER_MENU}>
                <ErrorScreen {...props} />
              </DefaultLayout>
            )}
          />
          <Route
            key="not-found"
            path="/notfound"
            render={props => (
              <DefaultLayout {...props} siderMenu={SIDER_MENU}>
                <NotFoundScreen {...props} />
              </DefaultLayout>
            )}
          />
          <Route
            key="service-maintaince"
            path="/service-maintaince"
            render={props => <RedirectScreen {...props} />}
          />
          <Route
            path="*"
            render={props => (
              <DefaultLayout {...props} siderMenu={SIDER_MENU}>
                <NotFoundScreen {...props} />
              </DefaultLayout>
            )}
          />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  ) : (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          {values(PUBLIC_ROUTES).map(item => (
            <Route
              key={item.id}
              exact
              path={item.path}
              component={item.component}
            />
          ))}
          <Redirect to={PUBLIC_ROUTES.LOGIN_SCREEN.path} />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Router;
