import random from "lodash/random";

const MIN = -100;
const MAX = -1;

// Generate a random negative number for locally added item
export function generateLocalId() {
  return random(MIN, MAX, true);
}

// Check that an id is valid before making an API call
// hence not a locally generated id
export function hasValidId(id: number) {
  return id > MAX;
}
