/**
 * User Scopes (OpenID terminology), ie. permissions
 * @readonly
 * @enum {string}
 */
export enum UserScopes {
  IDENTITY_USERS_MONTHLY_SALARY_READ = "identity:users:monthly_salary:read",
  IDENTITY_USERS_MONTHLY_SALARY_WRITE = "identity:users:monthly_salary:write",
  IDENTITY_ROLES_READ = "identity:roles:read",
  IDENTITY_ROLES_LIST_READ = "identity:roles:list:read",
  IDENTITY_ROLES_WRITE = "identity:roles:write",
  IDENTITY_ROLES_DELETE = "identity:roles:delete",
  IDENTITY_PROFILE_WRITE = "identity:profile:write",
  IDENTITY_PROFILE_READ = "identity:profile:read",
  IDENTITY_PROFILE_DELETE = "identity:profile:delete",
  IDENTITY_PROFILE_SKILL_REQUESTS_READ = "identity:profile:skill-requests:read",
  IDENTITY_PROFILE_SKILL_REQUESTS_WRITE = "identity:profile:skill-requests:write",
  IDENTITY_WRITE = "identity:write",
  IDENTITY_READ = "identity:read",
  IDENTITY_DELETE = "identity:delete",
  ORGANIZATION_WRITE = "organization:write",
  ORGANIZATION_READ = "organization:read",
  ORGANIZATION_DELETE = "organization:delete",
  ORGANIZATION_OFFICES_WRITE = "organization:offices:write",
  ORGANIZATION_OFFICES_READ = "organization:offices:read",
  ORGANIZATION_OFFICES_LIST_READ = "organization:offices:list:read",
  ORGANIZATION_OFFICES_DELETE = "organization:offices:delete",
  ORGANIZATION_CURRENCIES_LIST_READ = "organization:currencies:list:read",
  PROJECTS_WRITE = "projects:write",
  PROJECTS_READ = "projects:read",
  PROJECTS_DELETE = "projects:delete",
  PROJECTS_MEMBERS_READ = "projects:members:read",
  PROJECTS_MEMBERS_WRITE = "projects:members:write",
  PROJECTS_MEMBERS_DELETE = "projects:members:delete",
  PROJECTS_RATES_WRITE = "projects:rates:write",
  PROJECTS_RATES_READ = "projects:rates:read",
  PROJECTS_ITEMS_READ = "projects:items:read",
  PROJECTS_ITEMS_WRITE = "projects:items:write",
  PROJECTS_ITEMS_DELETE = "projects:items:delete",
  PROJECTS_ALLOCATIONS_READ = "projects:allocations:read",
  PROJECTS_ALLOCATIONS_WRITE = "projects:allocations:write",
  PROJECTS_ALLOCATIONS_DELETE = "projects:allocations:delete",
  PROJECTS_ALLOCATIONS_REQUEST_WRITE = "projects:allocations:request:write",
  PROJECTS_CLIENTS_READ = "projects:clients:read",
  PROJECTS_CLIENTS_LIST_READ = "projects:clients:list:read",
  PROJECTS_CLIENTS_WRITE = "projects:clients:write",
  PROJECTS_CLIENTS_DELETE = "projects:clients:delete",
  TIME_PLAN_WRITE = "time-plan:write",
  TIME_PLAN_READ = "time-plan:read",
  TIME_PLAN_DELETE = "time-plan:delete",
  TIME_PLAN_ALLOCATIONS_WRITE = "time-plan:allocations:write",
  TIME_PLAN_ALLOCATIONS_READ = "time-plan:allocations:read",
  TIME_PLAN_ALLOCATIONS_DELETE = "time-plan:allocations:delete",
  TIME_PLAN_TRACKING_WRITE = "time-plan:tracking:write",
  TIME_PLAN_TRACKING_READ = "time-plan:tracking:read",
  TIME_PLAN_TRACKING_ADMIN_READ = "time-plan:tracking:admin:read",
  TIME_PLAN_TRACKING_USERS_READ = "time-plan:tracking:users:read",
  TIME_PLAN_TRACKING_DELETE = "time-plan:tracking:delete",
  NOTIFATIONS_WRITE = "notifications:write",
  NOTIFICATIONS_READ = "notifications:read",
  NOTIFICATIONS_DELETE = "notifications:delete",
  BI_WRITE = "bi:write",
  BI_READ = "bi:read",
  BI_DELETE = "bi:delete",
  PROJECTS_INVOICES_READ = "projects:invoices:read",
  PROJECTS_INVOICES_WRITE = "projects:invoices:write",
  PROJECTS_INVOICES_DELETE = "projects:invoices:delete",
  CASES_READ = "cases:read",
  CASES_WRITE = "cases:write",
  CASES_DELETE = "cases:delete",
  ALLOCATION_READ = "allocation_request:read",
  ALLOCATION_WRITE = "allocation_request:write",
  IDENTITY_AUDITS_READ = "identity:audits:read",
  ORGANIZATION_AUDITS_READ = "organization:audits:read",
  PROJECTS_AUDITS_READ = "projects:audits:read",
  TIME_PLAN_AUDITS_READ = "time-plan:audits:read",
  APPLICATION_GUARD_LOCKED_MONTH = "application:guard:locked-month:skip",
}

/**
 * User includes.
 * Used for querying the service with arguments
 * @readonly
 * @enum
 */
export enum UserIncludes {
  OFFICE = "office",
  CURRENCY = "currency",
  WORKING_HOURS = "working_hours",
  ROLES = "roles",
  SELECTED_OFFICES = "selected_offices",
  USER_SKILLS = "user_skills",
  AUTO_TRACKING = "office_auto_tracking",
  PRACTICE_ROLES = "practice_role",
}

/**
 * User Billable.
 * Used for querying if user is billable or not
 * @readonly
 * @enum
 */
export enum UserBillableEnum {
  NO = 0,
  YES = 1,
}

/**
 * Identity Includes
 */
export enum IndetityIncludes {
  SERVICE_OFFERING = "serviceOffering",
}

/**
 * Freelancers dropdown default filter option
 */

export const FILTER_OPTION_ALL = 5;
export const CATEGORISATION_FILTER_OPTION_ALL = 4;
/**
 * Freelancers dropdown filter options
 * * @Array
 */

export const freelancerBillableFilterOptions = [
  { value: 1, name: "only_billable" },
  { value: 2, name: "only_freelancers" },
  { value: 3, name: "non_billable" },
  { value: 4, name: "billable_freelancers" },
  { value: 5, name: "all" },
];

export const freelancerCategorisationBillableFilterOptions = [
  { value: 1, name: "only_billable" },
  { value: 2, name: "non_billable" },
  { value: 3, name: "freelancers" },
  { value: 4, name: "all" },
];

/**
 * User Salary dropdown filter options
 * * @Array
 */
export const freelancerFilterOptions = [
  { value: 2, name: "freelancers", message: "freelancersMessage" },
  { value: 3, name: "non_freelancers", message: "nonFreelancersMessage" },
  { value: 5, name: "all", message: "allMessage" },
];

export const DEFAULT_MEMBERS_LIMIT = 20;
