import {
  Identity,
  OrganizationOffices,
  ProjectsClients,
} from "../identity/permissions/permissionConst";

export const ViewClients = [ProjectsClients.READ];

export const CreateClients = [ProjectsClients.WRITE];

export const ViewModifySpecificClient = [
  ProjectsClients.READ,
  OrganizationOffices.READ,
  Identity.READ,
  ProjectsClients.WRITE,
];

export const DeleteSpecificClient = [ProjectsClients.DELETE];
