import { Form, Select } from "antd";
import { FormContext } from "ui/forms/Form";
import React, { useContext, useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectFilter } from "./projectType";
import { useDeepMemo } from "ui/DeepMemo";
import * as clientsApi from "clients/clientsApi";
import { ClientType } from "clients/clientType";

const { Option } = Select;

interface Props {
  selectedFilters?: ProjectFilter;
}

export const ProjectClientFilter = ({ selectedFilters }: Props) => {
  const { t } = useTranslation();
  const form = useContext(FormContext);

  const [clients, setClients] = useState<ClientType[]>([]);

  const callGetClients = (search: string = "") => {
    clientsApi.getClients({ q: search }).then(res => {
      setClients(res.data);
    });
  };

  useEffect(() => {
    callGetClients();
  }, []);

  const renderOptionsFromApiList = useCallback(
    (items?: any[]) =>
      items?.map(item => (
        <Option key={item.id.toString()} value={item.id.toString()}>
          {item.name}
        </Option>
      )),
    []
  );

  const clientsOptions = useDeepMemo(() => renderOptionsFromApiList(clients), [
    renderOptionsFromApiList,
    clients,
  ]);

  const setFieldsValue = form.setFieldsValue;
  useEffect(() => {
    setFieldsValue({
      clients: selectedFilters?.clients,
    });
  }, [selectedFilters, setFieldsValue]);

  return (
    <FormContext.Consumer>
      {({ getFieldDecorator }) => (
        <Form.Item label={t("projectsOverview.client")}>
          {getFieldDecorator("clients", {
            initialValue: selectedFilters?.clients,
          })(
            <Select
              allowClear
              mode="multiple"
              showSearch
              optionFilterProp="children"
            >
              {clientsOptions}
            </Select>
          )}
        </Form.Item>
      )}
    </FormContext.Consumer>
  );
};
