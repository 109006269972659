import * as officeApi from "geography/office/officeApi";
import {
  loadingRematchModel,
  LoadingRematchModelState,
  wrapLoading,
} from "../../loading";
import { RangeTypeStr } from "../../allocations/rangeType";
import { ClosedDayType } from "./officeType";
import { defaultMemoize } from "reselect";

const closedDaysModel = loadingRematchModel(
  async (params: RangeTypeStr) => {
    const { start: from, end: to } = params;
    return officeApi.getAllClosedDays({ from, to });
  },
  {
    selectors: (slice: any, createSelector) => {
      const closedDaysSelector = createSelector(
        slice,
        (state: LoadingRematchModelState<ClosedDayType[]>) =>
          wrapLoading<ClosedDayType[]>(state.model).optionalSuccess
      );
      return {
        closedDays() {
          return createSelector(closedDaysSelector, state =>
            defaultMemoize((officeId: number) =>
              state?.filter(c => c.office_id === officeId)
            )
          );
        },
      };
    },
  }
);

export default closedDaysModel;
