import React, { PureComponent } from "react";
import { Tabs as AntdTabs } from "antd";
import { TabsProps } from "antd/lib/tabs/index";
import TabPane from "./TabPane/TabPane";

interface IProps extends TabsProps {
  /** Contents of the Tab */
  children: React.ReactNode;
}

/**
 * Custom Ant Design Tab component
 */
class Tabs extends PureComponent<IProps> {
  render() {
    const {
      children,
      onChange,
      defaultActiveKey,
      tabBarExtraContent,
      ...rest
    } = this.props;

    return (
      <AntdTabs
        defaultActiveKey={defaultActiveKey}
        onChange={onChange}
        tabBarExtraContent={tabBarExtraContent}
        {...rest}
      >
        {children}
      </AntdTabs>
    );
  }
}

export default Tabs;
export { TabPane };
