import { capturedLoadingRematchModel } from "loading";
import * as officeApi from "./office/officeApi";
import { OfficeIncludes } from "./office/officeConst";
import { DEFAULT_API_PARAMS } from "api/apiConst";
import { PaginationQueryConfig } from "api/models";

const OFFICE_INCLUDES = {
  include: [OfficeIncludes.LOCKED_MONTHS],
};

export const officeLockedMonthsModel = capturedLoadingRematchModel(
  "officeLockedMonthsModel",
  async (params?: PaginationQueryConfig) =>
    officeApi.getOfficesLockedMonths(
      {
        ...DEFAULT_API_PARAMS,
        ...params,
      },
      OFFICE_INCLUDES
    )
);
