/**
 * Enum with localization options for select
 * @readonly
 * @enum {string}
 */
export enum LocaleOptions {
  US = "en-US",
  JP = "ja-JP",
  CN = "zh-CN",
  VN = "vi-VN",
}

/**
 * Enum with localization keys for inputs.
 * @readonly
 * @enum {string}
 */
export const Locales = Object.freeze({
  US: `[${LocaleOptions.US}]`,
  JP: `[${LocaleOptions.JP}]`,
  CN: `[${LocaleOptions.CN}]`,
  VN: `[${LocaleOptions.VN}]`,
});
