import { capturedLoadingRematchModel } from "../../loading";
import * as regionApi from "./regionApi";
import { PaginationQueryParams } from "../../api/models";
import { DEFAULT_API_PARAMS } from "api/apiConst";

export const regionsModel = capturedLoadingRematchModel(
  "regionsModel",
  async (params: PaginationQueryParams) =>
    regionApi.getRegions({
      ...DEFAULT_API_PARAMS,
      ...params,
    })
);
