import cookie from "react-cookies";
import store from "../redux/store";
import * as authApi from "./authApi";
import { message } from "antd";
import i18next from "../localization/localization";
import { clearPermissions } from "./permissions/Permission";
import { getPersistor } from "@rematch/persist";
import { removeLocalStorage } from "ui/localStorage";

const persistor = getPersistor();

const SESSION_TOKEN = "session-token";

/**
 * Checks if the user is authenticated
 * @returns {boolean}
 */
export const isAuthenticated = (): boolean => {
  const { accessToken, expireAt } = getTokens();
  return accessToken != null && expireAt != null;
};

/**
 * Logout user, i.e. clear and ivalidate tokens, and clear user model.
 */
export const logout = async () => {
  const authState = store.getState().authModel;
  const authDispatch = store.dispatch.authModel;

  /**
   * If logout endpoint has already been called, return
   * the saved call.
   */
  if (authState.isLoggingOut) {
    return authState.logoutCall!;
  }

  const body = document.body;
  body.removeAttribute("class");
  body.classList.add("default");

  try {
    authDispatch.setLoggingOut({ isLoggingOut: true });
    const logoutResponse = await authApi.logout();
    authDispatch.setLogoutCall({ logoutCall: logoutResponse });
    return logoutResponse;
  } finally {
    clearTokens();
    store.dispatch.userModel.clearmodel();
    clearPermissions();
    // Clear authentication state, when logged out
    authDispatch.clear();
    // Clear local storage
    removeLocalStorage();
    persistor.purge();
    message.success(i18next.t("authentication.messageSuccessLogout"));
    authDispatch.setLoggingOut({ isLoggingOut: false });
  }
};

/** 401 error handler */
export const unauthorizedLogout = async () => {
  const authDispatch = store.dispatch.authModel;

  const body = document.body;
  body.removeAttribute("class");
  body.classList.add("default");

  clearTokens();
  store.dispatch.userModel.clearmodel();
  clearPermissions();
  // Clear authentication state, when logged out
  authDispatch.clear();
  // Clear local storage
  removeLocalStorage();
  persistor.purge();
  message.success(i18next.t("authentication.messageSuccessLogout"));
  authDispatch.setLoggingOut({ isLoggingOut: false });
};

export const versionUpdate = async (newVersion: string) => {
  const authState = store.getState().authModel;
  const authDispatch = store.dispatch.authModel;

  /**
   * If logout endpoint has already been called, return
   * the saved call.
   */
  if (authState.isLoggingOut) {
    return authState.logoutCall!;
  }

  const body = document.body;
  body.removeAttribute("class");
  body.classList.add("default");

  try {
    authDispatch.setLoggingOut({ isLoggingOut: true });
    const logout = await authApi.logout();
    return logout;
  } finally {
    clearTokens();
    store.dispatch.userModel.clearmodel();
    clearPermissions();
    // Clear local storage
    removeLocalStorage();
    persistor.purge();
    authDispatch.setLoggingOut({ isLoggingOut: false });
    window.location.reload();
  }
};

/**
 * Save token in a cookie
 * @param {string} accessToken
 * @param {string} expireAt
 * @param {string} refreshToken
 */
export const saveTokens = (
  accessToken: string,
  expireAt?: string,
  refreshToken?: string
) => {
  const value = { accessToken, expireAt, refreshToken };
  cookie.save(SESSION_TOKEN, value, { path: "/" });
};

/**
 * Loads token from session cookie
 * @returns {object}
 */
export const getTokens = () => {
  const value = cookie.load(SESSION_TOKEN);

  return {
    accessToken: value && value.accessToken,
    expireAt: value && value.expireAt,
    refreshToken: value && value.refreshToken,
  };
};

/**
 * Deletes the session cookie
 */
export const clearTokens = () => {
  cookie.remove(SESSION_TOKEN, { path: "/" });
};

/**
 * Refresh access token
 * @returns {Promise<boolean>}
 */
export const refreshToken = async () => {
  const authState = store.getState().authModel;
  const authDispatch = store.dispatch.authModel;

  /**
   * If refresh endpoint has already been called, return
   * the saved call.
   */
  if (authState.isRefreshing) {
    return authState.refreshCall!;
  }

  authDispatch.setRefreshing({ isRefreshing: true });
  const { refreshToken } = getTokens();
  const newRefreshTokenCall = await authApi
    .refreshToken(refreshToken)
    .then(response => {
      const { token, expire_at, refresh_token } = response.token;
      saveTokens(token, expire_at, refresh_token);

      authDispatch.setRefreshing({ isRefreshing: false });
      authDispatch.resetRefreshCall();

      return Promise.resolve(true);
    })
    .finally(() => {
      logout();
    });

  authDispatch.setRefreshCall({ refreshCall: newRefreshTokenCall });
  return newRefreshTokenCall;
};
