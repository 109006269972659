import React, { FunctionComponent } from "react";
import styles from "./SideMenu.module.scss";
import { Icon, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { IMenuItemType } from "../../router/routerType";
import { useDeepMemo } from "../DeepMemo";
import { hasPermission } from "../../identity/permissions/Permission";
import { useStore } from "redux/hooks";
import { useSelector } from "react-redux";
import { UserScopes } from "../../users/userConst";
import { useTranslation } from "react-i18next";

const { SubMenu, Item: MenuItem } = Menu;

interface Props {
  /** List of sider menu items */
  siderMenu: IMenuItemType[];
  isCollapsedCheck?: boolean;
}

const filterByPermission = (scopes: UserScopes[]) => (i: IMenuItemType) => {
  if (i.permissions) {
    return hasPermission(scopes, i.permissions);
  }
  // assume no permissions, has all
  return true;
};

/**
 * Main Layout Sider Menu
 */
const SiderMenu: FunctionComponent<Props> = ({
  siderMenu,
  isCollapsedCheck,
}) => {
  const location = useLocation();
  const { select } = useStore();
  const { t } = useTranslation();
  const scopes: UserScopes[] = useSelector(select.userModel.scopes);

  const getSubMenuOpenKey = (location: any) => {
    //get submenu key from location path
    return location.match(/[/]\w*/)[0];
  };

  const getMenuOpenKey = (path: any) => {
    const location = path.match(/^\/.[^/]+\/.[^0-9/]+\w/g);
    return location == null ? path.match(/[/]\w*/)[0] : location[0];
  };

  // map and ensure user has permissions to see the sidebar items
  const mappedSiderMenu = useDeepMemo(() => {
    return siderMenu
      .filter(filterByPermission(scopes))
      .map(i => {
        // hide sub menu items that user does not have permission to see.
        const submenuItems = i.subMenuItems?.filter(filterByPermission(scopes));

        return {
          ...i,
          subMenuItems: submenuItems,
        };
        // filter empty submenus due to permissions.
      })
      .filter(i => i.subMenuItems === undefined || i.subMenuItems.length > 0);
  }, [siderMenu, scopes]);

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[getMenuOpenKey(location.pathname)]}
      defaultOpenKeys={
        !isCollapsedCheck ? [getSubMenuOpenKey(location.pathname)] : []
      }
    >
      {mappedSiderMenu.map(item =>
        item.subMenuItems ? (
          <SubMenu
            key={item.path}
            title={
              <span>
                <Icon role="img" type={item.icon} />
                <span className={styles.menuItem}>
                  {t(`menus.${item.title}`)}
                </span>
              </span>
            }
          >
            {item.subMenuItems.map(subItem => (
              <MenuItem key={getMenuOpenKey(subItem.path)}>
                <Link to={subItem.path}>
                  <span className={styles.menuItem}>
                    {t(`menus.${subItem.title}`)}
                  </span>
                </Link>
              </MenuItem>
            ))}
          </SubMenu>
        ) : (
          <MenuItem key={getSubMenuOpenKey(item.path)}>
            <Link to={item.path}>
              <Icon role="img" type={item.icon} />
              <span className={styles.menuItem + " themeTooltip"}>
                {t(`menus.${item.title}`)}
              </span>
            </Link>
          </MenuItem>
        )
      )}
    </Menu>
  );
};

export default SiderMenu;
