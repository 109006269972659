import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";
import { RegionAdminType, RegionType } from "geography/region/regionType";
import { UtilHelper } from "app/utilHelper";
import { ApiResponse } from "../../api/models";

/**
 * Organization: Get all Regions
 */
export const getRegions = async (
  params?: any
): Promise<ApiResponse<RegionType[]>> => {
  const url = Endpoints.REGIONS;

  // If params query exists remove all undefined values
  params && UtilHelper.removeUndefined(params);

  try {
    const resp = await api.get(url, {
      params: { ...params },
    });
    return await resp.data;
  } catch (err) {
    throw err;
  }
};

/** Organization: Create Region */
export const createRegion = (region: RegionType) => {
  const url = Endpoints.REGIONS;

  return api
    .post(url, region)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Edit Region
 */
export const editRegion = (regionId: number, region: RegionType) => {
  const url = `${Endpoints.REGIONS}/${regionId}`;

  return api
    .patch(url, region)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

export const getRegionAdmin = (
  regionId: number,
  params?: object
): Promise<RegionAdminType> => getRegion(regionId, params);

/**
 * Organization: Get Region
 */
export const getRegion = (regionId: number, params?: object) => {
  const url = `${Endpoints.REGIONS}/${regionId}`;

  return api
    .get(url, {
      params: { ...params },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Delete Region
 */
export const deleteRegion = (regionId: number) => {
  const url = `${Endpoints.REGIONS}/${regionId}`;

  return api.delete(url);
};
