import { api, getAll } from "../../api/apiHelper";
import { DEFAULT_ALL_RESULTS_PER_PAGE, Endpoints } from "../../api/apiConst";
import {
  ClosedDayType,
  OfficeAdminType,
  OfficeHolidayType,
  OfficeRateCardSubmit,
  OfficeRateCardType,
  OfficeTargetsType,
  OfficeTargetsYearsType,
  OfficeType,
  OverHeadCostType,
} from "./officeType";
import { UtilHelper } from "app/utilHelper";
import { RangeType } from "allocations/rangeType";
import { rangeToStr } from "allocations/rangeHelper";
import { ApiParamsType } from "api/apiType";
import { ApiResponse } from "../../api/models";
import { hasValidId } from "api/localDataHelper";
import { LogChangeType } from "audit-logs/log.type";
import { OfficeIncludes, rateTypes } from "./officeConst";

/**
 * Organization: Get all Offices
 */
export const getOffices = (
  params?: any,
  includes?: any
): Promise<ApiResponse<OfficeType[]>> => {
  const url = Endpoints.OFFICES;

  // If params query exists remove all undefined values
  params && UtilHelper.removeUndefined(params);
  includes && UtilHelper.removeUndefined(includes);

  return api
    .get(url, {
      params: { ...params, ...includes },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};

export /**
 * Get all offices from all pages
 *
 * @param {ApiOptionsType} [options]
 * @returns
 */
const getAllOffices = (params?: ApiParamsType): Promise<OfficeType[]> => {
  UtilHelper.removeUndefined(params);
  return getAll(Endpoints.OFFICES, {
    ...params,
    limit: DEFAULT_ALL_RESULTS_PER_PAGE,
  });
};

export /**
 * Get all closed days from all pages
 *
 * @param {ApiParamsType} [params]
 * @returns
 */
const getAllClosedDays = (params?: ApiParamsType) =>
  getAll<ClosedDayType>(Endpoints.ALL_CLOSED_DAYS, {
    ...params,
    // this limit value is 60 but this endpoint has a max limit of 30 so it still takes 2 calls to get all.
    limit: DEFAULT_ALL_RESULTS_PER_PAGE,
  });

/**
 * Get Office by Id
 */
export const getOfficeById = async (officeId: number): Promise<OfficeType> => {
  const url = `${Endpoints.OFFICES}/${officeId}`;
  try {
    const resp = await api.get(url, {
      params: { include: OfficeIncludes.ADMIN },
    });
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: Create Office */
export const createOffice = (office: OfficeType) => {
  const url = Endpoints.OFFICES;

  return api
    .post(url, office)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Edit Office
 */
export const editOffice = (officeId: number, office: OfficeType) => {
  const url = `${Endpoints.OFFICES}/${officeId}`;

  return api
    .patch(url, office)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

export const getAdminOffice = (
  officeId: number,
  params?: object
): Promise<OfficeAdminType> => getOffice(officeId, params);

/**
 * Organization: Get Office
 */
export const getOffice = (officeId: number, params?: object) => {
  const url = `${Endpoints.OFFICES}/${officeId}`;

  return api
    .get(url, {
      params: { ...params },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Get Office yearly target list
 */
export const getOfficeYearlyTargetList = async (
  officeId: number
): Promise<OfficeTargetsYearsType[]> => {
  const url = `${Endpoints.OFFICES}/${officeId}${Endpoints.OFFICES_TARGET_YEARS}`;
  try {
    const resp = await api.get<ApiResponse<OfficeTargetsYearsType[]>>(url);
    return await resp.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Organization: Get Office target per financial year
 */
export const getOfficeTargetPerFinancialYear = async (
  officeId: number,
  params?: object
): Promise<ApiResponse<OfficeTargetsType[]>> => {
  const url = `${Endpoints.OFFICES}/${officeId}${Endpoints.OFFICES_TARGET}`;

  try {
    const resp = await api.get<ApiResponse<OfficeTargetsType[]>>(url, {
      params: { ...params },
    });
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Organization: Save/create Office target per financial year
 */
export const saveOfficeTargetPerFinancialYear = async (
  officeId: number,
  params: object
): Promise<ApiResponse<OfficeTargetsType[]>> => {
  const url = `${Endpoints.OFFICES}/${officeId}${Endpoints.OFFICES_TARGET}`;

  try {
    const resp = await api.patch<ApiResponse<OfficeTargetsType[]>>(url, {
      ...params,
    });
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Organization: Delete Office
 */
export const deleteOffice = (officeId: number) => {
  const url = `${Endpoints.OFFICES}/${officeId}`;

  return api.delete(url);
};

/**
 * Organization: Get Rate cards of single Office
 */
export const getOfficeRateCards = async (
  officeId: number,
  rateType: string,
  year?: string
): Promise<ApiResponse<OfficeRateCardType[]>> => {
  const url = `${Endpoints.OFFICES}/${officeId}/${rateType}`;
  try {
    const resp = await api.get(url, { params: { year } });
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: Create and update Office rate cards */
export const changeOfficeRateCard = async (
  officeId: number,
  rateCards: OfficeRateCardSubmit,
  rateType: string,
  editMode?: boolean
): Promise<OfficeRateCardType[]> => {
  const url = `${Endpoints.OFFICES}/${officeId}/${rateType}`;

  try {
    const resp = await (!!editMode ? api.patch : api.post)<
      ApiResponse<OfficeRateCardType[]>
    >(
      url,
      rateType === rateTypes.EXTERNAL
        ? { external_rates: rateCards.rate_cards }
        : { transfer_rates: rateCards.rate_cards }
    );
    return resp.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: Create Office rate cards by month */
export const getOfficeRateCardsByMonth = async (
  officeId: number,
  month: string,
  rateType: string,
  params?: any
): Promise<any> => {
  const url = `${Endpoints.OFFICES}/${officeId}/${rateType}/months/${month}`;
  try {
    const resp = await api.get<ApiResponse<any>>(url, {
      params: { ...params },
    });
    return resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: Create Office rate cards by months */
export const getOfficeRateCardsByMonths = async (
  officeId: number,
  rateType: string,
  month: string,
  params?: any
): Promise<OfficeRateCardType[]> => {
  const url = `${Endpoints.OFFICES}/${officeId}/${rateType}/months/${month}`;
  try {
    const resp = await api.get<ApiResponse<OfficeRateCardType[]>>(url, {
      params: { ...params },
    });
    return resp.data.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: Update Office rate cards
 *
 * */
export const updateOfficeRateCard = async (
  officeId: number,
  ratecardId: number,
  rateCards: OfficeRateCardType
): Promise<OfficeRateCardType> => {
  const url = `${Endpoints.OFFICES}/${officeId}/rate-cards/${ratecardId}`;
  try {
    const resp = await api.patch(url, rateCards);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: delete Office rate by id
 *
 * */
export const deleteOfficeRateCardById = async (
  officeId: string,
  ratecardId: number,
  type: string
): Promise<OfficeRateCardType> => {
  const url = `${Endpoints.OFFICES}/${officeId}/${type}/${ratecardId}`;
  try {
    const resp = await api.delete(url);
    return resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: delete Office rate by id
 *
 * */
export const handleOfficeRateCardById = async (
  officeId: string,
  ratecardId: number,
  type: string,
  params: any
): Promise<OfficeRateCardType> => {
  try {
    const resp = hasValidId(ratecardId)
      ? await api.patch(
          `${Endpoints.OFFICES}/${officeId}/${type}/${ratecardId}`,
          { ...params }
        )
      : await api.post(`${Endpoints.OFFICES}/${officeId}/${type}`, {
          ...params,
        });
    return resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Organization: Get all suggested Bank holidays for a specific office and specific year
 */
export const getSuggestedHolidaysByOfficeAndYear = (
  officeId: number,
  year: string
) => {
  const url = `${Endpoints.OFFICES}/${officeId}/holidays/${year}`;

  return api
    .get(url)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Get all added Bank holidays and custom holidays for a specific office and specific year
 */
export const getOfficeHolidaysByYear = (officeId: number, year: string) => {
  const url = `${Endpoints.OFFICES}/${officeId}/closed_days`;

  return api
    .get(url, {
      params: { year },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Get all added Bank holidays and custom holidays for a specific office in dates range
 */
export const getOfficeHolidaysByRange = (
  officeId: number,
  requestRange: RangeType
) => {
  const url = `${Endpoints.OFFICES}/${officeId}/closed_days`;
  const { start: from, end: to } = rangeToStr(requestRange);
  return api
    .get(url, {
      params: {
        from,
        to,
      },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** Organization: Create Office Holiday */
export const createOfficeHoliday = (
  officeId: number,
  holiday: OfficeHolidayType
) => {
  const url = `${Endpoints.OFFICES}/${officeId}/closed_days`;

  return api
    .post(url, holiday)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};
/** Organization: Edit Office Holiday */
export const editOfficeHoliday = (
  officeId: number,
  holiday: OfficeHolidayType
) => {
  const url = `${Endpoints.OFFICES}/${officeId}/closed_days/${holiday.id}`;

  return api
    .patch(url, holiday)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Delete Office Holiday
 */
export const deleteOfficeHoliday = (officeId: number, holidayId: number) => {
  const url = `${Endpoints.OFFICES}/${officeId}/closed_days/${holidayId}`;

  return api.delete(url);
};

/**
 * Organization: offices locked month list by year
 */

export const getOfficesLockedMonths = (
  params?: any,
  includes?: any
): Promise<ApiResponse<OfficeType[]>> => {
  const url = `${Endpoints.OFFICES}`;

  // If params query exists remove all undefined values
  params && UtilHelper.removeUndefined(params);
  includes && UtilHelper.removeUndefined(includes);

  return api
    .get(url, {
      params: { ...params, ...includes },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

export const getOfficeLockedMonthsDataById = (
  officeId: number,
  year: string
) => {
  const OFFICE_INCLUDES = {
    include: "locked_months",
  };

  const url = `${Endpoints.OFFICES}/${officeId}`;

  return api
    .get(url, {
      params: { year, ...OFFICE_INCLUDES },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

export const getOfficeLockedMonthsData = async (year: string) => {
  const url = `${Endpoints.OFFICES}/locked_months`;

  try {
    const resp = await api.get(url, {
      params: { year },
    });
    return resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getOfficeUnLockedMonthsData = async () => {
  const url = `${Endpoints.VALUE_OBJECTS}/unlocked-months`;

  try {
    const resp = await api.get(url);
    return resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: Edit Office locked months */
export const editOfficeLockedMonthsById = (values: any) => {
  const url = `${Endpoints.OFFICES}/locked_months`;

  return api
    .patch(url, values)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** Organization: get office Overhead Cost by year */
export const getOverHeadCostByYear = (
  officeId: string,
  year: string
): Promise<ApiResponse<OverHeadCostType[]>> => {
  const url = `${Endpoints.OFFICES}/${officeId}/overhead-cost`;

  return api
    .get(url, {
      params: { year },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** Organization: edit office Overhead Cost by year */
export const editOverHeadCostByMonth = (
  officeId: string,
  params: OverHeadCostType
): Promise<ApiResponse<OverHeadCostType>> => {
  const url = `${Endpoints.OFFICES}/${officeId}/overhead-cost/${params.id}`;

  return api
    .patch(url, { ...params })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** Get offices id from region */
export const getOfficeIdsByRegion = (
  regionId: number
): Promise<ApiResponse<OverHeadCostType>> => {
  const url = `${Endpoints.REGIONS}/${regionId}/offices`;

  return api
    .get(url)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** Get Logs for office */
export const getOfficeAuditLogs = async (search?: {
  q?: string;
}): Promise<ApiResponse<LogChangeType<any>[]>> => {
  const url = Endpoints.OFFICE_AUDIT;

  // If search exists remove all undefined values
  search && UtilHelper.removeUndefined(search);

  try {
    const resp = await api.get(url, {
      params: { ...search },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export const exportOfficesRateCard = (months: string[], offices: number[]) => {
  const url = `${Endpoints.REPORTS_OFFICES}${Endpoints.PROJECTS_ROLES_RATES}`;

  return api
    .post(url, {
      months,
      officeIds: offices,
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};

export const getOfficesRateCardSignedUrl = (key: string) => {
  const url = `${Endpoints.OFFICES_RATE_CARD_DOWNLOAD}/${key}`;

  return api
    .get(url)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};
