import packageJson from "../../package.json";

const env: any = process.env;

export default {
  VERSION: packageJson.version,
  NODE_ENV: env.NODE_ENV,
  API_HOST: env.REACT_APP_API_HOST,
  APP_REMOTE_DB: env.REACT_APP_REMOTE_DB,
  NSTACK_APP_ID: env.REACT_APP_NSTACK_APP_ID,
  NSTACK_API_KEY: env.REACT_APP_NSTACK_API_KEY,
};
