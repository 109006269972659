import {
  APIPathSegment,
  Endpoints,
  DEFAULT_RESULTS_PER_PAGE,
} from "api/apiConst";
import { api } from "api/apiHelper";
import { AllocationFiltersType } from "bi/insightsType";
import { UtilHelper } from "app/utilHelper";
import { ApiFilterable } from "../api/apiType";

type ISearch = ApiFilterable & AllocationFiltersType;
const OFFICES_ZERO = "0";

/** Insights: get allocation capacities data */
export const getAllocationCapacities = (search: ISearch, date: string) => {
  const url = `${Endpoints.INSIGHTS_ALLOCATIONS}/${date}${Endpoints.INSIGHTS_ALLOCATION_CAPACITIES}`;

  delete search["period_length"];
  delete (search as any)["period"];

  const params = {
    primary_skills: search.primary_skills
      ? search.primary_skills.toString()
      : undefined,
    project_roles: search.project_roles
      ? search.project_roles.toString()
      : undefined,
    managers: search.managers ? search.managers.toString() : undefined,
    currency: search.currency,
    offices: search.offices,
    summarize: search.summarize,
  };

  if (params.offices === OFFICES_ZERO) delete params.offices;

  return api
    .get(url, {
      params: {
        ...params,
      },
    })
    .then(response => response.data);
};

/** Insights: get users by allocation data */
export const getAllocationUsers = (search: ISearch, date: string) => {
  const url = `${Endpoints.INSIGHTS_ALLOCATIONS}/${date}/${APIPathSegment.USERS}`;

  delete search["period_length"];
  delete (search as any)["period"];

  const params = {
    primary_skills: search.primary_skills
      ? search.primary_skills.toString()
      : undefined,
    project_roles: search.project_roles
      ? search.project_roles.toString()
      : undefined,
    managers: search.managers ? search.managers.toString() : undefined,
    currency: search.currency,
    offices: search.offices,
    summarize: search.summarize,
  };

  if (params.offices === OFFICES_ZERO) delete params.offices;

  return api
    .get(url, {
      params: {
        ...params,
      },
    })
    .then(response => response.data);
};

/**
 * Insights: Get all Revenues by year
 */
export const getRevenuesByYear = (year: string) => {
  const url = Endpoints.INSIGHTS_REVENUES;

  return api
    .get(url, {
      params: { year },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Insights: Get all Sales by year
 */
export const getSalesByYear = (year: string) => {
  const url = Endpoints.INSIGHTS_SALES;

  return api
    .get(url, {
      params: { year },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Insights: Get all BI Results
 */
export const getBIResults = (year: string, search?: any) => {
  const url = `${Endpoints.INSIGHTS_BI_PROJECTIONS}`;

  UtilHelper.removeUndefined(search);

  if (search?.offices) {
    let offices = search ? search.offices.toString() : undefined;
    search.offices = offices;
  }

  if (search.currency) {
    search.target_currency_id = search.currency;
    delete search.currency;
  }

  search.year = year;

  return api
    .get(url, {
      params: {
        ...search,
      },
    })
    .then((resp: any) => resp)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Insight: Get all billable
 */

export const getBillable = (startDate: string, search?: any) => {
  UtilHelper.removeUndefined(search);

  if (search?.offices) {
    let offices = search ? search.offices.toString() : undefined;
    search.offices = offices;
  }

  const url = `${Endpoints.INSIGHTS_BILLABLE}/${startDate}/${APIPathSegment.USERS}`;
  return api
    .get(url, {
      params: {
        ...search,
        limit: DEFAULT_RESULTS_PER_PAGE,
      },
    })
    .then(response => response);
};
