import { LoadingModel, loadingRematchModel, wrapLoading } from "loading";
import * as officeApi from "geography/office/officeApi";
import {
  OfficeRateCardSubmit,
  OfficeRateCardType,
  OfficeType,
} from "../officeType";
import { rateTypes } from "../officeConst";

export const officeDetailsModel = loadingRematchModel(
  async ({ officeId }: { officeId: number }) =>
    officeApi.getOfficeById(officeId),
  {
    selectors: (slice: any, createSelector) => ({
      currency() {
        return createSelector(slice, (state: any) => {
          const office: OfficeType | undefined = wrapLoading(state.model)
            .optionalSuccess;
          return office && office.currency;
        });
      },
    }),
  }
);

interface OfficeRateCardUpdatePayload {
  submit: OfficeRateCardSubmit;
  officeId: number;
  type: string;
  editMode?: boolean;
}

export const officeRateCardsModel = loadingRematchModel(
  async ({
    officeId,
    rateType,
    selectedYear,
  }: {
    officeId: number;
    rateType: string;
    selectedYear?: string;
  }) => {
    const rateCards = await officeApi.getOfficeRateCards(
      officeId,
      rateType,
      selectedYear
    );
    return rateCards.data;
  },
  {
    reducers: {
      replaceRateCardsByRoleAndMonth: (
        state,
        payload: OfficeRateCardType[] | undefined
      ) => {
        const list = state.model.optionalSuccess || [];
        return {
          ...state,
          model: LoadingModel.success(
            !payload
              ? list
              : list.map(l => {
                  const newRate = payload.find(
                    p => p.month === l.month && p.role.id === l.role.id
                  );
                  return newRate || l;
                })
          ),
        };
      },
    },
    effects: () => ({
      async update({
        submit,
        officeId,
        type,
        editMode,
      }: OfficeRateCardUpdatePayload) {
        this.requestmodel();
        try {
          const response:
            | OfficeRateCardType[]
            | undefined = await officeApi.changeOfficeRateCard(
            officeId,
            submit,
            type,
            editMode
          );
          this.replaceRateCardsByRoleAndMonth(response);
        } catch (e) {
          this.setmodelError(e);
        }
      },
    }),
  }
);

export const officeRateCardsByMonthModel = loadingRematchModel(
  async ({
    officeId,
    month,
    type,
    params,
  }: {
    officeId: number;
    month: string;
    type: string;
    params?: any;
  }) => officeApi.getOfficeRateCardsByMonth(officeId, month, type, params)
);

export const rateCardModel = {
  state: {
    rateCard: rateTypes.EXTERNAL,
  },
  reducers: {
    setSelectedRate: (state: Object, payload: string) => {
      return {
        ...state,
        rateCard: payload,
      };
    },
  },
};
