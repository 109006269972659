/**
 * Office includes.
 * Used for querying the service with arguments
 * @readonly
 * @enum
 */
export enum OfficeIncludes {
  LOCKED_MONTHS = "locked_months",
  ADMIN = "admin",
}

export const rateTypes = {
  EXTERNAL: "external-rates",
  TRANSFER: "transfer-rates",
};

export enum Separators {
  DOT = ".",
  COMMA = ",",
}

export const mondayIsFirstDay: any = {
  saturday: null,
  sunday: null,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
};

export const sundayIsFirstDay: any = {
  saturday: null,
  sunday: true,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: null,
};
