import { lazy } from "react";
import { IMenuItemType } from "router/routerType";
import { RouterParameters } from "router/routerConst";
import { ViewMyTimeSheets } from "../timesheets.permissions";

const MyTimesheetScreen = lazy(() =>
  import("time-plan/my/MyTimesheetScreen/MyTimesheetScreen")
);

/** Screen: My Timesheet Page */
export const MY_TIMESHEET_SCREEN: IMenuItemType = {
  id: "my-timesheet",
  path: "/my-timesheet",
  component: MyTimesheetScreen,
  title: "myTimeSheet",
  icon: "user",
  permissions: ViewMyTimeSheets,
};

export const MY_TIMESHEET_USER_SCREEN: IMenuItemType = {
  ...MY_TIMESHEET_SCREEN,
  path: MY_TIMESHEET_SCREEN.path + `/${RouterParameters.ID}`,
};
