import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";
import { ConfigType } from "./apiType";

/**
 * Gets couch bd config
 */
export const config = async (): Promise<ConfigType> => {
  const response = await api.get<ConfigType>(Endpoints.CONFIGS);
  return response.data;
};
