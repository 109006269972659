import { Spin } from "antd";
import React from "react";
import styles from "./styles.module.scss";

/**
 * Displayed before content loads on the site.
 */
export const PreloadScreen = () => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loaderContentColumn}>
        <Spin spinning size="large" className={styles.loaderSpin} />
      </div>
    </div>
  );
};
