import * as authApi from "./authApi";
import * as userApi from "../users/userApi";
import { saveTokens, getTokens, logout } from "./authHelper";
import { changeThemeHandler } from "ui/themeHandler";
import {
  loadingRematchModel,
  LoadingRematchModelState,
  wrapLoading,
} from "../loading";
import { UserType } from "../users/userType";
import { UpdatePasswordBody } from "users/userType";
import { changeLanguage } from "localization/localizationHelper";

const userModel = loadingRematchModel(async () => userApi.getUser(), {
  selectors: (slice: any, createSelector) => {
    const userSelector = createSelector(
      slice,
      (state: LoadingRematchModelState<UserType>) =>
        wrapLoading(state.model).optionalSuccess
    );
    return {
      scopes() {
        return createSelector(userSelector, user => user?.scopes || []);
      },
    };
  },
  effects: (dispatch: any) => ({
    /**
     * Log in user
     * @payload: { EffectPayload }
     * @return {Promise}
     */
    async login(payload: { username: string; password: string }) {
      this.requestmodel();
      const { username, password } = payload;
      const user = await authApi.login(username, password);
      // Save the tokens in the cookie
      saveTokens(
        user.token.token,
        user.token.expire_at,
        user.token.refresh_token
      );
      // delete token from user, so it won't be stored in localstorage for security reasons
      delete user.token;
      user.selected_offices = [];
      user.user_skills = [];
      this.setmodelSuccess(user);
    },

    /**
     * Request all localized objects like
     * skills, countries, roles, offices, currencies, regions
     */
    async requestLocalizedObjects() {
      dispatch.skillsModel.request();
      dispatch.rolesModel.request();
      dispatch.countriesModel.request();
      dispatch.officesQueryModel.request();
      dispatch.allCurrenciesModel.request();
      dispatch.regionsModel.request();
      dispatch.allRolesModel.request();
      dispatch.allPracticeRolesModel.request();
      dispatch.menuTutorialModel.request();
    },

    async updateProfile(value: any, state) {
      const newLocale = value.locale;
      const currentLocale = state.userModel.model?.success?.locale;
      this.requestmodel();
      try {
        const user = await userApi.updateProfile(value);
        dispatch.authModel.setTheme({ theme: user.theme });
        dispatch.authModel.setLocale({ locale: user.locale });
        changeThemeHandler(user.theme);
        this.setmodelSuccess(user);
        /** update localizations */
        if (newLocale !== currentLocale && newLocale) {
          this.requestLocalizedObjects();
        }
      } finally {
      }
      return true;
    },

    async updatePassword(value: UpdatePasswordBody) {
      try {
        const res = await userApi.changePassword(value);
        const user = res?.data;
        const { refreshToken } = getTokens();
        saveTokens(user.token.token, user.token.expire_at, refreshToken);
        setTimeout(() => {
          logout();
        }, 2500);
      } finally {
      }
      return true;
    },

    async afterLoginFetch(payload: any, state) {
      await Promise.all([
        dispatch.configModel.request(),
        dispatch.userModel.request(),
        dispatch.allRolesModel.request(),
        dispatch.allPracticeRolesModel.request(),
        dispatch.officesQueryModel.request(),
        dispatch.menuTutorialModel.request(),
      ]);
      const userModel = state.userModel.model?.success;
      if (userModel) {
        dispatch.authModel.setTheme({ theme: userModel.theme });
        dispatch.authModel.setLocale({ locale: userModel.locale });
        changeThemeHandler(userModel.theme);
        changeLanguage(userModel.locale);
      }
    },
  }),
});

export default userModel;
