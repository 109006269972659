import { createModel } from "@rematch/core";
import { DEFAULT_API_PARAMS } from "api/apiConst";
import { PaginationQueryParams } from "api/models";
import {
  capturedLoadingRematchModel,
  CapturedState,
  removeCapturedApiListItem,
  removeCapturedListItem,
  updateCapturedApiLoadingModel,
  updateCapturedLoadingModel,
} from "loading";
import { SettingsParams } from "settings/settingsConst";
import { CURRENCY_ISO } from "ui/HourFormat";
import { CurrencyType } from "./currencyType";
import * as currencyApi from "./currencyApi";

interface CurrentCurrencyState {
  current: string;
}

const INITIAL_STATE: CurrentCurrencyState = {
  current: CURRENCY_ISO,
};

export const currencyModel = createModel({
  state: INITIAL_STATE,
  reducers: {
    setCurrency: (
      state: CurrentCurrencyState,
      payload: string
    ): CurrentCurrencyState => ({
      ...state,
      current: payload,
    }),
  },
  selectors: (slice, createSelector) => ({
    current() {
      return createSelector(slice, (state: any) => state.current);
    },
  }),
});

export interface CurrencyFormParams {
  currencyId?: number;
  values: Partial<CurrencyType>;
}

export const currencyFormSubmitModel = capturedLoadingRematchModel(
  "currencyFormSubmitModel",
  async ({ currencyId, values }: CurrencyFormParams) => {
    // ensure lowercase
    const newValues: Partial<CurrencyType> = {
      ...values,
      iso3: values.iso3?.toLowerCase(),
    };
    return currencyId
      ? currencyApi.editCurrency(Number(currencyId), newValues)
      : currencyApi.createCurrency(newValues);
  }
);

export const currencyFormModel = capturedLoadingRematchModel(
  "currencyFormModel",
  async ({ currencyId }: Required<Pick<CurrencyFormParams, "currencyId">>) => {
    const { all_localizations } = SettingsParams;
    return currencyApi.getAdminCurrencyById(currencyId, { all_localizations });
  }
);

export const currenciesModel = capturedLoadingRematchModel(
  "currenciesModel",
  async (params: PaginationQueryParams) =>
    currencyApi.getCurrencies({
      ...DEFAULT_API_PARAMS,
      ...params,
    }),
  {
    reducers: {
      removeCurrency: (state, currencyId: number) => {
        return {
          ...state,
          model: removeCapturedApiListItem(state.model, "id", currencyId),
        };
      },
      "currencyFormSubmitModel/setmodelSuccess": (
        state,
        payload: CapturedState<CurrencyFormParams, CurrencyType>
      ) => ({
        ...state,
        model: updateCapturedApiLoadingModel(
          state.model,
          "id",
          payload,
          "currencyId"
        ),
      }),
    },
  }
);

export const allCurrenciesModel = capturedLoadingRematchModel(
  "allCurrenciesModel",
  async () => currencyApi.getAllCurrencies(),
  {
    reducers: {
      removeCurrency: (state, currencyId: number) => {
        return {
          ...state,
          model: removeCapturedListItem(state.model, "id", currencyId),
        };
      },
      "currencyFormSubmitModel/setmodelSuccess": (
        state,
        payload: CapturedState<CurrencyFormParams, CurrencyType>
      ) => ({
        ...state,
        model: updateCapturedLoadingModel(
          state.model,
          "id",
          payload,
          "currencyId"
        ),
      }),
    },
    effects: (dispatch: any) => ({
      deleteCurrency: async ({
        currencyId,
      }: Pick<CurrencyFormParams, "currencyId">) => {
        if (currencyId) {
          const id = Number(currencyId);
          await currencyApi.deleteCurrency(id);
          dispatch.allCurrenciesModel.removeCurrency(id);
          dispatch.currenciesModel.removeCurrency(id);
        }
      },
    }),
  }
);
