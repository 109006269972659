import cx from "classnames";
import React from "react";
import styles from "./AllocationCircle.module.scss";
import { AllocationColors } from "allocations/allocationConst";

interface Props {
  color: AllocationColors;
}

const AllocationCircle = ({ color }: Props) => {
  return <span className={cx(styles.allocationCircle, styles[`${color}`])} />;
};

export default AllocationCircle;
