import { ApiResponse, PaginationQueryConfig } from "api/models";
import { Endpoints, DEFAULT_RESULTS_PER_PAGE } from "api/apiConst";
import { api } from "api/apiHelper";
import { UserType } from "users/userType";
import { getUserFilters } from "users/filterHelpers";
import qs from "query-string";
import { capturedLoadingRematchModel, wrapLoading } from "../../loading";
import { ProjectActivity } from "./projectActivityTypes";
import moment from "moment";
import { DateFormats } from "../../date/dateConst";
import omit from "lodash/omit";

export const projectActivitiesModel = capturedLoadingRematchModel(
  "projectActivitiesModel",
  async (params: PaginationQueryConfig, rootState, dispatch) => {
    const user: UserType = wrapLoading(rootState.userModel.model).success;
    const filters = getUserFilters(user);
    const from =
      params.from ||
      moment()
        .add(-1, "days")
        .format(DateFormats.API_DATE);
    const to = params.to || moment().format(DateFormats.API_DATE);
    let url = `${Endpoints.PROJECT_ACTIVITIES}/${from}/${to}`;
    const newParams = omit(
      {
        ...filters,
        ...params,
        limit: DEFAULT_RESULTS_PER_PAGE,
      },
      "from",
      "to"
    );
    const response = await api.get<ApiResponse<ProjectActivity[]>>(url, {
      params: newParams,
      paramsSerializer: params1 =>
        qs.stringify(params1, { arrayFormat: "comma" }),
    });
    dispatch.currencyModel.setCurrency(response.headers["x-currency"]);
    return response.data;
  }
);
