import { api } from "../api/apiHelper";
import {
  Endpoints,
  APIPathSegment,
  DEFAULT_RESULTS_PER_PAGE,
} from "../api/apiConst";

/**
 * Insight: Get all billable
 */

export const getBillable = (startDate: string, search?: object) => {
  const url = `${Endpoints.INSIGHTS_ALLOCATION_BILLABLE}/${startDate}/${APIPathSegment.USERS}`;
  return api
    .get(url, {
      params: {
        ...search,
        limit: DEFAULT_RESULTS_PER_PAGE,
      },
    })
    .then(response => response.data);
};

export const prjectionsCalculations = (year: any) => {
  const url = `${Endpoints.INSIGHTS_BI_PROJECTIONS}/populate?year=${year}`;

  return api
    .post(url, { year })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

export const getProjections = (currencyd: number) => {
  const url = Endpoints.INSIGHTS_BI_PROJECTIONS;

  return api
    .get(url, {
      params: {
        target_currency_id: currencyd,
      },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};
