import {
  BusinessIntelligence,
  Identity,
  OrganizationOffices,
  ProjectsClients,
} from "../identity/permissions/permissionConst";

export const ViewBillableOverview = [
  OrganizationOffices.READ,
  BusinessIntelligence.READ,
];

export const ViewAllocation = [
  OrganizationOffices.READ,
  Identity.READ,
  BusinessIntelligence.READ,
];

export const ViewRevSalesAvgHour = [BusinessIntelligence.READ];

export const ViewSkillsOverview = [BusinessIntelligence.READ];

export const ViewProjectActivitiesSales = [
  BusinessIntelligence.READ,
  ProjectsClients.READ,
];
