import { createModel } from "@rematch/core";

const INITIAL_STATE = {
  isRefreshing: false,
  isLoggingOut: false,
  isOfficesChanged: false,
  isSideBarCollapsed: false,
  isVersionUpdate: false,
  refreshCall: null,
  logoutCall: null,
  theme: "default",
  locale: "en-US",
  user_tutorial: null,
};

const authModel = createModel({
  state: INITIAL_STATE,
  reducers: {
    /**
     * Set refresh state
     */
    setRefreshing: (state: Object, payload: { isRefreshing: boolean }) => {
      return {
        ...state,
        isRefreshing: payload.isRefreshing,
      };
    },
    /**
     * Set refresh state
     */
    setVersionUpdate: (
      state: Object,
      payload: { isVersionUpdate: boolean }
    ) => {
      return {
        ...state,
        isVersionUpdate: payload.isVersionUpdate,
      };
    },
    /**
     * Set logging out state
     */
    setLoggingOut: (state: Object, payload: { isLoggingOut: boolean }) => {
      return {
        ...state,
        isLoggingOut: payload.isLoggingOut,
      };
    },
    /**
     * Set offices chnaged state
     */
    setOfficesChanged: (
      state: Object,
      payload: { isOfficesChanged: boolean }
    ) => {
      return {
        ...state,
        isOfficesChanged: payload.isOfficesChanged,
      };
    },
    /**
     * Set the refresh request
     */
    setRefreshCall: (state: Object, payload: { refreshCall: any }) => {
      return {
        ...state,
        refreshCall: payload.refreshCall,
      };
    },
    /**
     * Set the theme
     */
    setTheme: (state: Object, payload: { theme: any }) => {
      return {
        ...state,
        theme: payload.theme,
      };
    },
    /**
     * Set the tour
     */
    setTour: (state: Object, payload: { user_tutorial: any }) => {
      return {
        ...state,
        user_tutorial: payload.user_tutorial,
      };
    },
    /**
     * Set the locale
     */
    setLocale: (state: Object, payload: { locale: any }) => {
      return {
        ...state,
        locale: payload.locale,
      };
    },
    /**
     * Reset the refresh request
     */
    resetRefreshCall: (state: Object) => {
      return {
        ...state,
        refreshCall: null,
      };
    },
    /**
     * Set log out request
     */
    setLogoutCall: (state: Object, payload: { logoutCall: any }) => {
      return {
        ...state,
        logoutCall: payload,
      };
    },
    /**
     * Set sidebar collapsed
     */
    setSideBarCollapsed: (state: Object, payload: { isCollapsed: boolean }) => {
      return {
        ...state,
        isSideBarCollapsed: payload.isCollapsed,
      };
    },
    /**
     * Clear / reset state
     */
    clear() {
      return INITIAL_STATE;
    },
  },
});

export default authModel;
