import { api, getAll } from "../api/apiHelper";
import { DEFAULT_ALL_RESULTS_PER_PAGE, Endpoints } from "../api/apiConst";
import { SkillAdminType, SkillType } from "./skillType";
import { UtilHelper } from "app/utilHelper";
import { ApiParamsType } from "api/apiType";
import { ApiResponse } from "../api/models";

/** Organization: Get all skills */
export const getSkills = (params?: any): Promise<ApiResponse<SkillType[]>> => {
  const url = Endpoints.SKILLS;

  // If params query exists remove all undefined values
  params && UtilHelper.removeUndefined(params);

  return api
    .get(url, {
      params: { ...params },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};

export /**
 * Get all skills from all pages
 *
 * @param {ApiOptionsType} [options]
 * @returns
 */
const getAllSkills = (params?: ApiParamsType): Promise<SkillType[]> => {
  return getAll(Endpoints.SKILLS, {
    ...params,
    limit: DEFAULT_ALL_RESULTS_PER_PAGE,
  });
};

/** Organization: Create skill */
export const createSkill = async (skill: SkillType): Promise<SkillType> => {
  const url = Endpoints.SKILLS;

  try {
    const resp = await api.post<SkillType>(url, skill);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: Edit skill */
export const editSkill = async (
  skillId: number,
  skill: SkillType
): Promise<SkillType> => {
  const url = `${Endpoints.SKILLS}/${skillId}`;

  try {
    const resp = await api.patch<SkillType>(url, skill);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getAdminSkill = (
  skillId: number,
  params?: object
): Promise<SkillAdminType> => getSkill(skillId, params);

/**
 * Organization: Get skill
 */
export const getSkill = (skillId: number, params?: object) => {
  const url = `${Endpoints.SKILLS}/${skillId}`;

  return api
    .get(url, {
      params: { ...params },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** Organization: Delete skill */
export const deleteSkill = (skillId: number) => {
  const url = `${Endpoints.SKILLS}/${skillId}`;

  return api.delete(url);
};
