/**
 * Enum with router URL parameters.
 * @readonly
 * @enum {string}
 */
export enum RouterParameters {
  TAB = ":tab?",
  ID = ":id?",
  ITEM_ID = "/:itemId",
  CREATE = "/create",
  EDIT = "/edit",
  EDIT_FINANCIAL_YEAR = "/edit-financial-year",
  NEW_FINANCIAL_YEAR = "/new-financial-year",
  PERMISSION = "/permission",
}

/**
 * Tab params
 */
export type TabParams<T> = {
  id: string;
  tab: T;
  itemId: string;
};

/**
 * Details params
 */
export type DetailsParams = {
  id: string;
  itemId?: string;
};

export type MyTimesheetParams = {
  id?: string;
};
