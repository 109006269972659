import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation {
  /** Meta title (optional) */
  title?: string;
}

/**
 * Set the browser title
 */
class BrowserTitle extends PureComponent<Props> {
  render() {
    const { title, t } = this.props;

    return (
      <Helmet>
        {title && (
          <title>
            {t("default.title")}
            {!!title && ` |  ${title}`}
          </title>
        )}
      </Helmet>
    );
  }
}

export default withTranslation()(BrowserTitle);
