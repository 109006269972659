import {
  Identity,
  OrganizationOffices,
  IdentityRoles,
} from "../identity/permissions/permissionConst";

export const ViewEmployeesScreen = [
  Identity.READ,
  OrganizationOffices.READ,
  IdentityRoles.LIST_READ,
];

export const ViewModifySpecificEmployee = [
  Identity.READ,
  OrganizationOffices.READ,
  Identity.WRITE,
];

export const ViewSpecificEmployeeDetails = [
  Identity.READ,
  OrganizationOffices.READ,
];

export const ViewSpecificEmployee = [Identity.READ];

export const DeleteEmployee = [Identity.DELETE];
