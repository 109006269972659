import React, { PureComponent } from "react";
import styles from "./Breadcrumbs.module.scss";
import { Link } from "react-router-dom";
import { Breadcrumb as AntBreadcrumb } from "antd";
import cx from "classnames";

export type BreadcrumbType = {
  /** Label of item */
  label: string;
  /** Link of item (optional, will only show breadcrumb as Link if passed in) */
  link?: string;
};

type IProps = {
  /** The breadcrumbs for the current navigation */
  breadcrumbs: BreadcrumbType[];
  /** Force the separator to be shown after the last breadcrumb (optional) */
  showSeparatorAfterLastItem?: boolean;
};

/**
 * Breadcrumbs
 * Shows the items passed in, where the link is optional
 */
class Breadcrumbs extends PureComponent<IProps> {
  render() {
    const { breadcrumbs, showSeparatorAfterLastItem } = this.props;

    return (
      <AntBreadcrumb
        className={cx(styles.breadcrumbs, {
          [styles.showSeparatorAfterLastItem]: showSeparatorAfterLastItem,
        })}
      >
        {breadcrumbs.map((b, index) => (
          <AntBreadcrumb.Item key={index}>
            {b.link ? <Link to={b.link}>{b.label}</Link> : b.label}
          </AntBreadcrumb.Item>
        ))}
      </AntBreadcrumb>
    );
  }
}

export default Breadcrumbs;
