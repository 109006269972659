import Wrapper from "ui/Wrapper/Wrapper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card } from "antd";
import Tabs, { TabPane } from "ui/tabs/Tabs";
import { useTranslation } from "react-i18next";
import { InsightsSkillsTable } from "./InsightsSkillsTable";
import { useSelector } from "react-redux";
import { useStore } from "../../redux/hooks";
import { ApiResponse } from "../../api/models";
import { parseSearch } from "../../api/paginationQueryHandler";
import { useLocation, useHistory } from "react-router-dom";
import BrowserTitle from "../../ui/BrowserTitle";
import SubHeader from "../../ui/SubHeader/SubHeader";
import { InsightSkill } from "../insightsType";
import { LoadingModel } from "../../loading";
import { UserType } from "users/userType";
import qs from "query-string";
import ErrorFallback from "ui/ErrorFallback";
import { InsightsSkillsSteps } from "../InsightsUtils";
import Tour from "../../ui/tour/tour";
import { MenuTutorialPage } from "ui/tour/tourConstant";

const { ErrorBoundary } = require("react-error-boundary");

enum TabOptions {
  SKILLS = "Skills",
}

export const InsightsSkillsScreen = () => {
  const { t } = useTranslation();
  const steps = InsightsSkillsSteps(t);
  const { dispatch, select } = useStore();
  const location = useLocation();
  const history = useHistory();

  const skills:
    | LoadingModel<ApiResponse<InsightSkill[]>>
    | undefined = useSelector(select.insightSkillsModel.skills);
  const isLoadingSkills: boolean = useSelector(
    select.insightSkillsModel.isLoadingSkills
  );

  const user: UserType = useSelector(select.userModel.success);

  const defaultParams = useMemo(
    () => ({
      params: parseSearch(location.search),
    }),
    [location.search]
  );
  const [params] = useState(defaultParams);
  const [queryStrings, setQueryStrings] = useState({});

  useEffect(() => {
    const queryStrings = qs.parse(history.location.search);
    setQueryStrings(queryStrings);
    const paramsUpdate = { ...params };
    paramsUpdate.params = queryStrings;
    dispatch.insightSkillsModel.loadSkills(paramsUpdate);
    // eslint-disable-next-line
  }, [dispatch, params, user]);

  const [tab, setTab] = useState(TabOptions.SKILLS);
  const handleTabChange = useCallback(
    (tab: string) => {
      setTab(tab as TabOptions);
    },
    [setTab]
  );

  const handleQueryStringUpdate = (values: any) => {
    const currentSearch = qs.parse(location.search);

    values = {
      ...currentSearch,
      ...values,
    };

    history.push({
      pathname: location.pathname,
      search: qs.stringify(values, { arrayFormat: "comma" }),
    });

    setQueryStrings(qs.parse(history.location.search));

    if (values.offices === "0") delete values.offices;
    dispatch.insightSkillsModel.loadSkills({ params: values });
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserTitle title={t("insightsSkills.title")} />
      <SubHeader title={t("insightsSkills.headerTitle")} />
      <Tour steps={steps} id={MenuTutorialPage.INSIGHTS_SKILLS_SCREEN} />
      <div className="tour-step-main">
        <Wrapper loading={!skills && isLoadingSkills}>
          <Card className="tour-step-insights-skills-overview">
            <Tabs defaultActiveKey={tab} onChange={handleTabChange}>
              <TabPane
                tab={t("insightsSkills.tabSkills")}
                key={TabOptions.SKILLS}
              >
                {skills && (
                  <InsightsSkillsTable
                    skills={skills}
                    onParamsChange={handleQueryStringUpdate}
                    columns={queryStrings}
                  />
                )}
              </TabPane>
            </Tabs>
          </Card>
        </Wrapper>
      </div>
    </ErrorBoundary>
  );
};
