import { loadingRematchModel } from "../loading";
import * as projectsApi from "projects/projectsApi";

const projectsModel = loadingRematchModel(
  async (params: any, rootState: any, dispatch: any) => {
    const response = await projectsApi.getAllProjects(params);
    dispatch.currencyModel.setCurrency(response.headers["x-currency"]);
    return response.data;
  }
);

export default projectsModel;
