import React, { FunctionComponent, memo } from "react";
import _chunk from "lodash/chunk";
import { UserType } from "../users/userType";
import { useStore } from "redux/hooks";
import { useSelector } from "react-redux";

export enum StyleEnum {
  THOUSANDS_SEPARATOR = ",",
  DECIMAL_SEPARATOR = ".",
}

export const CURRENCY_ISO = "USD";
const FRACTION_DIGITIS = "";

interface IProps {
  /** currency/decimal/percentage value */
  value: number;
  /** How much value want to show after fraction */
  fractionDigitsShow?: boolean;
  /** Decimal Count Show */
  decimalCount?: number;
}

const HourFormat: FunctionComponent<IProps> = ({
  value = 0,
  fractionDigitsShow = true,
  decimalCount = 0,
}) => {
  const { select } = useStore();
  const user: UserType | undefined = useSelector(
    select.userModel.optionalSuccess
  );
  const hourFormat = hourFormattor(
    value,
    fractionDigitsShow,
    user,
    decimalCount
  );
  return <>{hourFormat}</>;
};

export default memo(HourFormat);

export const hourFormattor = (
  value = 0,
  fractionDigitsShow = true,
  user: UserType | undefined,
  decimalCount = 0
) => {
  let thousandsSeparator = StyleEnum.THOUSANDS_SEPARATOR;
  let fractionDigits = FRACTION_DIGITIS;
  let decimalSeparator = StyleEnum.DECIMAL_SEPARATOR;
  let hourValue = "";
  let isNegative = false;

  if (Number(value) < 0) {
    isNegative = true;
    value = Math.abs(Number(value));
  }

  const number =
    decimalCount && decimalCount > 0
      ? value.toFixed(decimalCount).split(".")
      : value.toString().split(".");

  if (number.length === 2) {
    fractionDigits = number[1];
  }

  if (decimalCount && decimalCount > 0 && number.length !== 2) {
    const zero = 0;
    fractionDigits = zero.toFixed(decimalCount).split(".")[1];
  }

  if (user) {
    thousandsSeparator = user.thousand_separator as StyleEnum;
    decimalSeparator = user.decimal_separator as StyleEnum;

    value = Number(number[0]);

    const fractionArr: any = [];

    if (value > 999) {
      const count = value
        .toString()
        .split("")
        .reverse();
      _chunk(count, 3).forEach(fraction =>
        fractionArr.push(fraction.reverse().join(""))
      );
    }

    hourValue =
      fractionArr.length > 0
        ? `${fractionArr.reverse().join(thousandsSeparator)}`
        : value.toString();

    /** check if decimal seperator show */
    if (fractionDigitsShow && fractionDigits !== FRACTION_DIGITIS) {
      hourValue = `${hourValue}${decimalSeparator}${fractionDigits}`;
    }

    /** Negetavity check */
    if (isNegative) {
      hourValue = `-${hourValue}`;
    }

    return hourValue;
  }
  return undefined;
};
