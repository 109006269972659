import { api, getAll } from "../api/apiHelper";
import {
  DEFAULT_ALL_RESULTS_PER_PAGE,
  DEFAULT_RESULTS_PER_PAGE,
  Endpoints,
} from "../api/apiConst";
import {
  CreateUserRoleType,
  RoleAdminType,
  RoleType,
  PracticeType,
} from "./roleType";
import { UtilHelper } from "../app/utilHelper";
import { ApiResponse } from "../api/models";
import { ApiParamsType } from "api/apiType";

/** Organization: Get all project roles */
export const getUserRoles = async (): Promise<ApiResponse<RoleType[]>> => {
  const url = Endpoints.USERS_ROLES;
  try {
    const resp = await api.get(url);
    return await resp.data;
  } catch (err) {
    throw err;
  }
};

/**
 * Get all roles from all pages
 *
 * @param {ApiOptionsType} [options]
 * @returns
 */
export const getProjectAllRoles = async (params?: any): Promise<RoleType[]> => {
  return await getAll(Endpoints.ROLES, {
    ...params,
    limit: DEFAULT_ALL_RESULTS_PER_PAGE,
  });
};

/**
 * Organization: Get role by Id
 */
export const getRoleById = (
  roleId: number,
  includes?: any
): Promise<RoleType> => {
  const url = `${Endpoints.USERS_ROLES}/${roleId}`;
  includes && UtilHelper.removeUndefined(includes);

  return api
    .get(url, {
      params: {
        ...includes,
      },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** Organization: Get project roles */
export const getProjectRoles = async (params?: any) => {
  const url = Endpoints.ROLES;
  // If params query exists remove all undefined values
  params && UtilHelper.removeUndefined(params);

  return api
    .get(url, {
      params: { ...params },
    })
    .then((resp: any) => {
      return resp.data;
    })
    .catch((err: any) => {
      throw err;
    });
};

export const callAllProjectRoles = async (params?: any) => {
  // If params query exists remove all undefined values
  params && UtilHelper.removeUndefined(params);

  try {
    const resp: RoleType[] = await getAllProjectRoles(params);
    return await {
      data: resp,
      meta: {
        pagination: {
          count: DEFAULT_RESULTS_PER_PAGE,
          current_page: Math.ceil(resp.length / DEFAULT_RESULTS_PER_PAGE),
          per_page: DEFAULT_RESULTS_PER_PAGE,
          total: resp.length,
          total_pages: Math.ceil(resp.length / DEFAULT_RESULTS_PER_PAGE),
        },
      },
    };
  } catch (err) {
    throw err;
  }
};

export /**
 * Get all project roles
 *
 * @param {ApiOptionsType} [options]
 * @returns
 */
const getAllProjectRoles = (params?: ApiParamsType): Promise<RoleType[]> => {
  return getAll(Endpoints.ROLES, {
    ...params,
    limit: DEFAULT_ALL_RESULTS_PER_PAGE,
  });
};

export /**
 * Get all practice roles
 *
 * @param {ApiOptionsType} [options]
 * @returns
 */
const getAllPracticeRoles = (
  params?: ApiParamsType
): Promise<PracticeType[]> => {
  return getAll(Endpoints.PRACTICE_ROLES, {
    ...params,
    limit: DEFAULT_ALL_RESULTS_PER_PAGE,
  });
};

/** Organization: Create project role */
export const createProjectRole = async (role: RoleType): Promise<RoleType> => {
  const url = Endpoints.ROLES;

  try {
    const resp = await api.post<RoleType>(url, role);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: Edit project role */
export const editProjectRole = async (
  roleId: number,
  role: RoleType
): Promise<RoleType> => {
  const url = `${Endpoints.ROLES}/${roleId}`;

  try {
    const resp = await api.patch<RoleType>(url, role);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getAdminProjectRole = (
  roleId: number,
  params?: object
): Promise<RoleAdminType> =>
  (getProjectRole(roleId, params) as any) as Promise<RoleAdminType>;

/**
 * Organization: Get role
 */
export const getProjectRole = async (
  roleId: number,
  params?: object
): Promise<RoleType> => {
  const url = `${Endpoints.ROLES}/${roleId}`;

  try {
    const resp = await api.get<RoleType>(url, {
      params: { ...params },
    });
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: Delete project role */
export const deleteProjectRole = (roleId: number) => {
  const url = `${Endpoints.ROLES}/${roleId}`;

  return api.delete(url);
};

/** Permission: Create user role */
export const createUserRole = (role: CreateUserRoleType) => {
  const url = Endpoints.USERS_ROLES;

  return api
    .post(url, role)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};
