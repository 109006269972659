import React from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "antd";
import UserButton from "identity/UserButton/UserButton";
import LanguageSwitcher from "localization/LanguageSwitcher";
import styles from "./ErrorFallbackReload.module.scss";

const { Header: AntdHeader } = Layout;

export default function ErrorFallback() {
  const { t } = useTranslation();

  return (
    <AntdHeader className={styles.header}>
      {t("notFound.errorText")}
      <div className={styles.controlsWrapper}>
        <LanguageSwitcher />
        <UserButton />
      </div>
    </AntdHeader>
  );
}
