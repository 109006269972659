import {
  LoadingModel,
  loadingRematchModel,
  wrapLoading,
} from "../../../loading";
import * as userApi from "users/userApi";
import { PracticeRoleRateType } from "../../../users/userType";
import { ApiResponse } from "../../../api/models";

export const practiceRoleRatesEmployeesModel = loadingRematchModel(
  async ({
    params,
    include,
    office_id,
    month_date,
  }: {
    params: any;
    include: any;
    office_id: number;
    month_date: string;
  }) =>
    userApi.getPracticeRoleRates({
      include,
      office_id,
      month_date,
      ...params,
    }),
  {
    reducers: {
      updateEmployee: (state, payload: PracticeRoleRateType) => {
        const employeeData = wrapLoading<ApiResponse<PracticeRoleRateType[]>>(
          state.model
        ).optionalSuccess;
        const userPracticeRates = (employeeData?.data || []).map(u => {
          if (u.id === payload.id) {
            return payload;
          }
          return u;
        });
        return {
          ...state,
          model: LoadingModel.success({
            meta: employeeData?.meta,
            data: userPracticeRates,
          }),
        };
      },
    },
  }
);
