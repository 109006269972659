import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

let ErrorBoundary: React.ComponentType<any>;
const apiKey = process.env.REACT_APP_BUGSNAG_API_KEY;
// in dev we probably do not want to run bugsnag to trigger errors.
// rather we want to exclusively for dev and production.
if (apiKey) {
  Bugsnag.start({
    apiKey: apiKey || "",
    plugins: [new BugsnagPluginReact()],
  });

  ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);
} else {
  ErrorBoundary = React.Fragment;
}

export { ErrorBoundary };
