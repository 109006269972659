import { UserType } from "./userType";
import { ApiFilterable } from "../api/apiType";

/**
 * Converts a {@link UserType.selected_offices} into a query string for filtering.
 */
export const getUserOfficeFilters = (user: UserType): string | undefined => {
  let selectedOffices: number[] = [];
  if (user.selected_offices.length > 0) {
    user.selected_offices.forEach(office => {
      selectedOffices.push(office.id);
    });
  }
  return selectedOffices.length > 0 ? selectedOffices.toString() : undefined;
};

/**
 * Converts a {@link UserType} into a set of {@link ApiFilterable} based on
 * user's current properties.
 * @param user The user to convert into filters.
 * @param overrides Specify here any overrides to provide when filtering.
 */
export const getUserFilters = (
  user: UserType,
  overrides?: ApiFilterable
): ApiFilterable => {
  const offices = getUserOfficeFilters(user);
  const currency = user.currency ? user.currency.id : undefined;
  return {
    offices,
    currency,
    ...overrides,
  };
};
