import { lazy } from "react";
import { IMenuItemType } from "router/routerType";
import { RouterParameters } from "router/routerConst";
import {
  ViewTrackingEmployeeOverview,
  ViewTrackingSpecificEmployee,
} from "./timesheets.permissions";

const TimesheetsScreen = lazy(() =>
  import("time-plan/TimesheetsScreen/TimesheetsScreen")
);
const TimesheetsCategorisationScreen = lazy(() =>
  import(
    "time-plan/TimesheetsCategorisationScreen/TimesheetsCategorisationScreen"
  )
);
const TimesheetDetailScreen = lazy(() =>
  import("time-plan/details/TimesheetDetailScreen/TimesheetDetailScreen")
);

export const TIMESHEETS: IMenuItemType = {
  id: "timesheets",
  path: "/timesheets",
  title: "timesheets",
  icon: "team",
};

/** Screen: Timesheets Page */
export const TIMESHEETS_SCREEN: IMenuItemType = {
  ...TIMESHEETS,
  id: "timesheets-screen",
  path: TIMESHEETS.path,
  component: TimesheetsScreen,
  title: "overview",
  permissions: ViewTrackingEmployeeOverview,
};

/** Screen: Timesheets Page */
export const TIMESHEETS_CATEGORISATION_SCREEN: IMenuItemType = {
  ...TIMESHEETS,
  id: "timesheets-screen",
  path: `${TIMESHEETS.path}/categorisation`,
  component: TimesheetsCategorisationScreen,
  title: "timeSheetCategorisation",
  permissions: ViewTrackingEmployeeOverview,
};

/** Screen: Timesheet Details */
export const TIMESHEET_DETAIL_SCREEN: IMenuItemType = {
  ...TIMESHEETS_SCREEN,
  path: TIMESHEETS_SCREEN.path + `/${RouterParameters.ID}`,
  component: TimesheetDetailScreen,
  permissions: ViewTrackingSpecificEmployee,
};

/** Screen: Timesheet Details */
export const TIMESHEET_CATEGORISATION_DETAIL_SCREEN: IMenuItemType = {
  ...TIMESHEETS_CATEGORISATION_SCREEN,
  path: TIMESHEETS_CATEGORISATION_SCREEN.path + `/${RouterParameters.ID}`,
  component: TimesheetDetailScreen,
  permissions: ViewTrackingSpecificEmployee,
};

/** Insights subitems */
TIMESHEETS.subMenuItems = [TIMESHEETS_SCREEN, TIMESHEETS_CATEGORISATION_SCREEN];
