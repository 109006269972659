import { ClientType } from "./clientType";
import * as clientsApi from "clients/clientsApi";
import * as officesApi from "geography/office/officeApi";
import { PaginationQueryParams } from "../api/models";
import {
  capturedLoadingRematchModel,
  CapturedState,
  loadingRematchModel,
  removeCapturedApiListItem,
  updateCapturedApiLoadingModel,
} from "../loading";
import * as usersApi from "users/userApi";
import { ApiParamsType } from "../api/apiType";

export interface ClientFormParams {
  clientId: number | undefined;
  values: ClientType;
}

export const clientsFormSubmitModel = capturedLoadingRematchModel(
  "clientsFormSubmitModel",
  async ({ clientId, values }: ClientFormParams) =>
    clientId
      ? clientsApi.editClient(clientId, values)
      : clientsApi.createClient(values)
);

export const clientsTableModel = capturedLoadingRematchModel(
  "clientsTableModel",
  async (action: PaginationQueryParams) => await clientsApi.getClients(action),
  {
    reducers: {
      removeClient: (state, clientId: number) => {
        return {
          ...state,
          model: removeCapturedApiListItem(state.model, "id", clientId),
        };
      },
      "clientsFormSubmitModel/setmodelSuccess": (
        state,
        payload: CapturedState<ClientFormParams, ClientType>
      ) => ({
        ...state,
        model: updateCapturedApiLoadingModel(
          state.model,
          "id",
          payload,
          "clientId"
        ),
      }),
    },
    effects: (dispatch: any) => ({
      deleteClient: async ({
        clientId,
      }: Pick<ClientFormParams, "clientId">) => {
        if (clientId) {
          const id = Number(clientId);
          await clientsApi.deleteClient(id);
          if (dispatch && dispatch?.clientsTableModel) {
            dispatch.clientsTableModel.removeClient(id);
          }
        }
      },
    }),
  }
);

export const clientsFormUsers = loadingRematchModel(
  async (params: PaginationQueryParams) => usersApi.getUsers(params)
);

export const clientOfficesModel = loadingRematchModel(
  async (action: ApiParamsType) => officesApi.getAllOffices(action)
);
