import React, { FunctionComponent } from "react";
import { Result } from "antd";
import { useTranslation } from "react-i18next";
import { UserScopes } from "../users/userConst";
import styles from "./NotAccessScreen.module.scss";

interface Props {
  /**
   * Specifies which permissions user must have.
   */
  requiredPermissions?: UserScopes[];
}

/** No access screen */
const NotAccessScreen: FunctionComponent<Props> = ({
  requiredPermissions = [],
}) => {
  const { t } = useTranslation();
  return (
    <Result title={t("notAccess.title")} subTitle={t("notAccess.text")}>
      {requiredPermissions.length >= 1 &&
        process.env.NODE_ENV === "development" && (
          <div className={styles.permissionsContainer}>
            {requiredPermissions.map(p => (
              <span>{p}</span>
            ))}
          </div>
        )}
    </Result>
  );
};

export default NotAccessScreen;
