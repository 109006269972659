import { Endpoints } from "api/apiConst";
import { api } from "api/apiHelper";

/**
 * Get Office visits
 */
export const getOfficeVisits = async (officeId: number): Promise<any> => {
  const url = `${Endpoints.OFFICES}/${officeId}${Endpoints.VISITS}`;
  try {
    const resp = await api.get(url);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};
