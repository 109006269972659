import { lazy } from "react";
import { IMenuItemType } from "../../router/routerType";
import { ViewEditUserScopes } from "./userScopes.permissions";

const UserScopesScreen = lazy(() =>
  import("./UserScopesScreen").then(({ UserScopesScreen }) => ({
    default: UserScopesScreen,
  }))
);

export const SETTINGS_USER_SCOPES_SCREEN: IMenuItemType = {
  id: "user-scopes",
  title: "scopes",
  path: "/settings/user-scopes",
  component: UserScopesScreen,
  permissions: ViewEditUserScopes,
};
