import React, { FC, useState, useEffect, useCallback } from "react";
import Joyride from "react-joyride";
import { useTranslation } from "react-i18next";
import { StepType, LocaleType, StylesType } from "./tourType";
import { useStore } from "redux/hooks";
import { useSelector } from "react-redux";
import * as userApi from "users/userApi";
import { MenuTutorials } from "users/userType";
import { ApiResponse } from "api/models";

const styles: StylesType = {
  options: {
    arrowColor: "#fff",
    backgroundColor: "#fff",
    overlayColor: "rgba(0, 0, 0, 0.5)",
    primaryColor: "#1890ff",
    spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
    textColor: "rgba(0, 0, 0, 0.65)",
    beaconSize: 20,
    width: 500,
    zIndex: 999,
  },
};

interface Props {
  id: number;
  steps: StepType[];
}

const Tour: FC<Props> = ({ id, steps }) => {
  const { t } = useTranslation();
  const { select, dispatch } = useStore();
  const [tour, setTour] = useState(false);

  const isLoadingTutorial: boolean = useSelector(
    select.menuTutorialModel.isLoading
  );

  const menunTutorialResponse:
    | ApiResponse<MenuTutorials[]>
    | undefined = useSelector(select.menuTutorialModel.optionalResponse);
  const menus = menunTutorialResponse?.data || [];

  const locale: LocaleType = {
    open: t("tour.openMessage"),
    back: t("tour.backButton"),
    last: t("tour.lastButton"),
    next: t("tour.nextButton"),
    skip: t("tour.skipButton"),
    close: t("tour.closeButton"),
  };

  const updateTour = async () => {
    try {
      await userApi.updateTour({
        menu_id: id,
        is_done: true,
      });
    } finally {
      dispatch.menuTutorialModel.request();
    }
  };

  const handleMenuTutorial = useCallback(
    (id: number) => {
      const isMenuDone = menus.find(menu => menu.menu_id === id);
      if (!isMenuDone) {
        setTimeout(() => setTour(true), 500);
        updateTour();
      }
    },
    // eslint-disable-next-line
    [menus]
  );

  useEffect(() => {
    if (!isLoadingTutorial) {
      handleMenuTutorial(id);
    }
    // eslint-disable-next-line
  }, [id, isLoadingTutorial]);

  return (
    <Joyride
      run={tour}
      continuous
      scrollToFirstStep
      spotlightClicks
      showSkipButton
      showProgress
      hideCloseButton
      steps={steps}
      styles={styles}
      locale={locale}
      spotlightPadding={5}
      scrollOffset={100}
    />
  );
};

export default Tour;
