import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";
import { CountryAdminType, CountryType } from "geography/country/countryType";
import { UtilHelper } from "app/utilHelper";
import { ApiResponse } from "../../api/models";

/**
 * Organization: Get all countries
 */
export const getCountries = async (search?: {
  q?: string;
}): Promise<ApiResponse<CountryType[]>> => {
  const url = Endpoints.COUNTRIES;

  // If search exists remove all undefined values
  search && UtilHelper.removeUndefined(search);

  try {
    const resp = await api.get(url, {
      params: { ...search },
    });
    return await resp.data;
  } catch (err) {
    throw err;
  }
};

/** Organization: Create Country */
export const createCountry = async (
  country: CountryType
): Promise<CountryType> => {
  const url = Endpoints.COUNTRIES;

  try {
    const resp = await api.post(url, country);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Organization: Edit Country
 */

export const editCountry = async (
  countryId: number,
  country: CountryType
): Promise<CountryType> => {
  const url = `${Endpoints.COUNTRIES}/${countryId}`;
  try {
    const resp = await api.patch(url, country);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getAdminCountry = (
  countryId: number,
  params?: object
): Promise<CountryAdminType> => getCountry(countryId, params);

/**
 * Organization: Get Country
 */
export const getCountry = (countryId: number, params?: object) => {
  const url = `${Endpoints.COUNTRIES}/${countryId}`;

  return api
    .get(url, {
      params: { ...params },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Delete Country
 */
export const deleteCountry = (countryId: number) => {
  const url = `${Endpoints.COUNTRIES}/${countryId}`;

  return api.delete(url);
};
