import React, { memo } from "react";
import cx from "classnames";
import * as i18n from "i18next";
import styles from "./RevenueColumn.module.scss";

interface IProps {
  value: i18n.TFunctionResult | number;
  isBold?: boolean;
  isCentered?: boolean;
}

const RevenueColumn = ({ value, isBold, isCentered }: IProps) => {
  return (
    <div
      className={cx(styles.columnData, {
        [styles.bold]: isBold,
        [styles.centered]: isCentered,
      })}
    >
      {value}
    </div>
  );
};

export default memo(RevenueColumn);
