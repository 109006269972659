import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useStore } from "redux/hooks";
import { useSelector } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import SortableTable, {
  getColumnSearchProps,
} from "ui/SortableTable/SortableTable";
import { getUserFilters } from "users/filterHelpers";
import { Empty } from "antd";
import { ApiResponse, PaginationQueryConfig } from "api/models";
import { ProjectSale } from "./projectSalesTypes";
import {
  convertTableUpdateToQueryParams,
  firstFilterParam,
  PaginationParams,
  usePagination,
} from "api/paginationHelpers";
import { useTranslation } from "react-i18next";
import Column from "antd/es/table/Column";
import { ProjectActivity } from "./projectActivityTypes";
import { PRIVATE_ROUTES } from "../../router/Router.config";
import { Link } from "react-router-dom";
import { PaginationConfig, SorterResult } from "antd/es/table";
import CurrencyFormat from "../../ui/CurrencyFormat";
import moment from "moment";
import { LoadProjectParams } from "bi/project/projectActions";
import { TabPane } from "../../ui/tabs/Tabs";
import { DefaultTabProps } from "../../ui/tabs/TabScreen";
import debounce from "lodash/debounce";
import { UserType } from "users/userType";
import ShowSpace from "ui/ShowSpace";

interface Props extends DefaultTabProps {
  params: LoadProjectParams;
  onQueryParametersReceived: (config: PaginationQueryConfig) => void;
}

export const InsightSalesTable: FunctionComponent<Props> = ({
  params,
  onQueryParametersReceived,
  isActive,
  ...rest
}) => {
  const { t } = useTranslation();
  const { dispatch, select } = useStore();
  const currency: string = useSelector(select.currencyModel.current);
  const user: UserType = useSelector(select.userModel.success);
  const sales: ApiResponse<ProjectSale[]> | undefined = useSelector(
    select.insightProjectSalesModel.optionalResponse
  );
  const salesLoading: boolean = useSelector(
    select.insightProjectSalesModel.isLoading
  );
  const pagination = usePagination(sales?.meta);

  const handleTableUpdate = useCallback(
    (
      pagination: PaginationConfig,
      params: PaginationParams<ProjectSale>,
      sorter: SorterResult<ProjectSale>
    ) => {
      const queryParams = convertTableUpdateToQueryParams(pagination, sorter, {
        q: firstFilterParam(params.project_name),
        clients: firstFilterParam(params.client_name),
      });
      onQueryParametersReceived(queryParams);
    },
    [onQueryParametersReceived]
  );

  const requestData = useMemo(
    () =>
      debounce((params: PaginationQueryConfig, user: UserType) => {
        const query = { ...params, ...getUserFilters(user) };
        dispatch.insightProjectSalesModel.requestTimed(query);
      }, 500),
    [dispatch.insightProjectSalesModel]
  );

  useEffect(() => {
    if (isActive) {
      requestData(params, user);
    }
  }, [dispatch.insightProjectSalesModel, isActive, params, requestData, user]); // currency used to re-request when change.
  return (
    <TabPane {...rest}>
      <SortableTable
        loading={salesLoading}
        onChange={handleTableUpdate}
        localization={{
          emptyListText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("insightsBillable.noBillable")}
            />
          ),
        }}
        pagination={pagination}
        data={sales?.data || []}
      >
        <Column
          title={t("insightsProject.columnProjectName")}
          key="project_name"
          dataIndex="project_name"
          width={400}
          render={(text: any, record: ProjectActivity) => (
            <Link
              to={formatRoute(PRIVATE_ROUTES.PROJECT_DETAILS_SCREEN.path, {
                id: record.project_id,
              })}
            >
              <ShowSpace str={text} />
            </Link>
          )}
          {...getColumnSearchProps(t("insightsProject.columnProjectName"))}
        />
        <Column
          title={t("insightsProject.columnClient")}
          key="client_name"
          dataIndex="client_name"
          width={400}
          filterMultiple={false}
          render={(text: any, record: ProjectActivity) => (
            <Link
              to={formatRoute(PRIVATE_ROUTES.CLIENTS_OVERVIEW_SCREEN.path, {
                id: record.client_id,
              })}
            >
              <ShowSpace str={text} />
            </Link>
          )}
        />
        <Column
          title={t("insightsProject.columnName")}
          key="name"
          dataIndex="name"
          width={800}
        />
        <Column
          title={t("insightsProject.columnAmount")}
          key="amount"
          dataIndex="amount"
          width={400}
          render={text => (
            <CurrencyFormat currencyIso={currency} value={text} />
          )}
        />
        <Column
          title={t("insightsProject.columnSoldBy")}
          key="sold_by_user_name"
          dataIndex="sold_by_user_name"
          width={400}
        />
        <Column
          title={t("insightsProject.columnCreatedAt")}
          key="created_at"
          dataIndex="created_at"
          width={400}
          render={text => moment(text).format("YYYY - MM - DD")}
        />
      </SortableTable>
    </TabPane>
  );
};
