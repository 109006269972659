import React from "react";
import { Button, Icon, Typography } from "antd";
import Breadcrumbs, { BreadcrumbType } from "../Breadcrumbs/Breadcrumbs";
import styles from "./SubHeader.module.scss";
import { GroupOrScopeItem } from "../../identity/permissions/permissionTypes";
import { useCheckUserPermissions } from "../../identity/permissions/permission_hooks";

const { Title } = Typography;

export interface SubHeaderProps {
  /** Title shown in header */
  title: string;
  /** Button object */
  button?: {
    /** Text in button (if no text is passed in, button will be hidden) */
    text?: string;
    /** Callback when clicking button */
    onClick?: () => void;
    /** Permissions that will show hide the button, if they are not met */
    permissions?: GroupOrScopeItem[];
    /** How to check permissions, AND / OR */
    hasAll?: boolean;
    /** button icon name */
    icon?: string;
    /** button disable */
    disabled?: boolean;
  } | null;
  /** Breadcrumbs  */
  breadcrumbs?: BreadcrumbType[];
  /** Contents of the sub Header */
  children?: React.ReactNode;
}

/** Subheader */
const SubHeader = ({
  breadcrumbs,
  button,
  children,
  title,
}: SubHeaderProps) => {
  const permissions = button?.permissions || [];
  const hasPermissions = useCheckUserPermissions(permissions, button?.hasAll);
  return (
    <>
      <div className={styles.subHeader + " themeSubHeader"}>
        {breadcrumbs && (
          <div className={styles.breadcrumbs}>
            <Breadcrumbs breadcrumbs={breadcrumbs} showSeparatorAfterLastItem />
          </div>
        )}
        <Title level={4} className={styles.title}>
          {title}
        </Title>
        <div>
          {button?.text && hasPermissions && (
            <Button
              type="primary"
              onClick={button.onClick}
              disabled={button.disabled}
            >
              <Icon role="img" type={button.icon || "plus"} />
              {button.text}
            </Button>
          )}
        </div>
      </div>
      {children && (
        <div className={styles.content + " themeSubHeaderContent"}>
          {children}
        </div>
      )}
    </>
  );
};

export default SubHeader;
