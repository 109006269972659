import { useEffect, useState } from "react";

/**
 * Get Window Dimensions
 */
const getWindowDimensions = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

/**
 * Get window dimensions on resize browser
 */
export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};
