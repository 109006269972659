import { lazy } from "react";
import { IMenuItemType } from "router/routerType";

const TrainingScreen = lazy(() => import("training/TrainingScreen"));

/** Screen: Clients Page */

export const TRAINING_SCREEN: IMenuItemType = {
  id: "training",
  path: "/training",
  component: TrainingScreen,
  title: "training",
  icon: "solution",
};
