import { OfficeType } from "../office/officeType";
import { regionsObjectDataLocal } from "./regionType";
import { compact, uniq } from "lodash";

export const createRegionsDataObject = (
  allOffices: OfficeType[],
  locale: string
) => {
  const regionsIds: any[] = allOffices.map(
    (office: OfficeType) => office.region && office.region.id
  );
  const regions = compact(uniq(regionsIds)).sort();
  const regionsData: regionsObjectDataLocal[] = [];

  regions.forEach((id: number) => {
    const office = allOffices.find(
      (office: OfficeType) => office.region && office.region.id === id
    );
    if (office && office.region) {
      regionsData.push({
        title:
          locale === "en-US"
            ? office.region.name
            : office.region.name_localized
            ? office.region.name_localized
            : office.region.name,
        value: `region-${office.region.id}`,
        key: `region-${office.region.id}`,
        id: office.region.id,
        children: [],
      });
    }
  });

  regionsData.forEach((region, index) => {
    const offices = allOffices.filter(
      (office: OfficeType) => office.region && office.region.id === region.id
    );
    offices.forEach((office: OfficeType) => {
      regionsData[index].children.push({
        title:
          locale === "en-US"
            ? office.name
            : office.name_localized
            ? office.name_localized
            : office.name,
        value: office.id,
        key: office.id,
      });
    });
  });
  return regionsData;
};
