import { capturedLoadingRematchModel } from "../../loading";
import * as userApi from "../../users/userApi";
import { PaginationQueryConfig } from "../../api/models";

export const projectFilterUsersModel = capturedLoadingRematchModel(
  "projectFilterUsersModel",
  async (params: PaginationQueryConfig) => {
    const users = await userApi.getUsers(params);
    return users.data;
  }
);

export const projectFilterResponsibleUserModel = capturedLoadingRematchModel(
  "projectFilterResponsibleModel",
  async ({ userId }: { userId: number }) => userApi.getUserById(userId)
);
