import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import "moment/locale/en-gb";
import { NstackInstance } from "@nstack-io/javascript-sdk";
import ENV from "api/config";
import { LocaleOptions } from "./localizationConst";

export const DEFAULT_NS = "translation";

export const nstackClient = new NstackInstance({
  appId: ENV.NSTACK_APP_ID,
  apiKey: ENV.NSTACK_API_KEY,
  version: ENV.VERSION,
  initialLanguage: LocaleOptions.US,
  meta: `web;${ENV.NODE_ENV}`,
});

moment.locale(["en-gb", "JP"]);

i18next.use(initReactI18next).init({
  fallbackLng: LocaleOptions.US,
  lng: LocaleOptions.US,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  defaultNS: DEFAULT_NS,
  resources: {
    "en-US": {
      translation: {
        authentication: {
          emailPlaceholder: "Type your e-mail",
          emailErrorRequired: "E-mail is required",
          emailErrorInvalid: "The input is not a valid e-mail",
          passwordPlaceholder: "Type your password",
          passwordErrorRequired: "Password is required",
          passwordErrorMinLength: "Password has to be at least 8 characters",
          passwordNewPlaceholder: "Input new password",
          passwordOldPlaceholder: "Input old password",
          passwordConfirmationPlaceholder: "Confirm password",
          passwordNewLabel: "New password",
          passwordOldLabel: "Old password",
          passwordConfirmationLabel: "Confirm new password",
          passwordConfirmationErrorRequired:
            "New password and confirm password are not matched",
          passwordConfirmationErrorNotMatching: "Passwords do not match",
          profileButton: "Profile",
          aboutButton: "About",
          loginTitle: "Login",
          loginButton: "Login",
          logoutButton: "Logout",
          forgotPassword: "Forgot password",
          forgotPasswordTitle: "Forgot Password",
          resetPasswordButton: "Reset Password",
          setNewPasswordTitle: "Set New Password",
          setNewPasswordButton: "Set new password",
          messageErrorForgotPassword:
            "We couldn't process your request. Please try again.",
          messageSuccessLogout: "You have been logged out",
          messageSuccessForgotPasswordRequest:
            "We have sent an email with a link to change your password",
          messageSuccessPasswordChanged: "Your password has been changed",
          messageErrorMissingConfirmationToken: "Missing confirmation token!",
          messageJumpCloudAuthentificationSuccessful:
            "JumpCloud Authentification was successful. You will be redirected to the dashboard.",
          messageJumpCloudAuthentificationFailed:
            "JumpCloud Authentification has failed: {{ error }}",
        },
      },
    },
    "ja-JP": {
      translation: {
        authentication: {
          emailPlaceholder: "Type your e-mail",
          emailErrorRequired: "E-mail is required",
          emailErrorInvalid: "The input is not a valid e-mail",
          passwordPlaceholder: "Type your password",
          passwordErrorRequired: "Password is required",
          passwordErrorMinLength: "Password has to be at least 8 characters",
          passwordNewPlaceholder: "Input new password",
          passwordOldPlaceholder: "Input old password",
          passwordConfirmationPlaceholder: "Confirm password",
          passwordNewLabel: "New password",
          passwordOldLabel: "Old password",
          passwordConfirmationLabel: "Confirm new password",
          passwordConfirmationErrorRequired:
            "New password and confirm password are not matched",
          passwordConfirmationErrorNotMatching: "Passwords do not match",
          profileButton: "Profile",
          aboutButton: "About",
          loginTitle: "Login",
          loginButton: "Login",
          logoutButton: "Logout",
          forgotPassword: "Forgot password",
          forgotPasswordTitle: "Forgot Password",
          resetPasswordButton: "Reset Password",
          setNewPasswordTitle: "Set New Password",
          setNewPasswordButton: "Set new password",
          messageErrorForgotPassword:
            "We couldn't process your request. Please try again.",
          messageSuccessLogout: "You have been logged out",
          messageSuccessForgotPasswordRequest:
            "We have sent an email with a link to change your password",
          messageSuccessPasswordChanged: "Your password has been changed",
          messageErrorMissingConfirmationToken: "Missing confirmation token!",
          messageJumpCloudAuthentificationSuccessful:
            "JumpCloud Authentification was successful. You will be redirected to the dashboard.",
          messageJumpCloudAuthentificationFailed:
            "JumpCloud Authentification has failed: {{ error }}",
        },
      },
    },
  },
});

export default i18next;
