import {
  Identity,
  OrganizationOffices,
  UsersMonthySalary,
} from "../identity/permissions/permissionConst";

export const ViewGeographyOverview = [OrganizationOffices.READ];

export const CreateRegion = [OrganizationOffices.WRITE];

export const ViewModifySpecificRegion = [
  OrganizationOffices.READ,
  OrganizationOffices.WRITE,
];

export const DeleteRegion = [OrganizationOffices.DELETE];

export const CreateCountry = [OrganizationOffices.WRITE];

export const ViewModifySpecificCountry = [
  OrganizationOffices.READ,
  OrganizationOffices.WRITE,
];

export const DeleteCountry = [OrganizationOffices.DELETE];

export const CreateOffice = [OrganizationOffices.WRITE];
export const ExportRateCard = [OrganizationOffices.READ];

export const ViewModifySpecificOffice = [
  OrganizationOffices.READ,
  Identity.READ,
  OrganizationOffices.WRITE,
];

export const ViewSpecificOffice = [OrganizationOffices.READ, Identity.READ];

export const ModifySpecificOffice = [OrganizationOffices.WRITE];

export const DeleteOffice = [OrganizationOffices.DELETE];

export const ViewOfficeRateCardOverview = [OrganizationOffices.READ];

export const CreateModifyOfficeRateCard = [OrganizationOffices.WRITE]; // TODO: pending API.

export const ViewOfficeTargetOverview = [OrganizationOffices.READ];

export const CreateModifyOfficeTarget = [OrganizationOffices.WRITE];

export const ViewEmployeeOverviewMonthlySalary = [
  Identity.READ,
  UsersMonthySalary.READ,
];

export const ViewOfficeVisitors = [OrganizationOffices.READ];

export const ViewPracticeRole = [OrganizationOffices.READ];

export const ModifyEmployeeMonthlySalary = [
  Identity.READ,
  UsersMonthySalary.WRITE,
];

export const ViewOfficeLockedMonthOverview = [OrganizationOffices.READ];

export const ModifyOfficeLockedMonthForYear = [
  OrganizationOffices.READ,
  OrganizationOffices.WRITE,
];
