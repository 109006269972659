import { api, getAll } from "api/apiHelper";
import { DEFAULT_ALL_RESULTS_PER_PAGE, Endpoints } from "api/apiConst";
import {
  CurrencyAdminType,
  CurrencyType,
  ExchangeRateType,
} from "currencies/currencyType";
import { UtilHelper } from "app/utilHelper";
import { ApiParamsType } from "api/apiType";
import { ApiResponse } from "../api/models";
import { LogChangeType } from "audit-logs/log.type";

/**
 * Organization: Get all currency
 */
export const getCurrencies = async (
  params?: any
): Promise<ApiResponse<CurrencyType[]>> => {
  const url = Endpoints.CURRENCIES;
  // If params query exists remove all undefined values
  params && UtilHelper.removeUndefined(params);
  try {
    const resp = await api.get(url, {
      params: { ...params },
    });
    return await resp.data;
  } catch (err) {
    throw err;
  }
};

export /**
 * Get all currencies from all pages
 *
 * @param {ApiOptionsType} [options]
 * @returns
 */
const getAllCurrencies = (params?: ApiParamsType): Promise<CurrencyType[]> => {
  return getAll(Endpoints.CURRENCIES, {
    ...params,
    limit: DEFAULT_ALL_RESULTS_PER_PAGE,
  });
};

export const getAdminCurrencyById = (
  currencyId: number,
  params?: any
): Promise<CurrencyAdminType> => getCurrencyById(currencyId, params);

/**
 * Get Currency by Id
 */
export const getCurrencyById = (currencyId: number, params?: object) => {
  const url = `${Endpoints.CURRENCIES}/${currencyId}`;
  return api
    .get(url, {
      params: { ...params },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Create Currency
 */
export const createCurrency = async (
  currency: Partial<CurrencyType>
): Promise<CurrencyType> => {
  const url = Endpoints.CURRENCIES;
  try {
    const resp = await api.post<CurrencyType>(url, currency);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Organization: Edit Currency
 */
export const editCurrency = async (
  currencyId: number,
  currency: Partial<CurrencyType>
): Promise<CurrencyType> => {
  const url = `${Endpoints.CURRENCIES}/${currencyId}`;
  try {
    const resp = await api.patch<CurrencyType>(url, currency);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Organization: Delete Currency
 */
export const deleteCurrency = (currencyId: number) => {
  const url = `${Endpoints.CURRENCIES}/${currencyId}`;

  return api.delete(url);
};

/**
 * Organization: Get Exchange rates of single currency
 */
export const getCurrencyExchangeRates = (currencyId: number, year: string) => {
  const url = `${Endpoints.CURRENCIES}/${currencyId}/values`;

  return api
    .get(url, {
      params: { year },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};
/**
 * Organization: Get Exchange rate by id of single currency
 */
export const getCurrencyExchangeRatesById = (
  currencyId: number,
  exchangeRateId: number
) => {
  const url = `${Endpoints.CURRENCIES}/${currencyId}/values/${exchangeRateId}`;

  return api
    .get(url)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Create Currency
 */
export const createCurrencyExchangeRate = (
  currencyId: number,
  exchangeRate: ExchangeRateType
) => {
  const url = `${Endpoints.CURRENCIES}/${currencyId}/values`;
  return api
    .post(url, exchangeRate)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Edit Currency Exchange Rate
 */
export const editCurrencyExchangeRate = (
  currencyId: number,
  exchangeRateId: number,
  exchangeRate: ExchangeRateType
) => {
  const url = `${Endpoints.CURRENCIES}/${currencyId}/values/${exchangeRateId}`;

  return api
    .patch(url, exchangeRate)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Organization: Delete Currency
 */
export const deleteCurrencyExchangeRate = (
  currencyId: number,
  exchangeRateId: number
) => {
  const url = `${Endpoints.CURRENCIES}/${currencyId}/values/${exchangeRateId}`;

  return api.delete(url);
};

/** Get Logs for currencies */
export const getCurrencyAuditLogs = async (search?: {
  q?: string;
}): Promise<ApiResponse<LogChangeType<any>[]>> => {
  const url = Endpoints.CURRENCIES_AUDIT;

  // If search exists remove all undefined values
  search && UtilHelper.removeUndefined(search);

  try {
    const resp = await api.get(url, {
      params: { ...search },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};
