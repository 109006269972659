import { createRoot } from "react-dom/client";
import React, { useEffect } from "react";
import "./index.scss";
import "./wdyr";
import { BrowserRouter, Prompt } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Router from "./router/Router";
import "./localization/localization";
import { Provider, useSelector } from "react-redux";
import store, { RootState } from "./redux/store";
import { getPersistor } from "@rematch/persist";
import { isAuthenticated, logout } from "./identity/authHelper";
import isEmpty from "lodash/isEmpty";
import { AppContainer } from "react-hot-loader";
import {
  checkPrivateUrl,
  checkPrivateUrlAndStoreUrl,
} from "router/router.utils";
import { PreloadScreen } from "./ui/PreloadScreen";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary } from "bugsnag/bugsnag";
import { useStore } from "redux/hooks";
import { QueryClient, QueryClientProvider } from "react-query";
import { Spin } from "antd";

const queryClient = new QueryClient();

interface Props {}

const App: React.FC<Props> = () => {
  const { dispatch, select } = useStore();
  /** Get localization loading status */
  const localizationLoading: boolean = useSelector(
    select.localizationModel.isLoading
  );

  /** Get logout loading status */
  // const logoutLoading: boolean = useSelector(
  //   (state: RootState) => state.authModel.isLoggingOut
  // );

  /** Dispatch localization loading from NStack */
  useEffect(() => {
    dispatch.localizationModel.request();
  }, [dispatch.localizationModel]);

  /** Disable pinch to zoom */
  useEffect(() => {
    const preventZoom = (e: any) => e.preventDefault();
    document.addEventListener("gesturestart", preventZoom);
    return () => {
      document.removeEventListener("gesturestart", preventZoom);
    };
  }, []);

  /**
   * Turns on hot reloading. This is if hot reloading is available.
   */
  const { hot } = module as any;
  if (hot) {
    hot.accept();
  }

  const persistor = getPersistor();

  const onBeforeLift = async () => {
    checkPrivateUrlAndStoreUrl();
    const state = store.getState();
    if (isAuthenticated() && checkPrivateUrl()) {
      if (isEmpty(state.userModel)) {
        logout();
      }
    }
  };

  const navigatingPromptMessage: string | null = useSelector(
    (state: RootState) => state.appModel.navigatingPromptMessage
  );

  const getConfirmation = (message: string, callback: Function) => {
    const allowTransition = window.confirm(message);
    if (allowTransition) {
      dispatch.appModel.setNavigatingPromptMessage(null);
    }
    callback(allowTransition);
  };

  if (localizationLoading) {
    return <PreloadScreen />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AppContainer>
        <PersistGate
          loading={<PreloadScreen />}
          onBeforeLift={onBeforeLift}
          persistor={persistor}
        >
          <Spin spinning={false}>
            <BrowserRouter getUserConfirmation={getConfirmation}>
              <Router />
              {!!navigatingPromptMessage && (
                <Prompt when={true} message={navigatingPromptMessage} />
              )}
            </BrowserRouter>
          </Spin>
        </PersistGate>
      </AppContainer>
    </QueryClientProvider>
  );
};

const container = document.getElementById("root");

if (!container) throw new Error("Couldn't find element with id root");

const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
