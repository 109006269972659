import { lazy } from "react";
import { IMenuItemType } from "router/routerType";

const AboutScreen = lazy(() => import("about/AboutScreen"));

/** Screen: About Page */

export const ABOUT_SCREEN: IMenuItemType = {
  id: "about",
  path: "/about",
  component: AboutScreen,
  title: "About",
  icon: "bookTwoTone",
};
