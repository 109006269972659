import moment, { unitOfTime } from "moment";
import { DateFormats, Periods } from "./dateConst";
import isNaN from "lodash/isNaN";
import { toDecimal } from "../app/utilHelper";
import { DateFilterDate } from "../ui/DateFilter/DateFilter";
import { TimezoneType } from "geography/office/officeType";
import { TFunction } from "i18next";

export const HOUR_PRECISION = 4;

/** Pass in a seconds and convert to hours with default 4 decimals */
export const secondsToHours = (
  seconds: number | string | undefined,
  precision = HOUR_PRECISION
) => {
  if (!seconds) return 0;
  seconds = Number(seconds);
  if (isNaN(seconds)) {
    console.error("'seconds' parameter has to be a number");
    seconds = 0;
  }

  const hours = seconds / 3600;

  return toDecimal(hours, precision);
};

/** Pass in a hours and convert to seconds */
export const hoursToSeconds = (hours: number | string) => {
  hours = Number(hours);
  if (isNaN(hours)) {
    console.error("'hours' parameter has to be a number");
    hours = 0;
  }

  const seconds = hours * 3600;

  return toDecimal(seconds);
};

/**
 * Takes start time (HH:mm:ss) and adds hours,
 * and returns new time (HH:mm:ss)
 */
export const hoursToHMS = (startTime: string, hours: number | string) => {
  hours = Number(hours);
  if (isNaN(hours)) {
    console.error("'hours' parameter has to be a number");
    hours = 0;
  }

  var time = moment.utc(startTime, DateFormats.API_TIME);
  time.add(hours, "hours");

  return time.format(DateFormats.API_TIME);
};

/**
 * Takes a date
 * and returns the first and last day in the week
 */
export const getWeekDates = (date: moment.Moment) => {
  // moment weekday() is locale aware
  const from = date.clone().weekday(0);
  const to = date.clone().weekday(6);

  return {
    from,
    to,
  };
};

export const getWeekDatesApiFormat = (date: moment.Moment) => {
  const from = date
    .clone()
    .weekday(0)
    .format(DateFormats.API_DATE);
  const to = date
    .clone()
    .weekday(6)
    .format(DateFormats.API_DATE);

  return {
    from,
    to,
  };
};

export const getAllDaysBetweenWeek = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  let dates: string[] = [];

  while (startDate.isSameOrBefore(endDate)) {
    dates.push(startDate.format(DateFormats.API_DATE));
    startDate.add(1, "days");
  }
  return dates;
};

/**
 * Takes a date
 * and returns start date and end date according to period
 */
export const getStartEndDates = (
  date: DateFilterDate | null,
  period: string,
  // if true, clamp end date of range to today.
  onlyInPast?: boolean
) => {
  let startDate;
  let endDate;
  if (!date) {
    return {};
  }
  if (period !== Periods.CUSTOM && !Array.isArray(date)) {
    startDate = moment(date)
      .startOf(period as unitOfTime.StartOf)
      .format(DateFormats.API_DATE);
    let endOfPeriod = moment(date).endOf(period as unitOfTime.StartOf);
    if (onlyInPast) {
      const now = moment();
      if (endOfPeriod.isAfter(now)) {
        endOfPeriod = now;
      }
    }
    endDate = endOfPeriod.format(DateFormats.API_DATE);
  } else if (Array.isArray(date)) {
    startDate = date[0]?.format(DateFormats.API_DATE);
    endDate = date[1]?.format(DateFormats.API_DATE);
  } else {
    startDate = date.toString();
    endDate = date.toISOString();
  }
  return {
    startDate,
    endDate,
  };
};

export const checkDateBetweenTwoDates = (
  start: moment.MomentInput,
  end: moment.MomentInput,
  selected: moment.Moment
) => selected.isBetween(start, end, undefined, "[]");

export const formatTimezone = (timezone: TimezoneType) => {
  if (Math.sign(timezone.offset) === -1) {
    return `${timezone.label} (${timezone.format}${timezone.offset})`;
  } else {
    return `${timezone.label} (${timezone.format}+${timezone.offset})`;
  }
};

/**
 * Format DateTime to show today/tomorrow
 *
 * @param {string} date
 * @param {TFunction} t
 * @param {string} [locale]
 * @returns
 */
export const formatDateTime = (date: string, t: TFunction, locale?: string) => {
  const inputDate = new Date(date);
  const todaysDate = new Date();
  const inputTime = inputDate.toLocaleTimeString(locale || "en-GB", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const inputLocaleDate = inputDate.toLocaleDateString(locale || "en-GB", {
    month: "long",
    day: "2-digit",
    year: "numeric",
  });

  switch (inputDate.setHours(0, 0, 0, 0) - todaysDate.setHours(0, 0, 0, 0)) {
    case 0:
      return `${t("default.today")}, ${inputTime}`;
    case 86400000:
      return `${t("default.tomorrow")}, ${inputTime}`;
    default:
      return `${inputLocaleDate} ${inputTime}`;
  }
};
