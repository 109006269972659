import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Periods } from "../../date/dateConst";
import { DatePicker, Select } from "antd";
import moment from "moment";
import styles from "./DateFilter.module.scss";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import { useTranslation } from "react-i18next";
import YearPicker from "../YearPicker/YearPicker";

const { MonthPicker, WeekPicker, RangePicker } = DatePicker;
const { Option } = Select;

export interface PeriodItem {
  value: Periods;
  text: string;
  default?: boolean;
}

export type DateFilterDate = moment.Moment | RangePickerValue;

interface ControlledDatePickerProps {
  periods?: PeriodItem[];
  defaultDate?: DateFilterDate;
  defaultPeriod?: Periods;
  dateValue?: DateFilterDate;
  periodValue?: Periods;
  onDateChange(date: DateFilterDate | null, periodOverride?: Periods): void;

  onPeriodChange(value: Periods): void;

  /**
   * Default false, set this if you want to only pick dates in the past,
   * including today
   */
  onlyInPast?: boolean;

  /** Default True, set this if you want to hidden button delete of calendar with period week, month */
  allowClear?: boolean;
}

interface Props
  extends Omit<ControlledDatePickerProps, "dateValue" | "periodValue"> {}

const disabledOnlyIfInPast = (onlyInPast: boolean | undefined) => (
  m: moment.Moment | null
) => {
  const isPast = m && (m.isBefore(moment()) || m.isSame(moment()));
  return (onlyInPast && !isPast) || false;
};

export const ControlledDateFilter = ({
  defaultDate,
  periods = [],
  onDateChange,
  onPeriodChange,
  onlyInPast,
  defaultPeriod,
  dateValue,
  periodValue,
  allowClear = true,
}: ControlledDatePickerProps) => {
  const { t } = useTranslation();
  const handleYearPicker = useCallback(
    date => {
      onDateChange(date);
    },
    [onDateChange]
  );

  const handlePeriodChange = useCallback(
    (value: Periods) => {
      onPeriodChange(value);
    },
    [onPeriodChange]
  );

  const handleDateChange = useCallback(
    (date: DateFilterDate | null) => {
      onDateChange(date, periodValue);
    },
    [onDateChange, periodValue]
  );

  const renderedComponent = useMemo(() => {
    switch (periodValue) {
      case Periods.WEEK:
        return (
          <WeekPicker
            allowClear={allowClear}
            value={dateValue as moment.Moment | undefined}
            disabledDate={disabledOnlyIfInPast(onlyInPast)}
            defaultValue={defaultDate as moment.Moment | undefined}
            className={styles.item}
            onChange={handleDateChange}
            placeholder={t("dateFilter.selectWeek")}
          />
        );
      case Periods.MONTH:
        return (
          <MonthPicker
            allowClear={allowClear}
            value={dateValue as moment.Moment | undefined}
            disabledDate={disabledOnlyIfInPast(onlyInPast)}
            defaultValue={defaultDate as moment.Moment | undefined}
            className={styles.item}
            onChange={handleDateChange}
            placeholder={t("dateFilter.selectMonth")}
          />
        );
      case Periods.YEAR:
        return (
          <YearPicker
            className={styles.item}
            onYearChange={handleYearPicker}
            defaultYear={dateValue as moment.Moment | undefined}
            disabledDate={disabledOnlyIfInPast(onlyInPast)}
          />
        );
      case Periods.CUSTOM:
        return (
          <RangePicker
            value={dateValue as RangePickerValue}
            disabledDate={disabledOnlyIfInPast(onlyInPast)}
            defaultValue={defaultDate as RangePickerValue}
            className={styles.item}
            onChange={handleDateChange}
            placeholder={[
              t("dateFilter.selectRangeStart"),
              t("dateFilter.selectRangeEnd"),
            ]}
          />
        );
      case Periods.DATE:
        return (
          <DatePicker
            value={dateValue as moment.Moment | undefined}
            disabledDate={disabledOnlyIfInPast(onlyInPast)}
            defaultValue={defaultDate as moment.Moment | undefined}
            className={styles.item}
            onChange={handleDateChange}
            placeholder={t("dateFilter.selectDate")}
          />
        );
      default:
        return undefined;
    }
  }, [
    periodValue,
    allowClear,
    dateValue,
    onlyInPast,
    defaultDate,
    handleDateChange,
    t,
    handleYearPicker,
  ]);
  return (
    <>
      {renderedComponent}
      {periodValue && periods.length > 1 && (
        <Select
          className={styles.item}
          value={periodValue}
          onChange={handlePeriodChange}
          dropdownMatchSelectWidth={false}
        >
          {periods.map(periodItem => (
            /**
             * In case the value has to be a number, setting the key only
             * is not sufficient. Therefore we set the value prop as well
             * in order to take this scenario into account.
             */
            <Option key={periodItem.value} value={periodItem.value}>
              {periodItem.text}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
};

const DateFilter: FunctionComponent<Props> = ({
  defaultDate,
  periods = [],
  onDateChange,
  onPeriodChange,
  onlyInPast,
  defaultPeriod,
  allowClear = true,
}) => {
  const [period, setPeriod] = useState(
    Array.isArray(defaultDate) ? Periods.CUSTOM : Periods.DATE
  );
  const [date, setDate] = useState<DateFilterDate | undefined>(
    Array.isArray(defaultDate) ? defaultDate : moment(defaultDate)
  );

  // Update the defaultDate when in year mode because of the custom year picker
  useEffect(() => {
    setDate(defaultDate);
  }, [defaultDate, setDate]);

  useEffect(() => {
    const initialPeriod = periods.find(periodItem =>
      defaultPeriod ? periodItem.value === defaultPeriod : periodItem.default
    );
    if (initialPeriod) {
      setPeriod(initialPeriod.value);
    } else if (periods.length) {
      setPeriod(periods[0].value);
    }
  }, [defaultPeriod, periods]);

  const handleDateChange = useCallback(
    date => {
      setDate(date);
      onDateChange(date);
    },
    [setDate, onDateChange]
  );

  const handlePeriodChange = useCallback(
    (value: Periods) => {
      setPeriod(value);
      onPeriodChange(value);
    },
    [onPeriodChange, setPeriod]
  );

  return (
    <ControlledDateFilter
      onlyInPast={onlyInPast}
      periods={periods}
      dateValue={date}
      periodValue={period}
      defaultPeriod={defaultPeriod}
      defaultDate={defaultDate}
      onDateChange={handleDateChange}
      onPeriodChange={handlePeriodChange}
      allowClear={allowClear}
    />
  );
};

export default DateFilter;
