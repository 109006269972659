import { lazy } from "react";
import { IMenuItemType } from "router/routerType";
import { ViewCases } from "cases/cases.permissions";

const CasesScreen = lazy(() => import("publish-cases/CasesScreen"));

/** Screen: Content Page */

export const PUBLISH_CASES_LIST_SCREEN: IMenuItemType = {
  id: "atlas",
  path: "/atlas",
  title: "atlas",
  component: CasesScreen,
  icon: "file-done",
  permissions: ViewCases,
};
