import {
  LoadingModel,
  loadingRematchModel,
  wrapLoading,
} from "../../../loading";
import * as userApi from "users/userApi";
import { UserType } from "../../../users/userType";
import { ApiResponse } from "../../../api/models";

export const salaryEmployeesModel = loadingRematchModel(
  async ({ offices, params }: { offices: number; params: any }) =>
    userApi.getUsers({
      offices,
      ...params,
    }),
  {
    reducers: {
      updateEmployee: (state, payload: UserType) => {
        const employeeData = wrapLoading<ApiResponse<UserType[]>>(state.model)
          .optionalSuccess;
        const users = (employeeData?.data || []).map(u => {
          if (u.id === payload.id) {
            return payload;
          }
          return u;
        });
        return {
          ...state,
          model: LoadingModel.success({
            meta: employeeData?.meta,
            data: users,
          }),
        };
      },
    },
  }
);

export type SalaryEditParams = Partial<UserType> & Pick<UserType, "id">;

export const salaryUserEditModel = loadingRematchModel(
  async (user: SalaryEditParams) => userApi.editUser(user.id, user)
);
