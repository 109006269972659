import React, { PureComponent } from "react";
import moment from "moment";
import upperFirst from "lodash/upperFirst";
import values from "lodash/values";
import cx from "classnames";
import { Tooltip } from "antd";
import { withTranslation, WithTranslation } from "react-i18next";
import SortableTable, { Column } from "ui/SortableTable/SortableTable";
import { Months } from "date/dateConst";
import { RevenueDataType, MonthlyRevenueDataType } from "bi/revenueType";
import { BIResultsTypes } from "bi/insightsConst";
import RevenueColumnData from "../RevenueColumnData/RevenueColumnData";
import RevenueColumn from "../RevenueColumn/RevenueColumn";
import styles from "./RevenueDataList.module.scss";

interface IProps extends WithTranslation {
  /** List of revenue */
  items: RevenueDataType[];
  /** Loading data */
  loading: boolean;
  /** Selected year */
  selectedYear: moment.Moment;
  /** Result type */
  type: string;
  /** Currency ISO */
  currencyIso: string;
  /** Consolidate check */
  isConsolidate?: boolean;
}

interface RevenueDataCellType {
  actual: number;
  target: number;
  difference: number;
}

/**
 * Table with a list of items
 */
class RevenueDataList extends PureComponent<IProps> {
  currentDate = moment();

  render() {
    const {
      selectedYear,
      items,
      loading,
      t,
      type,
      currencyIso,
      isConsolidate,
    } = this.props;
    const RevenueDataCell = (data: RevenueDataCellType) => {
      const { actual, target, difference } = data;
      return (
        <>
          <RevenueColumnData value={actual} isBold currencyIso={currencyIso} />
          <RevenueColumnData value={target} currencyIso={currencyIso} />
          <RevenueColumnData value={difference} currencyIso={currencyIso} />
        </>
      );
    };

    const MonthlyRevenueData = (RevenueData: MonthlyRevenueDataType) => {
      const {
        actual_revenue,
        actual_revenue_inter_company,
        target_revenue,
        difference_revenue,
      } = RevenueData;
      return (
        <RevenueDataCell
          actual={
            isConsolidate
              ? actual_revenue - actual_revenue_inter_company
              : actual_revenue
          }
          target={target_revenue}
          difference={
            isConsolidate
              ? difference_revenue - actual_revenue_inter_company
              : difference_revenue
          }
        />
      );
    };

    const MonthlySalesData = (SalesData: MonthlyRevenueDataType) => {
      const {
        actual_sold,
        actual_sold_inter_company,
        target_sold,
        difference_sold,
      } = SalesData;
      return (
        <RevenueDataCell
          actual={
            isConsolidate
              ? actual_sold - actual_sold_inter_company
              : actual_sold
          }
          target={target_sold}
          difference={
            isConsolidate
              ? difference_sold - actual_sold_inter_company
              : difference_sold
          }
        />
      );
    };

    const MonthlyAverageHoursData = (SalesData: MonthlyRevenueDataType) => {
      const { avr_hourly_rate } = SalesData;
      return <RevenueColumn value={avr_hourly_rate} isCentered />;
    };

    return (
      <SortableTable
        scroll={{ x: 1500, y: 540 }}
        className={cx(styles.expandableTable, {
          [styles.tdPadding]: type !== BIResultsTypes.AVERAGE_HOUR,
        })}
        loading={loading}
        data={items}
        disablePagination
        localization={{ emptyListText: t("insightsRevenue.emptyListText") }}
      >
        <Column
          title={t("insightsRevenue.columnName")}
          dataIndex="name"
          key="name"
          fixed="left"
          width={300}
        />
        <Column
          key="label"
          className={styles.labelColumn}
          width={150}
          render={() => {
            return BIResultsTypes.AVERAGE_HOUR === type ? (
              <Tooltip
                placement="top"
                title={t("insightsRevenue.averageHourlyRate")}
              >
                <div className={styles.infoIcon}></div>
              </Tooltip>
            ) : (
              <>
                <RevenueColumn
                  value={t("insightsRevenue.labelActual")}
                  isBold
                />
                <RevenueColumn value={t("insightsRevenue.labelTarget")} />
                <RevenueColumn value={t("insightsRevenue.labelDifference")} />
              </>
            );
          }}
        />

        {values(Months).map(month => {
          const selectedYearMonth = selectedYear.month(month);
          const isCurrentMonth =
            this.currentDate.isSame(selectedYearMonth, "year") &&
            this.currentDate.isSame(selectedYearMonth, "month");
          return (
            <Column
              title={t(`insightsRevenue.column${upperFirst(month)}`)}
              dataIndex={month}
              key={month}
              className={cx(styles.monthColumn, {
                [styles.isCurrentMonth]: isCurrentMonth,
              })}
              render={(data: MonthlyRevenueDataType) => {
                switch (type) {
                  case BIResultsTypes.REVENUE:
                    return <MonthlyRevenueData {...data} />;
                  case BIResultsTypes.AVERAGE_HOUR:
                    return <MonthlyAverageHoursData {...data} />;
                  default:
                    return <MonthlySalesData {...data} />;
                }
              }}
              width={150}
            />
          );
        })}
      </SortableTable>
    );
  }
}

export default withTranslation()(RevenueDataList);
