import axios, { AxiosError, AxiosRequestConfig } from "axios";
import ENV from "./config";
import {
  getTokens,
  //refreshToken,
  unauthorizedLogout,
} from "../identity/authHelper";
import {
  DEFAULT_API_PARAMS,
  Endpoints,
  ResponseStatus,
  API_CHECK_WHITELIST,
} from "api/apiConst";
import { message } from "antd";
import i18next from "../localization/localization";
import qs from "query-string";
import { ApiParamsType } from "api/apiType";
import { LOCAL_STORAGE_REDIRECT_URL } from "app/utils/storageConst";
import store from "redux/store";
import { ERROR_ROUTES } from "router/Router.config";
// import packageJson from "../../package.json";

interface MyWindow extends Window {}

declare var window: MyWindow;

/** Excluded endpoints for authorization */
const anonymousEndpoints = [
  Endpoints.USERS_LOGIN,
  Endpoints.USERS_RESET_PASSWORD,
  Endpoints.USERS_REFRESH,
  Endpoints.USERS_SET_NEW_PASSWORD,
];

/**
 * Adds autherization headers to API calls
 * @param {AxiosRequestConfig} request
 */
const authInterceptor = async (request: AxiosRequestConfig) => {
  const isAnonymous = anonymousEndpoints.includes(request.url || "");

  const { accessToken, expireAt } = getTokens();

  if (isAnonymous) {
    return request;
  } else if (request.url === Endpoints.USERS_LOGOUT && accessToken) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
    return request;
  } else if (!accessToken) {
    unauthorizedLogout();
    return Promise.reject(ResponseStatus.UNAUTHORIZED);
  }

  request.headers["Authorization"] = `Bearer ${accessToken}`;
  const header = request.headers["Authorization"];

  if (header && header.startsWith("Bearer")) {
    // Check if access token has expired
    if (new Date(expireAt).getTime() < new Date().getTime()) {
      unauthorizedLogout();
      // Try and refresh the access token
      // await refreshToken().then(() => {
      //   const { accessToken } = getTokens();
      //   request.headers["Authorization"] = `Bearer ${accessToken}`;
      // });
    }
  }

  return request;
};

/**
 * Add locale header to API calls
 *
 * @param {AxiosRequestConfig} request
 * @returns Updated request
 */
const localeInterceptor = async (request: AxiosRequestConfig) => {
  try {
    const state = store.getState();
    const locale = state.authModel.locale;
    request.headers["Accept-Language"] = locale;
    return request;
  } catch (e) {
  } finally {
    return request;
  }
};

/**
 * Remove Redirct url
 */
const removeRedirectUrl = () => {
  setTimeout(() => {
    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_URL);
  });
};

/**
 * Axios error interceptor
 * @param {AxiosError} axiosError
 */
const errorInterceptor = (axiosError: AxiosError) => {
  if (axiosError && axiosError?.response) {
    const response = axiosError?.response;
    const url: string | undefined = response?.config?.url;

    if (response.status === ResponseStatus.UNAUTHORIZED) {
      unauthorizedLogout();
      return;
    }

    if (response.status.toString().startsWith("5")) {
      /**
       * If response status starts with a 5, indicating a server error,
       * then display user friendly error message
       */
      let check: boolean = false;

      for (let index = 0; index < API_CHECK_WHITELIST.length; index += 1) {
        let isCheck: boolean = false;
        const checkLength: number = API_CHECK_WHITELIST[index].checks.length;
        if (checkLength === 1) {
          isCheck =
            url && url.indexOf(API_CHECK_WHITELIST[index].checks[0]) > -1
              ? true
              : false;
        } else if (checkLength === 2) {
          isCheck =
            url &&
            url.indexOf(API_CHECK_WHITELIST[index].checks[0]) > -1 &&
            url.indexOf(API_CHECK_WHITELIST[index].checks[1]) > -1
              ? true
              : false;
        } else if (checkLength === 3) {
          isCheck =
            url &&
            url.indexOf(API_CHECK_WHITELIST[index].checks[0]) > -1 &&
            url.indexOf(API_CHECK_WHITELIST[index].checks[1]) > -1 &&
            url.indexOf(API_CHECK_WHITELIST[index].checks[2]) > -1
              ? true
              : false;
        }
        if (isCheck) {
          check = isCheck;
          break;
        }
      }

      if (!check) {
        window.location.href = ERROR_ROUTES.ERROR_PATH;
        removeRedirectUrl();
      }
      message.error(i18next.t("default.fallbackServerError"));
    } else {
      // Else display error message returned from API
      if (response?.status === ResponseStatus.DENIED) {
        if (response?.data?.errors?.length > 0) {
          for (let error of response?.data?.errors) {
            for (let err of error?.errors) {
              if (err?.message) {
                message.error(err?.message);
              }
            }
          }
        } else {
          message.error(response?.data?.message);
        }
        removeRedirectUrl();
      } else if (response.status === ResponseStatus.NOT_FOUND) {
        const items: string[] | undefined = url?.split("/");
        if (
          items &&
          items[1] === "allocations" &&
          typeof Number(items[2]) === "number"
        ) {
          return;
        }
        window.location.href = ERROR_ROUTES.NOT_FOUND_PATH;
        removeRedirectUrl();
      } else if (response.status === ResponseStatus.UNAUTHORIZED) {
        unauthorizedLogout();
      } else {
        const apiErrorMessage = response?.data?.localized_message
          ? response.data?.localized_message
          : response.data?.message;

        if (apiErrorMessage) {
          message.error(apiErrorMessage);
        }
      }
    }
  } else if (typeof axiosError === "object") {
    // If error response from API is not readeable, show default error
    // message.error(i18next.t("default.fallbackError"));
  } else {
    // If not a custom 401 error, display error
    if (axiosError !== ResponseStatus.UNAUTHORIZED) {
      unauthorizedLogout();
      message.error(axiosError);
    }
  }

  return Promise.reject(axiosError);
};

/** Setup an API instance */
export const api = axios.create({
  baseURL: ENV.API_HOST,
  headers: {
    Application: "nplanner-web-v1",
    "Content-Type": "application/json",
  },
  paramsSerializer: params => {
    return qs.stringify(params, { arrayFormat: "index" });
  },
});

/** Add interceptor */
api.interceptors.request.use(authInterceptor);
api.interceptors.request.use(localeInterceptor);
api.interceptors.response.use(res => res, errorInterceptor);

api.interceptors.response.use(
  response => {
    // const versionFE = packageJson.version;
    // const versionBE = response.headers["x-fe-version"];

    if ("caches" in window) {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });
    }

    // if (Number(versionBE) >= Number(versionFE)) {
    // }

    return response;
  },
  error => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

/**
 * Recursively goes through pages and adds response data to array
 *
 * @param {string} endpoint Endpoint of the api call
 * @param {ApiOptionsType} [options=apiOptionsDefault]
 * @returns Array of all data from endpoint
 */
export const getAll = <T extends any>(
  endpoint: string,
  params: ApiParamsType = DEFAULT_API_PARAMS,
  accumulator: T[] = []
): Promise<T[]> => {
  params = { ...DEFAULT_API_PARAMS, ...params };
  return api
    .get(endpoint, {
      params,
    })
    .then((response: any) => response.data)
    .then(({ data, meta }) => {
      accumulator = [...accumulator, ...data];
      const { current_page, total_pages } = meta.pagination;
      if (current_page === total_pages) {
        return accumulator;
      } else {
        return getAll(
          endpoint,
          {
            ...params,
            page: current_page + 1,
          },
          accumulator
        );
      }
    })
    .catch(err => {
      throw err;
    });
};
