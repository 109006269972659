import { lazy } from "react";
import { IMenuItemType } from "router/routerType";
import { RouterParameters } from "router/routerConst";
import {
  CreateModifyProject,
  CreateModifySpecificSalesItem,
  ViewProjects,
  ViewSpecificProject,
  ViewTeamMember,
} from "./project.permissions";
import { CreateModifyProjectInvoices } from "./invoices/invoices.permissions";

const ProjectsScreen = lazy(() =>
  import("projects/ProjectsScreen/ProjectsScreen")
);
const ProjectScreen = lazy(() =>
  import("projects/ProjectScreen/ProjectScreen")
);

/** Screen: Projects Overview */
export const PROJECTS_SCREEN: IMenuItemType = {
  id: "projects",
  path: "/projects",
  component: ProjectsScreen,
  title: "projects",
  icon: "file-done",
  permissions: ViewProjects,
};

/** Screen: Create Project */
export const PROJECT_CREATE_SCREEN: IMenuItemType = {
  ...PROJECTS_SCREEN,
  path: PROJECTS_SCREEN.path + RouterParameters.CREATE,
  permissions: CreateModifyProject,
};

/** Screen: Project Details */
export const PROJECT_DETAILS_SCREEN: IMenuItemType = {
  id: "project",
  path: PROJECTS_SCREEN.path + `/${RouterParameters.ID}`,
  component: ProjectScreen,
  permissions: ViewSpecificProject,
};

export const PROJECT_EDIT_SCREEN: IMenuItemType = {
  ...PROJECT_DETAILS_SCREEN,
  path: PROJECT_DETAILS_SCREEN.path + RouterParameters.EDIT,
  permissions: CreateModifyProject,
};

export const PROJECT_ADD_TEAM_MEMBER: IMenuItemType = {
  ...PROJECT_DETAILS_SCREEN,
  path: PROJECT_DETAILS_SCREEN.path + "/add-team-member",
  permissions: ViewTeamMember,
};

export const PROJECT_EDIT_TEAM_MEMBER: IMenuItemType = {
  ...PROJECT_DETAILS_SCREEN,
  path:
    PROJECT_DETAILS_SCREEN.path +
    "/edit-team-member" +
    `${RouterParameters.ITEM_ID}`,
  permissions: ViewTeamMember,
};

export const PROJECT_UPDATE_RATECARD: IMenuItemType = {
  ...PROJECT_DETAILS_SCREEN,
  path: PROJECT_DETAILS_SCREEN.path + "/update-rate-card",
};

export const PROJECT_CREATE_ALLOCATION_REQUEST: IMenuItemType = {
  ...PROJECT_DETAILS_SCREEN,
  path: PROJECT_DETAILS_SCREEN.path + "/create-allocation-request",
};

export const PROJECT_ADD_SALES_ITEM: IMenuItemType = {
  ...PROJECT_DETAILS_SCREEN,
  path: PROJECT_DETAILS_SCREEN.path + "/add-budget-item",
  permissions: CreateModifySpecificSalesItem,
};

export const PROJECT_EDIT_SALES_ITEM: IMenuItemType = {
  ...PROJECT_DETAILS_SCREEN,
  path:
    PROJECT_DETAILS_SCREEN.path +
    "/edit-budget-item" +
    `${RouterParameters.ITEM_ID}`,
  permissions: CreateModifySpecificSalesItem,
};

export const PROJECT_ADD_INVOICE_ITEM: IMenuItemType = {
  ...PROJECT_DETAILS_SCREEN,
  path: PROJECT_DETAILS_SCREEN.path + "/add-invoice",
  permissions: CreateModifyProjectInvoices,
};

export const PROJECT_EDIT_INVOICE_ITEM: IMenuItemType = {
  ...PROJECT_DETAILS_SCREEN,
  path:
    PROJECT_DETAILS_SCREEN.path +
    "/edit-invoice" +
    `${RouterParameters.ITEM_ID}`,
  permissions: CreateModifyProjectInvoices,
};
