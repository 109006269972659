import { api, getAll } from "../api/apiHelper";
import { Endpoints, DEFAULT_RESULTS_PER_PAGE } from "../api/apiConst";
import { ApiResponse } from "api/models";
import { UtilHelper } from "app/utilHelper";
import { LogChangeType } from "audit-logs/log.type";
import {
  AllocationFilters,
  AllocationType,
  AllocationFormType,
} from "./allocationType";
import { AllocationIncludes } from "./allocationConst";
import { RoleType } from "../roles/roleType";
import _cloneDeep from "lodash/cloneDeep";

/** Get allocation */
export const getAllocation = (id: number) => {
  const url = `${Endpoints.ALLOCATIONS}/${id}`;
  return api
    .get(url)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** Create allocation */
export const createAllocation = (allocation: AllocationFormType) => {
  const url = Endpoints.ALLOCATIONS;
  return api
    .post(url, allocation)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};

/** Edit allocation */
export const editAllocation = (id: number, allocation: AllocationFormType) => {
  let url = `${Endpoints.ALLOCATIONS}/${id}`;
  return api
    .patch(url, allocation)
    .then((resp: any) => resp.data)
    .catch(err => {
      throw err;
    });
};

/**
 * Split allocation
 *
 * @param {number} id Allocation id
 * @param {string} date Split date
 * @returns
 */
export const splitAllocation = (id: number, date: string) => {
  const url = `${Endpoints.ALLOCATIONS}/${id}/${Endpoints.ALLOCATION_SPLIT}`;

  return api
    .post(url, { date })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** Delete allocation */
export const deleteAllocation = (id: number) => {
  const url = `${Endpoints.ALLOCATIONS}/${id}`;
  return api.delete(url);
};

/**
 * Export allocation
 *
 * @param {string} date
 * @returns
 */
export const exportAllocation = (params: any) => {
  const url = `${Endpoints.REPORTS_ALLOCATION}`;

  return api
    .get(url, {
      params: {
        ...params,
      },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};

/** Signed Url Download */

/** Get signed url for report download */
export const getAllocationsReportSignedUrl = (key: string) => {
  const url = `${Endpoints.REPORTS_ALLOCATIONS_DOWNLOAD}/${key}`;

  return api
    .get(url)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};

export const getUserAllocationsWithProjects = async (
  params: AllocationFilters
) => {
  try {
    const resp: AllocationType[] = await getAllUserAllocationsWithProjects(
      params
    );
    return await {
      data: resp,
      meta: {
        pagination: {
          count: DEFAULT_RESULTS_PER_PAGE,
          current_page: Math.ceil(resp.length / DEFAULT_RESULTS_PER_PAGE),
          per_page: DEFAULT_RESULTS_PER_PAGE,
          total: resp.length,
          total_pages: Math.ceil(resp.length / DEFAULT_RESULTS_PER_PAGE),
        },
      },
    };
  } catch (err) {
    throw err;
  }
};

const getAllUserAllocationsWithProjects = async (
  params?: any
): Promise<AllocationType[]> => {
  return await getAll(Endpoints.ALLOCATIONS_OVERVIEW_LIST, {
    ...params,
    limit: DEFAULT_RESULTS_PER_PAGE,
  });
};

export const getUserAllocations = async (
  params?: any
): Promise<AllocationType[]> => {
  try {
    const resp = await api.get(Endpoints.ALLOCATIONS_OVERVIEW_LIST, {
      params: { ...params },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

/** Get Logs for tracking */
export const getTrackingAuditLogs = async (search?: {
  q?: string;
}): Promise<ApiResponse<LogChangeType<any>[]>> => {
  const url = Endpoints.TRACKING_AUDIT;

  // If search exists remove all undefined values
  search && UtilHelper.removeUndefined(search);

  try {
    const resp = await api.get(url, {
      params: { ...search },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

/** Get Logs for Allocations */
export const getAllocationsAuditLogs = async (search?: {
  q?: string;
}): Promise<ApiResponse<LogChangeType<any>[]>> => {
  const url = Endpoints.ALLOCATIONS_AUDIT;

  // If search exists remove all undefined values
  search && UtilHelper.removeUndefined(search);

  try {
    const resp = await api.get(url, {
      params: { ...search },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

/** Get Updated by for an allocation  */
export const getAllocationUpdatedBy = async (
  id: number,
  includes?: AllocationIncludes[]
): Promise<AllocationType[]> => {
  const url = `${Endpoints.ALLOCATIONS}/${id}`;
  try {
    const resp = await api.get(url, {
      params: { include: includes },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export const getTeamList = async (params?: any): Promise<AllocationType[]> => {
  const URL = Endpoints.ALLOCATIONS_TEAM_LIST;

  // If search exists remove all undefined values
  params && UtilHelper.removeUndefined(params);

  try {
    const resp = await api.get(URL, {
      params: { ...params },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export const getProjectsByUserId = async (
  id: number | string,
  params?: any
): Promise<AllocationType[]> => {
  const URL = `${Endpoints.ALLOCATIONS_TEAM_LIST}/${id}/projects`;
  // If search exists remove all undefined values
  params && UtilHelper.removeUndefined(params);

  try {
    const resp = await api.get(URL, {
      params: { ...params },
    });
    return resp.data.data;
  } catch (err) {
    throw err;
  }
};

export const getProjectList = async (
  params?: any
): Promise<AllocationType[]> => {
  const URL = Endpoints.ALLOCATIONS_PROJECTS_LIST;
  // If search exists remove all undefined values
  params && UtilHelper.removeUndefined(params);

  try {
    const response: any = await api.get(URL, {
      params: { ...params },
    });
    const projects = _cloneDeep(response?.data);
    for (let index = 0; index < response?.data.length; index++) {
      projects[index].roles = response?.data[index].roles.map(
        (role: RoleType) => role.id
      );
      projects[index].hours_tracked = projects[index].tracked_hours;
      projects[index].hours_allocated = projects[index].total_allocated_hours;
    }
    response.data = projects;
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const getMembersByProjectId = async (
  id: number | string,
  params: any
): Promise<AllocationType[]> => {
  const URL = `${Endpoints.ALLOCATIONS_PROJECTS_LIST}/${id}/members`;
  // If search exists remove all undefined values
  params && UtilHelper.removeUndefined(params);

  try {
    return await getAll(URL, {
      ...params,
      limit: DEFAULT_RESULTS_PER_PAGE,
    });
  } catch (err) {
    throw err;
  }
};

export const getRateCardList = async (params?: any) => {
  const URL = `${Endpoints.PROJECT_RATE_CARDS}/unique-list`;

  // If search exists remove all undefined values
  params && UtilHelper.removeUndefined(params);

  try {
    const resp = await api.get(URL, {
      params: { ...params },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};
