import { lazy } from "react";
import { IMenuItemType } from "../router/routerType";
import {
  ViewAllocations,
  ViewAllocationsRequests,
} from "./allocation.permissions";
import { RouterParameters } from "router/routerConst";

const AllocationsScreen = lazy(() => import("./AllocationScreen"));
const AllocationRequestScreen = lazy(() => import("./Request/Request"));

export const ALLOCATION: IMenuItemType = {
  id: "allocation",
  path: "/allocation",
  title: "allocation",
  icon: "sync",
};

export const ALLOCATION_SCREEN: IMenuItemType = {
  ...ALLOCATION,
  id: "allocation-screen",
  path: ALLOCATION.path,
  component: AllocationsScreen,
  title: "overview",
  permissions: ViewAllocations,
};

export const ALLOCATION_REQUEST: IMenuItemType = {
  ...ALLOCATION,
  id: "allocation-screen",
  path: `${ALLOCATION.path}/request`,
  component: AllocationRequestScreen,
  title: "allocationRequest",
  permissions: ViewAllocationsRequests,
};

export const ALLOCATION_REQUEST_VIEW: IMenuItemType = {
  ...ALLOCATION_REQUEST,
  path: `${ALLOCATION_REQUEST.path}${RouterParameters.ITEM_ID}`,
};

/** Insights subitems */
ALLOCATION.subMenuItems = [ALLOCATION_SCREEN, ALLOCATION_REQUEST];
