import * as skillApi from "skills/skillApi";
import { DEFAULT_API_PARAMS } from "api/apiConst";
import { PaginationQueryParams } from "api/models";
import {
  capturedLoadingRematchModel,
  CapturedState,
  removeCapturedApiListItem,
  removeCapturedListItem,
  updateCapturedApiLoadingModel,
  updateCapturedLoadingModel,
} from "../loading";
import { SkillType } from "./skillType";
import { SettingsParams } from "../settings/settingsConst";

export interface EditSkillsFormParams {
  skillId: number | undefined;
  values: SkillType;
}

export const allSkillsModel = capturedLoadingRematchModel(
  "allSkillsModel",
  async () => skillApi.getAllSkills(),
  {
    reducers: {
      removeSkill: (state, skillId: number) => {
        return {
          ...state,
          model: removeCapturedListItem(state.model, "id", skillId),
        };
      },
      "editSkillsFormSubmitModel/setmodelSuccess": (
        state,
        payload: CapturedState<EditSkillsFormParams, SkillType>
      ) => ({
        ...state,
        model: updateCapturedLoadingModel(
          state.model,
          "id",
          payload,
          "skillId"
        ),
      }),
    },
  }
);

export const editSkillModel = capturedLoadingRematchModel(
  "editSkillModel",
  async ({ skillId }: { skillId: number }) =>
    skillApi.getAdminSkill(skillId, SettingsParams)
);

export const editSkillsFormSubmitModel = capturedLoadingRematchModel(
  "editSkillsFormSubmitModel",
  async ({ skillId, values }: EditSkillsFormParams) =>
    skillId ? skillApi.editSkill(skillId, values) : skillApi.createSkill(values)
);

export const skillsModel = capturedLoadingRematchModel(
  "skillsModel",
  async (params: PaginationQueryParams) =>
    skillApi.getSkills({
      ...DEFAULT_API_PARAMS,
      ...params,
    }),
  {
    reducers: {
      removeSkill: (state, skillId: number) => {
        return {
          ...state,
          model: removeCapturedApiListItem(state.model, "id", skillId),
        };
      },
      "editSkillsFormSubmitModel/setmodelSuccess": (
        state,
        payload: CapturedState<EditSkillsFormParams, SkillType>
      ) => ({
        ...state,
        model: updateCapturedApiLoadingModel(
          state.model,
          "id",
          payload,
          "skillId"
        ),
      }),
    },
    effects: (dispatch: any) => ({
      deleteSkill: async ({
        skillId,
      }: Pick<EditSkillsFormParams, "skillId">) => {
        if (skillId) {
          await skillApi.deleteSkill(skillId);
          dispatch.skillsModel.removeSkill(skillId);
          dispatch.allSkillsModel.removeSkill(skillId);
        }
      },
    }),
  }
);
