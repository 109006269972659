import { lazy } from "react";
import { IMenuItemType } from "router/routerType";
import { RouterParameters } from "router/routerConst";
import { SETTINGS_CURRENCIES_SCREEN } from "currencies/router.currencies";
import { SETTINGS_GEOGRAPHY_SCREEN } from "../geography/router.geography";
import { SETTINGS_PERMISSION_SCREEN } from "../identity/permissions/router.permissions";
import {
  CreateModifyRolesSkills,
  ViewRolesSkills,
} from "./settings.permissions";
import { SETTINGS_USER_SCOPES_SCREEN } from "../identity/userScopes/router.userScopes";
import { CASES_LIST_SCREEN } from "cases/router.cases";
import { SETTINGS_CASES_SCREEN } from "../geography/router.cases";

const RolesAndSkillsScreen = lazy(() =>
  import("settings/RolesAndSkillsScreen")
);

/** Screen: Settings Page */
export const SETTINGS_SCREEN: IMenuItemType = {
  id: "settings",
  title: "settings",
  path: "/settings",
  icon: "setting",
};

/** Screen: Settings Roles and skills Page */
export const SETTINGS_ROLES_SKILLS_SCREEN: IMenuItemType = {
  id: "roles-skills",
  title: "rolesAndSkills",
  path: SETTINGS_SCREEN.path + `/roles-and-skills/${RouterParameters.TAB}`,
  component: RolesAndSkillsScreen,
  permissions: ViewRolesSkills,
};

export const SETTINGS_ROLES_SCREEN: IMenuItemType = {
  id: "roles",
  title: "rolesAndSkills",
  path: SETTINGS_SCREEN.path + `/roles-and-skills/roles`,
  component: RolesAndSkillsScreen,
  permissions: ViewRolesSkills,
};

export const SETTINGS_SKILLS_SCREEN: IMenuItemType = {
  id: "skills",
  title: "rolesAndSkills",
  path: SETTINGS_SCREEN.path + `/roles-and-skills/skills`,
  component: RolesAndSkillsScreen,
  permissions: ViewRolesSkills,
};

export const SETTINGS_PRACTICES_SCREEN: IMenuItemType = {
  id: "practices",
  title: "rolesAndSkills",
  path: SETTINGS_SCREEN.path + `/roles-and-skills/practices`,
  component: RolesAndSkillsScreen,
  permissions: ViewRolesSkills,
};

export const SETTINGS_ROLES_SKILLS_EDIT_SCREEN: IMenuItemType = {
  ...SETTINGS_ROLES_SKILLS_SCREEN,
  path:
    SETTINGS_ROLES_SKILLS_SCREEN.path +
    RouterParameters.EDIT +
    RouterParameters.ITEM_ID,
  permissions: CreateModifyRolesSkills,
};
export const SETTINGS_ROLES_SKILLS_CREATE_SCREEN: IMenuItemType = {
  ...SETTINGS_ROLES_SKILLS_SCREEN,
  path: SETTINGS_ROLES_SKILLS_SCREEN.path + RouterParameters.CREATE,
  permissions: CreateModifyRolesSkills,
};

/** Settings subitems */
SETTINGS_SCREEN.subMenuItems = [
  SETTINGS_GEOGRAPHY_SCREEN,
  SETTINGS_CURRENCIES_SCREEN,
  SETTINGS_ROLES_SKILLS_SCREEN,
  SETTINGS_PERMISSION_SCREEN,
  SETTINGS_USER_SCOPES_SCREEN,
  CASES_LIST_SCREEN,
  SETTINGS_CASES_SCREEN,
];
