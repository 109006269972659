import { RootState } from "redux/store";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import { InfoMessage } from "allocations/AllocationFiltersAlert";
import { CalendarType } from "calendar/calendarConst";
import { DEFAULT_API_PARAMS } from "api/apiConst";
import { PaginationQueryParams } from "../api/models";
import { RoleType } from "../roles/roleType";
import { AllocationTypes } from "./allocationConst";
import {
  ProjectFilterType,
  TeamFilterType,
} from "./Header/Filters/FilterTypes";
import { capturedLoadingRematchModel } from "../loading";
import * as allocationApi from "./allocationApi";

type TeamFiltersState = Readonly<Omit<TeamFilterType, "offices" | "search">>;

export const teamFiltersModel = {
  state: { billable: true } as TeamFiltersState,
  reducers: {
    setState: (
      state: TeamFiltersState,
      payload: Omit<TeamFilterType, "offices" | "search">
    ) => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch: any) => ({
    getFiltersCount(payload: any, rootState: RootState) {
      /** get selected offices (counts as a filter) */
      const selectedOffices =
        rootState.userModel.model.success?.selected_offices;
      /** get search (counts as a filter) */
      const search = rootState.allocationScreenModel.search;
      /** get the rest of the team filters */
      const {
        managers,
        freelancers,
        billable,
        availability,
        service_offering_ids,
        endDate,
        startDate,
        practice_role_ids,
      } = rootState.teamFiltersModel;
      let filterCount = 0;
      if (!_isEmpty(selectedOffices)) filterCount++;
      if (!_isEmpty(managers)) filterCount++;
      if (!_isEmpty(practice_role_ids)) filterCount++;
      if (!_isEmpty(service_offering_ids)) filterCount++;
      if (freelancers !== undefined) filterCount++;
      if (billable !== undefined) filterCount++;
      if (availability && endDate && startDate) filterCount++;
      if (search) filterCount++;
      return filterCount;
    },
  }),
};

type ProjectFiltersState = Readonly<
  Omit<ProjectFilterType, "offices" | "search">
>;

export const projectFiltersModel = {
  state: {} as ProjectFiltersState,
  reducers: {
    setState: (
      state: ProjectFiltersState,
      payload: Omit<ProjectFilterType, "offices" | "search">
    ) => ({
      ...state,
      ...payload,
    }),
  },
  effects: (dispatch: any) => ({
    getFiltersCount(payload: any, rootState: RootState) {
      /** get selected offices (counts as a filter) */
      const selectedOffices =
        rootState.userModel.model?.success?.selected_offices;
      /** get search (counts as a filter) */
      const search = rootState.allocationScreenModel.search;
      /** get the rest of the project filters */
      const { members, archived } = rootState.projectFiltersModel;
      let filterCount = 0;
      if (!_isEmpty(selectedOffices)) filterCount++;
      if (!_isEmpty(members)) filterCount++;
      if (archived) filterCount++;
      if (search) filterCount++;
      return filterCount;
    },
  }),
};

type AllocationScreenType = {
  type: AllocationTypes;
  search?: string;
  info?: InfoMessage;
  mode?: string;
  teamTime?: number | null;
  projectTime?: number | null;
  teamLoading?: boolean;
  projectLoading?: boolean;
  datePickerVisible: boolean;
  loading: boolean;
  apiUpdate: number;
  isSplit: boolean;
};

type AllocationScreenState = Readonly<AllocationScreenType>;

export const allocationScreenModel = {
  state: {
    type: AllocationTypes.TEAM,
    teamTime: null,
    projectTime: null,
    teamLoading: false,
    projectLoading: false,
    mode: CalendarType.WEEK,
    datePickerVisible: false,
    loading: false,
    apiUpdate: Math.random(),
    isSplit: false,
  } as AllocationScreenState,
  reducers: {
    setState: (
      state: AllocationScreenState,
      payload: Partial<AllocationScreenType>
    ) => ({
      ...state,
      ...payload,
    }),
  },
};

export const projectListModel = capturedLoadingRematchModel(
  "projectListModel",
  async (params: PaginationQueryParams) => {
    const response: any = await allocationApi.getProjectList({
      ...DEFAULT_API_PARAMS,
      ...params,
    });
    const projects = _cloneDeep(response?.data);
    for (let index = 0; index < response?.data.length; index++) {
      projects[index].roles = response?.data[index].roles.map(
        (role: RoleType) => role.id
      );
      projects[index].hours_tracked = projects[index].tracked_hours;
      projects[index].hours_allocated = projects[index].total_allocated_hours;
    }
    response.data = projects;
    return response;
  }
);

export const teamListModel = capturedLoadingRematchModel(
  "teamListModel",
  async (params: PaginationQueryParams) =>
    allocationApi.getTeamList({
      ...DEFAULT_API_PARAMS,
      ...params,
    })
);
