import { useTranslation } from "react-i18next";
import { Empty } from "antd";
import Column from "antd/es/table/Column";
import { Link } from "react-router-dom";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { formatRoute } from "react-router-named-routes";
import { ProjectActivity } from "bi/project/projectActivityTypes";
import { ApiResponse, PaginationQueryConfig } from "api/models";
import SortableTable, {
  getColumnSearchProps,
} from "ui/SortableTable/SortableTable";
import AllocationLink from "allocations/AllocationLink/AllocationLink";
import { PRIVATE_ROUTES } from "router/Router.config";
import CurrencyFormat from "ui/CurrencyFormat";
import { useSelector } from "react-redux";
import { getUserFilters } from "users/filterHelpers";
import { useStore } from "redux/hooks";
import {
  convertTableUpdateToQueryParams,
  firstFilterParam,
  PaginationParams,
  usePagination,
} from "../../api/paginationHelpers";
import { PaginationConfig } from "antd/es/pagination";
import { SorterResult } from "antd/es/table";
import { DefaultTabProps } from "../../ui/tabs/TabScreen";
import { TabPane } from "../../ui/tabs/Tabs";
import debounce from "lodash/debounce";
import { UserType } from "users/userType";
import ShowSpace from "ui/ShowSpace";

interface Props extends DefaultTabProps {
  params: PaginationQueryConfig;
  onQueryParametersReceived: (config: PaginationQueryConfig) => void;
}

export const InsightActivitiesTable: FunctionComponent<Props> = ({
  onQueryParametersReceived,
  params,
  isActive,
  ...rest
}) => {
  const { t } = useTranslation();
  const { select, dispatch } = useStore();
  const currency: string = useSelector(select.currencyModel.current);
  const user: UserType = useSelector(select.userModel.success);
  const projects: ApiResponse<ProjectActivity[]> | undefined = useSelector(
    select.projectActivitiesModel.optionalResponse
  );
  const projectsLoading: boolean = useSelector(
    select.projectActivitiesModel.isLoading
  );
  const pagination = usePagination(projects?.meta);
  const handleTableUpdate = useCallback(
    (
      pagination: PaginationConfig,
      params: PaginationParams<ProjectActivity>,
      sorter: SorterResult<ProjectActivity>
    ) => {
      const queryParams = convertTableUpdateToQueryParams(pagination, sorter, {
        q: firstFilterParam(params.project_name),
        client: firstFilterParam(params.client_name),
        client_id: firstFilterParam(params.client_name),
      });
      onQueryParametersReceived(queryParams);
    },
    [onQueryParametersReceived]
  );

  const requestData = useMemo(
    () =>
      debounce((params: PaginationQueryConfig, user: UserType) => {
        const query = { ...params, ...getUserFilters(user) };
        dispatch.projectActivitiesModel.requestTimed(query);
      }, 500),
    [dispatch.projectActivitiesModel]
  );

  useEffect(() => {
    if (isActive) {
      requestData(params, user);
    }
  }, [dispatch.projectActivitiesModel, isActive, params, requestData, user]);

  return (
    <TabPane {...rest}>
      <SortableTable
        loading={projectsLoading}
        localization={{
          emptyListText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("insightsProject.noActivities")}
            />
          ),
        }}
        pagination={pagination}
        data={projects?.data || []}
        onChange={handleTableUpdate}
      >
        <Column
          title={t("insightsProject.columnProjectName")}
          key="project_name"
          dataIndex="project_name"
          width={400}
          render={(text: any, record: ProjectActivity) => (
            <AllocationLink
              color={record.color}
              text={text}
              link={formatRoute(PRIVATE_ROUTES.PROJECT_DETAILS_SCREEN.path, {
                id: record.project_id,
              })}
            />
          )}
          {...getColumnSearchProps(t("insightsProject.columnName"))}
        />
        <Column
          title={t("insightsProject.columnClient")}
          key="client_name"
          dataIndex="client_name"
          width={400}
          filterMultiple={false}
          render={(text: any, record: ProjectActivity) => (
            <Link
              to={formatRoute(PRIVATE_ROUTES.CLIENTS_OVERVIEW_SCREEN.path, {
                id: record.client_id,
              })}
            >
              <ShowSpace str={text} />
            </Link>
          )}
        />
        <Column
          title={t("insightsProject.columnProjectResponsible")}
          key="responsible_name"
          dataIndex="responsible_name"
          width={400}
          render={(text: any, record: ProjectActivity) => (
            <ShowSpace str={record.responsible_name} />
          )}
        />
        <Column
          title={t("insightsProject.columnImpact")}
          key="diff"
          dataIndex="diff"
          width={400}
          render={text => (
            <CurrencyFormat currencyIso={currency} value={text} />
          )}
        />
      </SortableTable>
    </TabPane>
  );
};
