interface State {
  navigatingPromptMessage: string | null;
}

export const appModel = {
  state: {
    navigatingPromptMessage: null,
  } as State,
  reducers: {
    updateAppState: (state: State, payload: State) => {
      return {
        ...state,
        ...payload,
      };
    },
    setNavigatingPromptMessage: (state: State, message: string | null) => {
      return {
        ...state,
        navigatingPromptMessage: message,
      };
    },
  },
};
