import { InsightSkill } from "../insightsType";

export type MappedInsightSkill = Omit<InsightSkill, "ratings">;

export interface InsightSkillWithRating {
  ratingLevels: number[];
  mapped: MappedInsightSkill[];
}

/**
 * Maps a {@link InsightSkill} into an object where each rating level is a
 * separate level key.
 * @param skills the set of skills from api to load.
 */
export const mapSkillsWithRatingLevels = (
  skills: InsightSkill[] | undefined
): InsightSkillWithRating => {
  const data = skills || [];
  // keep track of rating levels found, and set them on the table dynamically.
  const ratingLevels: { [key: number]: number } = {};
  const mapped = data.map<MappedInsightSkill>(d => {
    const newD: any = {
      ...d,
    };
    // remove ratings key to map
    delete newD.ratings;
    let ratingSummation = 0;
    d.ratings.forEach(r => {
      ratingSummation += r.count;
      newD[`level-${r.rating}`] = r.count;
      ratingLevels[r.rating] = r.rating;
    });
    newD[`count`] = ratingSummation;
    return newD;
  });
  return {
    mapped,
    ratingLevels: Object.values(ratingLevels).sort((a, b) => a - b),
  };
};
