import { IdentityProfile } from "../permissions/permissionConst";

export const ViewUserProfile = [IdentityProfile.READ];

export const ModifyUserProfile = [IdentityProfile.WRITE];

export const ChangePassword = [IdentityProfile.WRITE];

export const CreateSkillRequest = [IdentityProfile.READ];

export const UpdateSkillRequest = [IdentityProfile.WRITE];

export const DeleteSkillRequest = [IdentityProfile.DELETE];

export const ShowSkillRequests = [IdentityProfile.READ_REQUEST];

export const ApproveRejectSkillRequest = [IdentityProfile.WRITE_REQYEST];
