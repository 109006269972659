import { lazy } from "react";
import { IMenuItemType } from "../router/routerType";
import { RouterParameters } from "../router/routerConst";
import {
  ViewEmployeesScreen,
  ViewModifySpecificEmployee,
  ViewSpecificEmployeeDetails,
} from "./employee.permissions";
import { ShowSkillRequests } from "../identity/Profile/profile.permissions";

const EmployeesScreen = lazy(() => import("./EmployeesScreen/EmployeesScreen"));
const EmployeeOverviewScreen = lazy(() =>
  import("./EmployeeOverviewScreen/EmployeeOverviewScreen")
);
const EmployeeSkillsRequestScreen = lazy(() =>
  import("./SkillRequestScreen/SkillRequestScreen")
);

export const EMPLOYEES: IMenuItemType = {
  id: "employees",
  title: "employees",
  path: "/employees",
  icon: "team",
};

/** Screen: Settings Employees Page */
export const EMPLOYEES_SCREEN: IMenuItemType = {
  ...EMPLOYEES,
  id: "employees-screen",
  path: "/employees",
  component: EmployeesScreen,
  title: "overview",
  permissions: ViewEmployeesScreen,
};

export const EMPLOYEES_EDIT_SCREEN: IMenuItemType = {
  ...EMPLOYEES_SCREEN,
  path:
    EMPLOYEES_SCREEN.path + RouterParameters.EDIT + RouterParameters.ITEM_ID,
  permissions: ViewModifySpecificEmployee,
};

export const EMPLOYEES_CREATE_SCREEN: IMenuItemType = {
  ...EMPLOYEES_SCREEN,
  path: EMPLOYEES_SCREEN.path + RouterParameters.CREATE,
  permissions: ViewModifySpecificEmployee,
};

export const EMPLOYEES_OVERVIEW_SCREEN: IMenuItemType = {
  ...EMPLOYEES_SCREEN,
  id: "employees-overview",
  path: `${EMPLOYEES_SCREEN.path}/${RouterParameters.ID}`,
  component: EmployeeOverviewScreen,
  permissions: ViewSpecificEmployeeDetails,
};

export const EMPLOYEES_SKILL_REQUEST_SCREEN: IMenuItemType = {
  ...EMPLOYEES_SCREEN,
  id: "employees-skills",
  path: `${EMPLOYEES_SCREEN.path}/request/skills`,
  component: EmployeeSkillsRequestScreen,
  title: "requestSkills",
  permissions: ShowSkillRequests,
};

EMPLOYEES.subMenuItems = [EMPLOYEES_SCREEN, EMPLOYEES_SKILL_REQUEST_SCREEN];
