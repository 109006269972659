import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Button, Popover, TreeSelect } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./GlobalOfficeSelector.module.scss";
//import { OfficeType } from "../officeType";
import { useSelector } from "react-redux";
import { useStore } from "redux/hooks";
//import { LoadingModel } from "loading";
import qs from "query-string";
import { UtilHelper } from "app/utilHelper";
import { useHistory, useLocation } from "react-router-dom";
import { usePrevious } from "hooks/usePrevious";
import { UserType } from "users/userType";
import variables from "ui/_variables.scss";
import { useWindowDimensions } from "hooks/useWindowDimentions";
import { RootState } from "redux/store";
import ErrorFallback from "ui/ErrorFallback/ErrorFallbackReload";
import { createRegionsDataObject } from "geography/region/regionHelper";

const { ErrorBoundary } = require("react-error-boundary");
const { SCREEN_LG } = variables;
const NO_OFFICES = "0";

interface Props {}

const GlobalOfficeSelector: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { select, dispatch } = useStore();
  const history = useHistory();
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const [loadedOffices, setHasLoadedOffices] = useState(false);
  const [disableState, setDisable] = useState(false);
  const { width } = useWindowDimensions();

  const user: UserType = useSelector(select.userModel.success);
  const userOffices = user?.selected_offices || [];

  /** request all offices */
  // const allOfficesModel: LoadingModel<OfficeType[]> = useSelector(
  //   select.officesQueryModel.model
  // );

  const allOffices = useSelector(select.officesQueryModel.success) || [];

  const offices = useSelector(
    (state: RootState) => state.globalOfficeSelectorModel.selectedOffices
  );

  const handleOfficeSelect = useCallback(
    (officesValues: number[]) => {
      dispatch.globalOfficeSelectorModel.setOffices(officesValues);

      try {
        dispatch.userModel.updateProfile({
          selected_offices: officesValues,
        });
        dispatch.authModel.setOfficesChanged({ isOfficesChanged: true });
        setDisable(true);
      } finally {
        dispatch.authModel.setOfficesChanged({ isOfficesChanged: false });
      }
    },
    [
      dispatch.authModel,
      dispatch.globalOfficeSelectorModel,
      dispatch.userModel,
      setDisable,
    ]
  );

  useEffect(() => {
    if (loadedOffices) {
      setHasLoadedOffices(true);
      dispatch.officesQueryModel.request();
    }
  }, [dispatch.officesQueryModel, loadedOffices, setHasLoadedOffices]);

  useEffect(() => {
    const officeList = userOffices?.map(office => office.id);
    dispatch.globalOfficeSelectorModel.setOffices(officeList);
  }, [
    dispatch.globalOfficeSelectorModel,
    dispatch.officesQueryModel,
    userOffices,
  ]);

  const handleOfficesChanges = useCallback(() => {
    if (userOffices) {
      const ids = userOffices.map((office: any) => office.id);

      const currentSearch: any = qs.parse(window.location.search, {
        arrayFormat: "comma",
      });

      if (currentSearch.page) {
        currentSearch.page = 1;
      }

      const searchParams = UtilHelper.removeUndefined({
        ...currentSearch,
        offices: ids.length ? ids : undefined,
      });
      history.replace({
        pathname: location.pathname,
        search: qs.stringify(searchParams, { arrayFormat: "comma" }),
      });
      setDisable(false);
    }
    // eslint-disable-next-line
  }, [history, location.pathname, userOffices]);

  useEffect(() => {
    handleOfficesChanges();
  }, [handleOfficesChanges]);

  useEffect(() => {
    if (!previousLocation) {
      const currentSearch = qs.parse(location.search);

      const ids =
        user.selected_offices && user.selected_offices.map(office => office.id);

      if (ids) {
        if (currentSearch.offices) {
          if (
            currentSearch.offices !== ids.toString() &&
            currentSearch.offices !== NO_OFFICES
          ) {
            dispatch.userModel.updateProfile({
              selected_offices: currentSearch.offices,
            });
            dispatch.authModel.setOfficesChanged({ isOfficesChanged: true });
          } else if (
            currentSearch.offices !== ids.toString() &&
            currentSearch.offices === NO_OFFICES &&
            ids.toString() !== ""
          ) {
            dispatch.userModel.updateProfile({
              selected_offices: [],
            });
            dispatch.authModel.setOfficesChanged({ isOfficesChanged: true });
          } else {
          }
        }
      }
    }
  }, [
    dispatch.authModel,
    dispatch.userModel,
    location.search,
    previousLocation,
    user.selected_offices,
  ]);

  const renderPopContent = useMemo(
    () => (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <TreeSelect
          id="globalofficeSearch"
          value={offices}
          treeNodeFilterProp="title"
          showSearch
          allowClear
          dropdownStyle={{ maxHeight: 250, overflow: "auto" }}
          className={styles.officeSelector + " officeSearch"}
          treeData={createRegionsDataObject(allOffices, user.locale)}
          treeCheckable={true}
          searchPlaceholder={t("header.headerOfficePlaceholder")}
          onChange={handleOfficeSelect}
          maxTagCount={15}
          disabled={disableState}
          data-glbol-office="globaOfficeSearch"
        />
      </ErrorBoundary>
    ),
    [handleOfficeSelect, offices, t, user.locale, allOffices, disableState]
  );

  const renderContent = useMemo(() => {
    if (width <= SCREEN_LG) {
      return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Popover
            overlayClassName={styles.overlay}
            trigger="click"
            placement="bottom"
            content={renderPopContent}
          >
            <Badge count={offices.length} showZero>
              <Button>{t("settingsOffices.title")}</Button>
            </Badge>
          </Popover>
        </ErrorBoundary>
      );
    } else {
      return renderPopContent;
    }
  }, [offices, renderPopContent, t, width]);

  // Add aria-* to some ant-design elements
  document
    .querySelector(".officeSearch")
    ?.setAttribute("aria-expanded", "false");

  document
    .querySelectorAll(".officeSearch ul > li")
    .forEach(x => x.setAttribute("role", "menuitem"));

  return renderContent;
};

export default GlobalOfficeSelector;
