import React, { PureComponent } from "react";
import { Result } from "antd";

/** 404 Not found screen */
class RedirectdScreen extends PureComponent {
  render() {
    return (
      <Result
        status="500"
        title="503"
        subTitle={
          <div style={{ textAlign: "left", padding: "50px" }}>
            <p>
              <b>Dear MPlanner users,</b>
            </p>
            <p>
              Please be advised that we are performing routine tests after
              deployment between 9/06 and 13/06. This is necessary so that we
              could properly test new sales items and their corresponding
              calculations.
            </p>
            <p>As a result, MPlanner will be unavailable during this time.</p>
            <p>
              For more information, or if you have any questions, please raise a
              Zendesk ticket or contact Pavel Šrámek at
              <a href="mailto:pavel.sramek@monstar-lab.com">
                pavel.sramek@monstar-lab.com
              </a>
              .
            </p>
          </div>
        }
      />
    );
  }
}

export default RedirectdScreen;
