import { lazy } from "react";
import { InsightsRevenueScreen } from "bi/revenue/InsightsRevenueScreen";
import { IMenuItemType } from "router/routerType";
import { InsightsProjectScreen } from "bi/project/InsightsProjectScreen";
import { InsightsSkillsScreen } from "./skills/InsightsSkillsScreen";
import { RouterParameters } from "../router/routerConst";
import {
  ViewAllocation,
  ViewBillableOverview,
  ViewProjectActivitiesSales,
  ViewRevSalesAvgHour,
  ViewSkillsOverview,
} from "./insights.permissions";

const HomeScreen = lazy(() => import("home/HomeScreen/HomeScreen"));
const InsightsAllocationScreen = lazy(() =>
  import("bi/allocation/InsightsAllocationScreen/InsightsAllocationScreen")
);
const InsightsBillableScreen = lazy(() => import("bi/InsightsBillableScreen"));

/** Screen: Insights Page
 *  TODO: missing InsightsScreen component
 */
export const INSIGHTS_SCREEN: IMenuItemType = {
  id: "insights",
  path: "/insights",
  component: HomeScreen,
  title: "insights",
  icon: "fund",
};

/**
 * Screen: Insights Project Page
 */
export const INSIGHTS_PROJECT_SCREEN: IMenuItemType = {
  id: "insights-project",
  path: "/insights/project/:tab",
  component: InsightsProjectScreen,
  title: "project",
  permissions: ViewProjectActivitiesSales,
};

export const INSIGHTS_PROJECT_ACTIVITY_TAB: IMenuItemType = {
  ...INSIGHTS_PROJECT_SCREEN,
  path: "/insights/project/activities",
};

export const INSIGHTS_PROJECT_SALES_TAB: IMenuItemType = {
  ...INSIGHTS_PROJECT_SCREEN,
  path: "/insights/project/sales",
};

/** Screen: Insights Billable Page
 *  TODO: missing Insights Billable component
 */
export const INSIGHTS_BILLABLE_SCREEN: IMenuItemType = {
  id: "insights-billable",
  path: "/insights/billable",
  component: InsightsBillableScreen,
  title: "billable",
  permissions: ViewBillableOverview,
};
/** Screen: Insights Allocation Page
 *  TODO: missing Insights Allocation component
 */
export const INSIGHTS_ALLOCATION_SCREEN: IMenuItemType = {
  id: "insights-allocation",
  path: "/insights/allocation",
  component: InsightsAllocationScreen,
  title: "allocation",
  permissions: ViewAllocation,
};

/** Screen: Insights Revenue Page
 */
export const INSIGHTS_REVENUE_SCREEN: IMenuItemType = {
  id: "insights-revenue",
  title: "revenue",
  path: `/insights/revenue/${RouterParameters.TAB}`,
  component: InsightsRevenueScreen,
  permissions: ViewRevSalesAvgHour,
};

/** Screen: Insights Skills Page
 *  TODO: missing Insights Skills component
 */
export const INSIGHTS_SKILLS_SCREEN: IMenuItemType = {
  id: "insights-skills",
  path: "/insights/skills",
  component: InsightsSkillsScreen,
  title: "skills",
  permissions: ViewSkillsOverview,
};

/** Insights subitems */
INSIGHTS_SCREEN.subMenuItems = [
  INSIGHTS_PROJECT_SCREEN,
  INSIGHTS_BILLABLE_SCREEN,
  INSIGHTS_ALLOCATION_SCREEN,
  INSIGHTS_REVENUE_SCREEN,
  INSIGHTS_SKILLS_SCREEN,
];
