import { ProjectInvoices } from "../../identity/permissions/permissionConst";

export const ViewProjectInvoices = [ProjectInvoices.READ];

export const CreateModifyProjectInvoices = [
  ProjectInvoices.READ,
  ProjectInvoices.WRITE,
];

export const DeleteProjectInvoices = [ProjectInvoices.DELETE];
