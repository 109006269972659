import {
  Identity,
  ProjectsClients,
  TimePlanTracking,
} from "../identity/permissions/permissionConst";

export const ViewMyTimeSheet = [TimePlanTracking.READ];

export const ViewAllocationSuggestion = [TimePlanTracking.READ];

export const CreateModifyMyTimeSheet = [TimePlanTracking.WRITE];

export const DeleteMyTimeSheet = [TimePlanTracking.DELETE];

export const ViewTrackingEmployeeOverview = [TimePlanTracking.USERS_READ];

export const ViewAdminTableColumns = [TimePlanTracking.READ_ADMIN];

export const ExportTimeSheets = [TimePlanTracking.READ];

export const ViewMyTimeSheets = [
  TimePlanTracking.READ,
  ProjectsClients.LIST_READ,
];

export const ViewTrackingSpecificEmployee = [
  Identity.READ,
  TimePlanTracking.READ,
];

export const ViewColleaguesTimeSheet = [
  ProjectsClients.LIST_READ,
  TimePlanTracking.READ,
];

export const CreateModifyInColleaguesTimeSheet = [TimePlanTracking.WRITE];

export const DeleteInColleaguesTimeSheet = [TimePlanTracking.DELETE];
