import { api } from "api/apiHelper";
import {
  Endpoints,
  DEFAULT_ALL_RESULTS_PER_PAGE,
  DEFAULT_RESULTS_PER_PAGE,
} from "api/apiConst";
import { TrackingType } from "time-plan/trackingType";
import { ProjectIncludes } from "projects/projectConst";
import { ApiResponse } from "../api/models";
import { ApiParamsType } from "api/apiType";
import { DEFAULT_API_PARAMS } from "api/apiConst";

/** Get all tracking */
export const getTracking = async (search: {
  from: string;
  to: string;
  user_id: number;
  currentUser?: boolean;
  isRegisterTime?: boolean;
  selectedDate?: string;
  include?: ProjectIncludes[];
}): Promise<ApiResponse<TrackingType[]>> => {
  let params;

  if (search?.isRegisterTime) {
    params = {
      from: search.from,
      to: search.to,
      user_id: search.user_id,
      include: search.include,
      register_time: search.selectedDate,
    };
  } else {
    params = {
      from: search.from,
      to: search.to,
      user_id: search.user_id,
      include: search.include,
    };
  }

  try {
    const resp: any = await getAlltracking(params);
    const data: any = resp.data;
    return {
      data,
      meta: {
        ...resp.meta,
        pagination: {
          count: DEFAULT_RESULTS_PER_PAGE,
          current_page: Math.ceil(data.length / DEFAULT_RESULTS_PER_PAGE),
          per_page: DEFAULT_RESULTS_PER_PAGE,
          total: data.length,
          total_pages: Math.ceil(data.length / DEFAULT_RESULTS_PER_PAGE),
        },
      },
    };
  } catch (err) {
    throw err;
  }
};

export const getTrackingRecursive = <T extends any>(
  endpoint: string,
  params: ApiParamsType = DEFAULT_API_PARAMS,
  accumulator: T[] = []
): Promise<any> => {
  params = { ...DEFAULT_API_PARAMS, ...params };
  return api
    .get(endpoint, {
      params,
    })
    .then((response: any) => response.data)
    .then(({ data, meta }) => {
      accumulator = [...accumulator, ...data];
      const { current_page, total_pages } = meta.pagination;
      if (current_page === total_pages) {
        return { data: accumulator, meta };
      } else {
        return getTrackingRecursive(
          endpoint,
          {
            ...params,
            page: current_page + 1,
          },
          accumulator
        );
      }
    })
    .catch(err => {
      throw err;
    });
};

async function getAlltracking(params?: any): Promise<TrackingType[]> {
  return await getTrackingRecursive(Endpoints.TRACKINGS, {
    ...params,
    limit: DEFAULT_ALL_RESULTS_PER_PAGE,
  });
}

/** Create tracking */
export const createTracking = (tracking: TrackingType) => {
  const url = Endpoints.TRACKINGS;

  delete tracking.id;
  delete tracking.project;
  delete tracking.role;
  delete tracking.time;
  delete tracking.isEdit;

  return api
    .post(url, tracking)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** Edit tracking */
export const editTracking = async (
  trackingId: number,
  tracking: TrackingType
): Promise<TrackingType> => {
  const url = `${Endpoints.TRACKINGS}/${trackingId}`;

  try {
    const resp = await api.patch(url, tracking);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Delete tracking */
export const deleteTracking = (trackingId: number) => {
  const url = `${Endpoints.TRACKINGS}/${trackingId}`;

  return api.delete(url);
};
