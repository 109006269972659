import { lazy } from "react";
import { IMenuItemType } from "../../router/routerType";
import { RouterParameters } from "../../router/routerConst";
import {
  CreateModifyUserRole,
  ViewUserRoles,
} from "./permissionscreen.permissions";

const PermissionsScreen = lazy(() => import("./PermissionsScreen"));
const PermissionCreateScreen = lazy(() =>
  import("./PermissionCreateScreen/PermissionCreateScreen")
);
const PermissionsEditScreen = lazy(() =>
  import("./PermissionEditScreen/PermissionEditScreen")
);

/** Screen: Settings Permission Changes */
export const SETTINGS_PERMISSION_SCREEN: IMenuItemType = {
  id: "settings-permission",
  title: "permission",
  path: "/settings/permission",
  component: PermissionsScreen,
  permissions: ViewUserRoles,
};

export const SETTINGS_PERMISSION_CREATE_SCREEN: IMenuItemType = {
  ...SETTINGS_PERMISSION_SCREEN,
  path: SETTINGS_PERMISSION_SCREEN.path + RouterParameters.CREATE,
  component: PermissionCreateScreen,
  permissions: CreateModifyUserRole,
};

export const SETTINGS_PERMISSION_EDIT_SCREEN: IMenuItemType = {
  ...SETTINGS_PERMISSION_SCREEN,
  path: `${SETTINGS_PERMISSION_SCREEN.path}${RouterParameters.EDIT}/${RouterParameters.ID}`,
  component: PermissionsEditScreen,
  permissions: CreateModifyUserRole,
};
