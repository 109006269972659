import {
  capturedLoadingRematchModel,
  CapturedState,
  removeCapturedApiListItem,
  updateCapturedApiLoadingModel,
} from "loading";
import * as countryApi from "./countryApi";
import { DEFAULT_API_PARAMS } from "api/apiConst";
import { PaginationQueryConfig, PaginationQueryParams } from "api/models";
import { CountryType } from "./countryType";
import { SettingsParams } from "../../settings/settingsConst";
import * as currencyApi from "../../currencies/currencyApi";
import * as regionApi from "../region/regionApi";

export interface CountryFormParams {
  countryId?: number;
  values: CountryType;
}

export const countryFormSubmitModel = capturedLoadingRematchModel(
  "countryFormSubmitModel",
  async ({ countryId, values }: CountryFormParams) => {
    return countryId
      ? countryApi.editCountry(Number(countryId), values)
      : countryApi.createCountry(values);
  }
);

export const countryFormModel = capturedLoadingRematchModel(
  "countryFormModel",
  async ({ countryId }: Required<Pick<CountryFormParams, "countryId">>) =>
    countryApi.getAdminCountry(countryId, SettingsParams)
);

export const countriesModel = capturedLoadingRematchModel(
  "countriesModel",
  async (params: PaginationQueryParams) =>
    countryApi.getCountries({
      ...DEFAULT_API_PARAMS,
      ...params,
    }),
  {
    reducers: {
      removeCountry: (state, countryId: number) => {
        return {
          ...state,
          model: removeCapturedApiListItem(state.model, "id", countryId),
        };
      },
      "countryFormSubmitModel/setmodelSuccess": (
        state,
        payload: CapturedState<CountryFormParams, CountryType>
      ) => ({
        ...state,
        model: updateCapturedApiLoadingModel(
          state.model,
          "id",
          payload,
          "countryId",
          "name"
        ),
      }),
    },
    effects: (dispatch: any) => ({
      deleteCountry: async ({
        countryId,
      }: Pick<CountryFormParams, "countryId">) => {
        if (countryId) {
          const id = Number(countryId);
          await countryApi.deleteCountry(id);
          dispatch.countriesModel.removeCountry(id);
        }
      },
    }),
  }
);

export const countryCurrenciesModel = capturedLoadingRematchModel(
  "countryCurrenciesModel",
  async (params: PaginationQueryConfig) => currencyApi.getCurrencies(params)
);

export const countryRegionsModel = capturedLoadingRematchModel(
  "countryRegionsModel",
  async (params: PaginationQueryConfig) => regionApi.getRegions(params)
);
