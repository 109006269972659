import { UserScopes } from "../../users/userConst";
import omit from "lodash/omit";
import { GroupOrScopeItem, PermissionGrouping } from "./permissionTypes";
import { uniq } from "lodash";

type GroupKeys = { [key in keyof Omit<PermissionGrouping, "type">]: any };

const groupOf = <T extends any>(
  group: GroupKeys & T
): T & Pick<PermissionGrouping, "type"> =>
  // @ts-ignore
  ({
    ...group,
    type: "grouping",
  });

export const groupOrScopeToScopes = (
  items: GroupOrScopeItem[]
): UserScopes[] => {
  const mappedScopes: UserScopes[] = [];
  items.forEach(a => {
    if (!a) {
      return;
    }
    if ((a as any)["type"] === "grouping") {
      const userScopes: UserScopes[] = Object.values(
        omit(a as PermissionGrouping, "type")
      ).filter(u => !!u) as UserScopes[];
      mappedScopes.push(...userScopes);
    } else if (Array.isArray(a)) {
      mappedScopes.push(...a);
    } else {
      mappedScopes.push(a as UserScopes);
    }
  });
  return uniq(mappedScopes);
};

/**
 * Permissions of Projects those are similiar with backend's scopes
 */
export const Projects = groupOf({
  WRITE: UserScopes.PROJECTS_WRITE,
  READ: UserScopes.PROJECTS_READ,
  DELETE: UserScopes.PROJECTS_DELETE,
});

/**
 * Permissions of Projects allocations those are similiar with backend's scopes
 */
export const ProjectsAllocations = groupOf({
  READ: UserScopes.PROJECTS_ALLOCATIONS_READ,
  WRITE: UserScopes.PROJECTS_ALLOCATIONS_WRITE,
  DELETE: UserScopes.PROJECTS_ALLOCATIONS_DELETE,
});

/**
 * Permissions of Projects allocations requetthose are similiar with backend's scopes
 */
export const ProjectsAllocationsRequest = groupOf({
  WRITE: UserScopes.PROJECTS_ALLOCATIONS_REQUEST_WRITE,
});

/**
 * Permissions of Projects memebers those are similiar with backend's scopes
 */
export const ProjectsMembers = groupOf({
  WRITE: UserScopes.PROJECTS_MEMBERS_WRITE,
  READ: UserScopes.PROJECTS_MEMBERS_READ,
  DELETE: UserScopes.PROJECTS_MEMBERS_DELETE,
});

/**
 * Permissions of Projects rates those are similiar with backend's scopes
 */
export const ProjectsRates = groupOf({
  WRITE: UserScopes.PROJECTS_RATES_WRITE,
  READ: UserScopes.PROJECTS_RATES_READ,
});

/**
 * Permissions of Projects sales items those are similiar with backend's scopes
 */
export const ProjectsItems = groupOf({
  WRITE: UserScopes.PROJECTS_ITEMS_WRITE,
  READ: UserScopes.PROJECTS_ITEMS_READ,
  DELETE: UserScopes.PROJECTS_ITEMS_DELETE,
});

/**
 * Permissions of Projects clients those are similiar with backend's scopes
 */
export const ProjectsClients = groupOf({
  WRITE: UserScopes.PROJECTS_CLIENTS_WRITE,
  READ: UserScopes.PROJECTS_CLIENTS_READ,
  LIST_READ: UserScopes.PROJECTS_CLIENTS_LIST_READ,
  DELETE: UserScopes.PROJECTS_CLIENTS_DELETE,
});

/**
 * Permissions of users monthly salary those are similiar with backend's scopes
 */
export const UsersMonthySalary = groupOf({
  READ: UserScopes.IDENTITY_USERS_MONTHLY_SALARY_READ,
  WRITE: UserScopes.IDENTITY_USERS_MONTHLY_SALARY_WRITE,
});

/**
 * Permissions of profile those are similiar with backend's scopes
 */
export const IdentityRoles = groupOf({
  READ: UserScopes.IDENTITY_ROLES_READ,
  LIST_READ: UserScopes.IDENTITY_ROLES_LIST_READ,
  WRITE: UserScopes.IDENTITY_ROLES_WRITE,
});

export const IdentityProfile = groupOf({
  READ: UserScopes.IDENTITY_PROFILE_READ,
  WRITE: UserScopes.IDENTITY_PROFILE_WRITE,
  DELETE: UserScopes.IDENTITY_PROFILE_DELETE,
  READ_REQUEST: UserScopes.IDENTITY_PROFILE_SKILL_REQUESTS_READ,
  WRITE_REQYEST: UserScopes.IDENTITY_PROFILE_SKILL_REQUESTS_WRITE,
});

/**
 * Permissions of identity are similiar with backend's scopes
 */
export const Identity = groupOf({
  READ: UserScopes.IDENTITY_READ,
  WRITE: UserScopes.IDENTITY_WRITE,
  DELETE: UserScopes.IDENTITY_DELETE,
});

/**
 * Permissions of organization those are similiar with backend's scopes
 */
export const Organization = groupOf({
  READ: UserScopes.ORGANIZATION_READ,
  WRITE: UserScopes.ORGANIZATION_WRITE,
  DELETE: UserScopes.ORGANIZATION_DELETE,
});

/**
 * Permissions of organizations offices those are similiar with backend's scopes
 */
export const OrganizationOffices = groupOf({
  READ: UserScopes.ORGANIZATION_OFFICES_READ,
  LIST_READ: UserScopes.ORGANIZATION_OFFICES_LIST_READ,
  WRITE: UserScopes.ORGANIZATION_OFFICES_WRITE,
  DELETE: UserScopes.ORGANIZATION_OFFICES_DELETE,
});

/**
 * Permissions of time plan those are similiar with backend's scopes
 */
export const TimePlan = groupOf({
  READ: UserScopes.TIME_PLAN_READ,
  WRITE: UserScopes.TIME_PLAN_WRITE,
  DELETE: UserScopes.TIME_PLAN_DELETE,
});

/**
 * Permissions of time plan allocations those are similiar with backend's scopes
 */
export const TimePlanAllocations = groupOf({
  READ: UserScopes.TIME_PLAN_ALLOCATIONS_READ,
  WRITE: UserScopes.TIME_PLAN_ALLOCATIONS_WRITE,
  DELETE: UserScopes.TIME_PLAN_ALLOCATIONS_DELETE,
});

/**
 * Permissions of time plan tracking those are similiar with backend's scopes
 */
export const TimePlanTracking = groupOf({
  READ: UserScopes.TIME_PLAN_TRACKING_READ,
  USERS_READ: UserScopes.TIME_PLAN_TRACKING_USERS_READ,
  WRITE: UserScopes.TIME_PLAN_TRACKING_WRITE,
  DELETE: UserScopes.TIME_PLAN_TRACKING_DELETE,
  READ_ADMIN: UserScopes.TIME_PLAN_TRACKING_ADMIN_READ,
});

/**
 * Permissions of notifications those are similiar with backend's scopes
 */
export const Notifications = groupOf({
  READ: UserScopes.NOTIFICATIONS_READ,
  DELETE: UserScopes.NOTIFICATIONS_DELETE,
});

/**
 * Permissions of business intelligence those are similiar with backend's scopes
 */
export const BusinessIntelligence = groupOf({
  READ: UserScopes.BI_READ,
  WRITE: UserScopes.BI_WRITE,
  DELETE: UserScopes.BI_DELETE,
});

export const ProjectInvoices = groupOf({
  READ: UserScopes.PROJECTS_INVOICES_READ,
  WRITE: UserScopes.PROJECTS_INVOICES_WRITE,
  DELETE: UserScopes.PROJECTS_INVOICES_DELETE,
});

export const Cases = groupOf({
  READ: UserScopes.CASES_READ,
  WRITE: UserScopes.CASES_WRITE,
  DELETE: UserScopes.CASES_DELETE,
});

export const Allocation = groupOf({
  READ: UserScopes.ALLOCATION_READ,
  WRITE: UserScopes.ALLOCATION_WRITE,
});

export const AuditLogs = groupOf({
  READ: UserScopes.IDENTITY_AUDITS_READ,
  ORGANIZATION_READ: UserScopes.ORGANIZATION_AUDITS_READ,
  PROJECTS_READ: UserScopes.PROJECTS_AUDITS_READ,
  TIME_PLAN_READ: UserScopes.TIME_PLAN_AUDITS_READ,
});

export const GuardLockedMonth = groupOf({
  WRITE: UserScopes.APPLICATION_GUARD_LOCKED_MONTH,
});
