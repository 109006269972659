import { Button, Icon } from "antd";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { useStore } from "redux/hooks";
import { PRIVATE_ROUTES } from "router/Router.config";
import Dropdown from "ui/Dropdown";
import { UserType } from "users/userType";
import { logout } from "../authHelper";
import { useCheckUserPermissions } from "../permissions/permission_hooks";
import { ViewUserProfile } from "../Profile/profile.permissions";
import styles from "./UserButton.module.scss";

/**
 * Get truncate when user name reach over 15 character
 * @param input String
 */
const truncate = (input: string) => {
  return input.length > 15 ? `${input.substring(0, 15)}...` : input;
};

interface Props {}

const UserButton: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { select } = useStore();
  const history = useHistory();
  const user: UserType = useSelector(select.userModel.success);

  const hasViewProfilePermission = useCheckUserPermissions(
    ViewUserProfile,
    true
  );

  const userMenu = useMemo(() => {
    const menu = [];

    if (hasViewProfilePermission)
      menu.push({
        label: t("authentication.profileButton"),
        onItemClick: () => {
          history.push({
            pathname: formatRoute(PRIVATE_ROUTES.USERS_PROFILE_SCREEN.path),
          });
        },
      });

    menu.push({
      label: t("authentication.aboutButton"),
      onItemClick: () => {
        history.push({
          pathname: formatRoute(PRIVATE_ROUTES.ABOUT_SCREEN.path),
        });
      },
    });

    menu.push({
      label: t("authentication.theme"),
      onItemClick: () => {
        history.push({
          pathname: formatRoute(PRIVATE_ROUTES.THEME_SCREEN.path),
        });
      },
    });

    menu.push({
      label: t("authentication.trainingLinks"),
      onItemClick: () => {
        history.push({
          pathname: formatRoute(PRIVATE_ROUTES.TRAINING_SCREEN.path),
        });
      },
    });

    menu.push({
      label: t("authentication.logoutButton"),
      onItemClick: () => logout(),
    });
    return menu;
  }, [hasViewProfilePermission, history, t]);

  return (
    <Dropdown options={userMenu}>
      <Button className={styles.userButton} title={user.name}>
        <Icon role="img" type="user" />
        <span className={styles.name}>{!!user && truncate(user.name)}</span>
      </Button>
    </Dropdown>
  );
};

export default UserButton;
