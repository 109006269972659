import React, { useEffect, useCallback } from "react";
import { Icon, Layout } from "antd";
import styles from "./Header.module.scss";
import GlobalSearch from "ui/GlobalSearch/GlobalSearch";
import LanguageSwitcher from "localization/LanguageSwitcher";
import cx from "classnames";
import UserButton from "identity/UserButton/UserButton";
import GlobalCurrencySelector from "currencies/GlobalCurrencySelector";
import GlobalOfficeSelector from "geography/office/GlobalOfficeSelector/GlobalOfficeSelector";
import ErrorFallback from "ui/ErrorFallback/ErrorFallbackReload";
import { versionUpdate } from "identity/authHelper";
import { useStore } from "redux/hooks";
const { ErrorBoundary } = require("react-error-boundary");
const { Header: AntdHeader } = Layout;

interface Props {
  /** Specifies if header is collapsed (when sider is expanded, header is collapsed) */
  collapsed: boolean;
  handleCollapsed: () => void;
}

const Header = ({ collapsed, handleCollapsed }: Props) => {
  const { dispatch } = useStore();
  const oldVersion = localStorage.getItem("APP_VERSION");

  const getMetaInfo = useCallback(async () => {
    fetch(`${window.location.origin}/meta.json`)
      .then(response => response.json())
      .then(data => {
        const newVersion = data.version;
        if (oldVersion !== newVersion) {
          dispatch.authModel.setVersionUpdate({ isVersionUpdate: true });
          setTimeout(() => {
            versionUpdate(newVersion);
          }, 1000);
        }
      });
  }, [dispatch.authModel, oldVersion]);

  useEffect(() => {
    getMetaInfo();
  }, [getMetaInfo]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AntdHeader
        className={cx(styles.header, { [styles.header__shrink]: collapsed })}
      >
        <Icon
          role="img"
          className={styles.trigger}
          type={collapsed ? "menu-fold" : "menu-unfold"}
          onClick={handleCollapsed}
        />
        <div className={styles.controlsWrapper}>
          <GlobalCurrencySelector />
          <GlobalOfficeSelector />
          <GlobalSearch />
          <LanguageSwitcher />
          <UserButton />
        </div>
      </AntdHeader>
    </ErrorBoundary>
  );
};

export default Header;
