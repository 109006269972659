import { TimeSheetSuggestion } from "./my/models";
import { generateLocalId } from "../api/localDataHelper";
import { TimesheetDefaults } from "./timesheetConst";
import { AllocationType } from "../allocations/allocationType";
import { TrackingType } from "./trackingType";
import { hoursToHMS } from "../date/dateHelper";

const start_at = TimesheetDefaults.START_TIME;
/**
 * Creates a new time tracking row with an optional {@link TimeSheetSuggestion}.
 */
export const newTrackingWithSuggestion = (
  userId: number,
  suggestion?: TimeSheetSuggestion
): Omit<TrackingType, "spent_at"> => ({
  // Generate random id to make it unique locally
  id: generateLocalId(),
  user_id: Number(userId),
  start_at,
  end_at: hoursToHMS(start_at, suggestion?.hours || 0),
  isEdit: false,
  time: (suggestion && suggestion.hours * 60 * 60) || 0,
  project: suggestion?.suggestion.project,
  project_id: suggestion?.suggestion.project_id,
  project_rate_card_id: suggestion?.project_rate_card_id,
});

export const allocationToSuggestion = (
  startAt: string,
  a: AllocationType
): TimeSheetSuggestion => ({
  id: a.id,
  project_name: a.project?.name,
  project_id: a.project_id,
  client_name: a.project?.client?.name,
  client_id: a.project?.client?.id,
  project_rate_card: a.project_rate_card?.name,
  project_rate_card_id: a.project_rate_card?.project_rate_card_id,
  hours: a.days.find(a => a.date === startAt)?.hours || a.hours_per_day,
  suggestion: a,
});
