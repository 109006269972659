import { loadingRematchModel } from "loading";
import * as officeApi from "geography/office/officeApi";
import { OfficeTargetsType } from "../officeType";

export interface OfficeTargetParams {
  officeId: number;
  params: { year: string };
}

export const officeTargetPerFinancialYearModel = loadingRematchModel(
  async ({ officeId, params }: OfficeTargetParams) => {
    const response = await officeApi.getOfficeTargetPerFinancialYear(
      officeId,
      params
    );
    return response.data.map<OfficeTargetsType>((item, index) => ({
      ...item,
      item_key: index,
    }));
  },
  {
    effects: () => ({
      async update({ officeId, params }: OfficeTargetParams) {
        this.requestmodel();
        try {
          const response = await officeApi.saveOfficeTargetPerFinancialYear(
            officeId,
            params
          );
          this.setmodelSuccess(
            response.data.map<OfficeTargetsType>((item, index) => ({
              ...item,
              item_key: index,
            }))
          );
        } catch (e) {
          this.setmodelError(e);
        }
      },
    }),
  }
);

export const officeYearlyTargetList = loadingRematchModel(
  async (officeId: number) =>
    await officeApi.getOfficeYearlyTargetList(officeId)
);
