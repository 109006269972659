import { OrganizationOffices } from "../identity/permissions/permissionConst";

export const ViewRolesSkills = [OrganizationOffices.READ];

export const CreateModifyRolesSkills = [
  OrganizationOffices.WRITE,
  OrganizationOffices.READ,
];

export const CreateRolesSkills = [OrganizationOffices.WRITE];

export const DeleteRolesSkills = [OrganizationOffices.DELETE];
