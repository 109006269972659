import { RoleType } from "roles/roleType";

/**
 * Role includes.
 * Used for querying the service with arguments such as,
 * @readonly
 * @enum
 */
export enum RoleIncludes {
  SCOPES = "scopes",
  ROLES = "roles",
}

export const FALLBACK_NON_EXISTING_ROLE: RoleType = {
  id: 0,
  name: "NON EXISTING ROLE",
  role_name: "",
  name_localized: "NON EXISTING ROLE",
  description: "",
  description_localized: "",
  project_id: null,
};
