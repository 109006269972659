import React, { PureComponent } from "react";
import styles from "./YearPicker.module.scss";
import { DatePicker } from "antd";
import moment from "moment";
import { withTranslation, WithTranslation } from "react-i18next";
import { DateFormats } from "../../date/dateConst";
import cx from "classnames";
import { DatePickerProps } from "antd/lib/date-picker/interface";

interface IProps
  extends WithTranslation,
    Pick<DatePickerProps, "disabledDate"> {
  defaultYear?: moment.Moment;

  onYearChange(date: moment.Moment | undefined): void;

  className?: string;
}

interface IState {
  value: moment.Moment;
  isYearOpen: boolean;
  year: any;
}

// Ant design don't have year picker, it will available in v4
// right now this custom year picker solution from this blog post
// https://juejin.im/post/5cf65c366fb9a07eca6968f9

/**
 * A Year Picker
 */
class YearPicker extends PureComponent<IProps, IState> {
  state: IState = {
    value: this.props.defaultYear || moment(),
    isYearOpen: false,
    year: null,
  };

  componentDidMount() {
    const { defaultYear } = this.props;
    if (defaultYear) {
      this.setState({
        year: defaultYear,
      });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { defaultYear } = this.props;
    if (defaultYear && defaultYear !== prevProps.defaultYear) {
      this.setState({
        value: defaultYear,
      });
    }
  }

  onDateChange = (date: moment.Moment | null) => {
    if (date) {
      this.setState({
        value: date,
      });
      this.props.onYearChange(date);
    }
  };

  render() {
    const { isYearOpen, year, value } = this.state;
    const { disabledDate, className } = this.props;
    return (
      <>
        <span className={styles.selectedYear}>
          {value.format(DateFormats.API_YEAR)}
        </span>
        <DatePicker
          className={cx(styles.yearPicker, className)}
          value={year}
          open={isYearOpen}
          allowClear={false}
          mode="year"
          disabledDate={disabledDate}
          format={DateFormats.API_YEAR}
          onPanelChange={date => {
            this.onDateChange(date);
            this.setState({
              year: date,
              isYearOpen: false,
            });
          }}
          onChange={() => {
            this.setState({ year: null });
          }}
          onOpenChange={status => {
            if (status) {
              this.setState({ isYearOpen: true });
            } else {
              this.setState({ isYearOpen: false });
            }
          }}
        />
      </>
    );
  }
}

export default withTranslation()(YearPicker);
