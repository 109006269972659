/** current url save in local storage for back url */
import { LOCAL_STORAGE_REDIRECT_URL } from "app/utils/storageConst";
import { PUBLIC_ROUTES, ERROR_ROUTES } from "router/Router.config";
import { isAuthenticated, logout } from "identity/authHelper";
import store from "redux/store";
import { changeThemeHandler } from "ui/themeHandler";

export const checkPrivateUrlAndStoreUrl = () => {
  const path = window.location.pathname;

  if (
    path !== PUBLIC_ROUTES.SET_NEW_PASSWORD_SCREEN.path &&
    path !== PUBLIC_ROUTES.FORGOT_PASSWORD_SCREEN.path &&
    path !== PUBLIC_ROUTES.LOGIN_SCREEN.path &&
    path !== PUBLIC_ROUTES.JUMPCLOUD_AUTH.path &&
    path !== ERROR_ROUTES.REDIRECT_PATH
  ) {
    const theme = store.getState().authModel.theme;
    const search = window.location.search;
    const currentUrl = `${path}${search}`;
    changeThemeHandler(theme);
    localStorage.setItem(LOCAL_STORAGE_REDIRECT_URL, currentUrl);
  }

  if (
    path === ERROR_ROUTES.ERROR_PATH ||
    path === ERROR_ROUTES.NOT_FOUND_PATH ||
    path === ERROR_ROUTES.REDIRECT_PATH ||
    path === PUBLIC_ROUTES.LOGIN_SCREEN.path
  ) {
    localStorage.removeItem(LOCAL_STORAGE_REDIRECT_URL);
  }

  /** when user is logged in and hit reset password then logout */
  if (
    path === PUBLIC_ROUTES.SET_NEW_PASSWORD_SCREEN.path ||
    path === PUBLIC_ROUTES.FORGOT_PASSWORD_SCREEN.path ||
    path === ERROR_ROUTES.REDIRECT_PATH
  ) {
    if (isAuthenticated()) {
      logout();
    }
  }
};

export const checkPrivateUrl = () => {
  const path = window.location.pathname;
  if (
    path === PUBLIC_ROUTES.SET_NEW_PASSWORD_SCREEN.path ||
    path === PUBLIC_ROUTES.FORGOT_PASSWORD_SCREEN.path
  ) {
    return false;
  }
  return true;
};
