import React, {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Input, AutoComplete, Spin, Popover, Button, Icon } from "antd";
import _debounce from "lodash/debounce";
import _camelCase from "lodash/camelCase";
import { getGlobalSearch } from "users/userApi";
import { useHistory } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { PRIVATE_ROUTES } from "router/Router.config";
import { globalSearchItemType, globalSearchType } from "users/userType";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import styles from "./GlobalSearch.module.scss";
import variables from "ui/_variables.scss";
import { useWindowDimensions } from "hooks/useWindowDimentions";
import ErrorFallback from "ui/ErrorFallback/ErrorFallbackReload";

const { ErrorBoundary } = require("react-error-boundary");
const { SCREEN_SM } = variables;

const { Option, OptGroup } = AutoComplete;

export enum ItemsEnum {
  PROJECTS_ITEMS = "projects",
  ARCHIVED_PROJECTS_ITEMS = "archived_projects",
  CLIENTS_ITEMS = "clients",
  USERS_ITEMS = "users",
  OFF_BOARDED_USERS = "off_boarded_users",
}

const GlobalSearch: FunctionComponent = () => {
  const [searchResults, setSearchResults] = useState<globalSearchType[]>([]);
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { width } = useWindowDimensions();

  const handleSearch = useCallback(
    _debounce(async (value: string) => {
      if (value === "") {
        return;
      }
      try {
        setLoading(true);
        const response: any = await getGlobalSearch(value.trim());
        const searchResultsProcessing: any = [];

        const isEmpty = Object.keys(response.data).every(
          (key: string) => response.data[key].length === 0
        );

        if (isEmpty) {
          searchResultsProcessing.push({
            title: t(`header.noResultsFound`),
            children: [],
          });
        } else {
          Object.keys(response.data).forEach((key: string) => {
            if (response.data[key].length === 0) return;
            searchResultsProcessing.push({
              title: t(`header.${_camelCase(key)}`),
              children: response.data[key].map((item: globalSearchItemType) => {
                let link: string = "";
                if (
                  key === ItemsEnum.PROJECTS_ITEMS ||
                  key === ItemsEnum.ARCHIVED_PROJECTS_ITEMS
                ) {
                  link = formatRoute(
                    PRIVATE_ROUTES.PROJECT_DETAILS_SCREEN.path,
                    {
                      id: item.id,
                    }
                  );
                }
                if (key === ItemsEnum.CLIENTS_ITEMS) {
                  link = formatRoute(
                    PRIVATE_ROUTES.CLIENTS_OVERVIEW_SCREEN.path,
                    {
                      id: item.id,
                    }
                  );
                }
                if (
                  key === ItemsEnum.USERS_ITEMS ||
                  key === ItemsEnum.OFF_BOARDED_USERS
                ) {
                  link = formatRoute(
                    PRIVATE_ROUTES.TIMESHEET_DETAIL_SCREEN.path,
                    {
                      id: item.id,
                    }
                  );
                }
                return {
                  id: item.id,
                  name: item.name,
                  isArchived:
                    key === ItemsEnum.ARCHIVED_PROJECTS_ITEMS ||
                    key === ItemsEnum.OFF_BOARDED_USERS,
                  link,
                };
              }),
            });
          });
        }

        setSearchResults(searchResultsProcessing);
      } finally {
        setLoading(false);
      }
    }, 500),
    [formatRoute, setSearchResults]
  );

  const handleSelect = useCallback(
    (value: any) => {
      if (value === "") return;
      history.push(value);
    },
    [history]
  );

  const renderPopContent = useMemo(
    () => (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <label
          className={styles.searchLabel}
          htmlFor={`globalSearch`}
        >{`Search`}</label>
        <AutoComplete
          style={{ width: "100%" }}
          dataSource={searchResults.map((group: any) => (
            <OptGroup key={group.title}>
              {group.children.map((opt: globalSearchItemType) => (
                <Option
                  className={cx({ [styles.archivedText]: opt.isArchived })}
                  key={opt.link}
                  value={opt.link}
                >
                  {opt.name}
                </Option>
              ))}
            </OptGroup>
          ))}
          placeholder={t("header.search")}
          onSearch={handleSearch}
          onSelect={handleSelect}
          notFoundContent={loading ? <Spin size="small" /> : null}
        >
          <Input id="globalSearch" type="search" />
        </AutoComplete>
      </ErrorBoundary>
    ),
    [handleSearch, handleSelect, loading, searchResults, t]
  );

  const renderContent = useMemo(() => {
    if (width <= SCREEN_SM) {
      return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Popover
            trigger="click"
            placement="bottom"
            content={renderPopContent}
          >
            <Button shape="circle">
              <Icon role="img" type="search" />
            </Button>
          </Popover>
        </ErrorBoundary>
      );
    } else {
      return renderPopContent;
    }
  }, [renderPopContent, width]);

  return renderContent;
};

export default GlobalSearch;
