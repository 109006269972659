import moment, { Moment } from "moment";
import _range from "lodash/range";
import { RangeType, RangeTypeStr } from "allocations/rangeType";
import { DateFormats } from "date/dateConst";
import { CalendarType } from "calendar/calendarConst";
import variables from "ui/_calendar.scss";
import {
  RangeValuesType,
  MonthType,
  WeekType,
  DayType,
} from "calendar/calendarType";
import { TimeUnit } from "date/unitType";

const { API_DATE } = DateFormats;

/** convert moment range to str range */
export const rangeToStr = (range: RangeType): RangeTypeStr => {
  const start = range.start.format(API_DATE);
  const end = range.end.format(API_DATE);
  return { start, end };
};

/** get range values from range and mode */
export const getRangeValues = (
  range: RangeTypeStr,
  mode: CalendarType
): RangeValuesType => {
  return mode === CalendarType.WEEK
    ? getRangeWeeks(range)
    : getRangeMonths(range);
};

/** get month range */
const getRangeMonths = (range: RangeTypeStr): MonthType[] => {
  const { start, end } = range;
  const today = moment();

  const totalMonths = moment(end).diff(start, "months") + 1; // +1 to add current month

  const months = _range(totalMonths).map(monthIndex => {
    const monthPointer = moment(start)
      .clone()
      .add(monthIndex, "month");
    const monthStart = monthPointer.clone().startOf("month");
    const monthEnd = monthPointer.clone().endOf("month");

    const month: MonthType = {
      isCurrent: today.isSame(monthPointer, "month"),
      start: monthStart.format(API_DATE),
      end: monthEnd.format(API_DATE),
      mmm: monthPointer.format("MMM"),
      number: monthPointer.month() + 1,
      weeks: [],
    };

    /** If month is January and it's first day in the last 53rd week of pervious year then week index is 0 */
    const monthStartWeekIndex =
      monthStart.month() === 0 && monthStart.week() !== 1
        ? 0
        : monthStart.week();

    /** If month is December and it's last day is a first week of the next year then manually set week to 52*/
    const monthEndWeekIndex =
      monthEnd.month() === 11 && monthEnd.week() === 1 ? 52 : monthEnd.week();

    const totalWeeks = monthEndWeekIndex - monthStartWeekIndex + 1; // +1 to add current week

    month.weeks = _range(totalWeeks).map(weekIndex => {
      const weekPointer = monthStart.clone().add(weekIndex, "week");
      // if first week in month, then use first day in month
      // as first day in week, could be in the previous month
      const weekStartDate =
        weekIndex === 0
          ? weekPointer.clone().startOf("month")
          : weekPointer.clone().startOf("week");
      // if last week in month, then use last day in month
      // as last day in week, could be in the next month
      const weekEndDate =
        weekIndex + 1 === totalWeeks
          ? monthEnd.clone()
          : weekPointer.clone().endOf("week");

      const week: WeekType = {
        week: weekPointer.week(),
        days: [],
      };

      const totalDays = weekEndDate.diff(weekStartDate, "days") + 1;

      week.days = _range(totalDays).map(dayIndex => {
        const dayPointer = weekStartDate.clone().add(dayIndex, "day");
        const day: DayType = {
          isToday: dayPointer.isSame(today, "day"),
          dd: dayPointer.format("DD"),
          day: dayPointer.format(API_DATE),
          dayIndex: dayPointer.day(),
        };

        return day;
      });

      return week;
    });
    return month;
  });

  return months;
};

/** finds weeks and week days by given range */
export const getRangeWeeks = (range: RangeTypeStr): WeekType[] => {
  let weeks = [] as WeekType[];
  for (
    var week: Moment = moment(range.start).clone();
    week.isSameOrBefore(range.end);
    week.add(1, "week")
  ) {
    let months = new Set();
    months.add(
      week
        .clone()
        .startOf("week")
        .format("MMM")
    );
    months.add(
      week
        .clone()
        .endOf("week")
        .format("MMM")
    );

    const days = getWeekDays(week);
    weeks.push({
      days,
      week: week.week(),
      months: Array.from(months) as string[],
    });
  }
  return weeks;
};

/** Finds week details from moment */
export const getWeekDays = (start: Moment) => {
  const days: any[] = [];
  const today = moment();
  const day = start.clone();
  const { API_DATE } = DateFormats;

  for (let i = 0; i < 7; i++) {
    const isToday = today.isSame(day, "day");

    days.push({
      isToday,
      day: day.format(API_DATE),
      dd: day.format("DD"),
      dayIndex: day.day(),
    });

    day.add(1, "days");
  }

  return days;
};

/** Calculate how many extra weeks we can fit into the calendar container width */
const getNumberOfExtraWeeks = (containerWidth: number) => {
  const weeks = Math.ceil(
    (containerWidth - variables.CALENDAR_LEFT_PANEL_WIDTH) /
      variables.CALENDAR_WEEK_WIDTH
  );
  return weeks - 1; // subtract current week
};

/** Calculate how many extra months we can fit into the calendar container width */
const getNumberOfExtraMonths = (containerWidth: number) => {
  const months = Math.ceil(
    (containerWidth - variables.CALENDAR_LEFT_PANEL_WIDTH) /
      variables.CALENDAR_MONTH_WIDTH
  );
  return months - 1; // subtract current month
};

/** Create a default range by given calendar type */
export const defaultRange = (
  mode: CalendarType,
  containerWidth: number
): RangeTypeStr => {
  const today = moment().format(API_DATE);
  return getRange(today, mode, containerWidth);
};

/**
 * returns range object from pointer & mode
 */
export const getRange = (
  pointer: string,
  mode: CalendarType,
  containerWidth: number
): RangeTypeStr => {
  let extra = 3; // default extra weeks/months
  switch (mode) {
    case CalendarType.WEEK:
      extra = getNumberOfExtraWeeks(containerWidth);
      break;
    case CalendarType.MONTH:
      extra = getNumberOfExtraMonths(containerWidth);
      break;
  }

  return {
    start: moment(pointer, API_DATE)
      .startOf(mode)
      .format(API_DATE),
    end: moment(pointer, API_DATE)
      .add(extra, mode)
      .endOf(mode)
      .format(API_DATE),
  };
};

export const getHolidaysRange = (
  amount: number = 1,
  unit: TimeUnit = "years"
): RangeType => {
  const start = moment().subtract(amount, unit);
  const end = moment().add(amount, unit);
  return { start, end };
};

/**
 * Get weekly or monthly allocation calendar bar's dimension
 */
export const getColorBarDimension = (
  calRange: RangeTypeStr,
  allocRange: { start_at: string; end_at: string },
  mode?: CalendarType
) => {
  const calendarMode = mode === CalendarType.MONTH ? "months" : "days";
  const multiplier =
    mode === CalendarType.MONTH
      ? variables.CALENDAR_MONTH_WIDTH
      : variables.CALENDAR_DAY_WIDTH;

  /** allocation time diff */
  const start = moment(calRange.start).isAfter(
    allocRange.start_at,
    calendarMode
  )
    ? moment(calRange.start)
    : moment(allocRange.start_at);

  const end = moment(calRange.end).isBefore(allocRange.end_at, calendarMode)
    ? moment(calRange.end)
    : moment(allocRange.end_at);

  /** allocation bar width in the range diff */
  let barDiffDays = end.diff(start, calendarMode);

  // assigned end day inclusive
  if (end.isSame(calRange.end) || end.isBefore(calRange.end)) {
    barDiffDays = barDiffDays + 1;
  }

  /** cal starting position and bar width */
  const startDiffDays = start.diff(calRange.start, calendarMode);

  const left = startDiffDays * multiplier;
  const width = barDiffDays * multiplier;

  return { left, width };
};

export const isOutsideCalendarRange = (
  calRange: RangeTypeStr,
  allocRange: { start_at: string; end_at: string }
) => {
  return (
    // if allocation end date is before calendar range start date
    moment(allocRange.end_at).isBefore(calRange.start) ||
    // if allocation start date is after calendar range end date
    moment(allocRange.start_at).isAfter(calRange.end)
  );
};

/**
 * Get split date
 *
 * @param {RangeTypeStr} calendarRange Calendar range
 * @param {string} assignmentStart Assignment start date
 * @param {number} splitOffsetX Offset of splitting line where splitOffsetX % CALENDAR_DAY_WIDTH === 0
 * @returns {string} Split date
 */
export const getSplitDate = (
  calendarRange: RangeTypeStr,
  assignmentStart: string,
  splitOffsetX: number
): string => {
  const start = moment(calendarRange.start).isAfter(assignmentStart, "days")
    ? calendarRange.start
    : assignmentStart;

  const daysOffset = splitOffsetX / variables.CALENDAR_DAY_WIDTH;
  const splitDate = moment(start)
    .add(daysOffset - 1, "days")
    .format(API_DATE);
  return splitDate;
};
