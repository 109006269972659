import React from "react";

interface Iprops {
  str: string;
}

// <span
//   dangerouslySetInnerHTML={{
//     __html: str && str.replace(/ /g, "&nbsp"),
//   }}
// />

const SpaceHandler = ({ str }: Iprops) => {
  return <span>{str}</span>;
};

export default SpaceHandler;
