import { createModel } from "@rematch/core";

const INITIAL_STATE = {
  lockMonthIsEdit: false,
};

const commonModel = createModel({
  state: INITIAL_STATE,
  reducers: {
    /**
     * Set locked month selected year
     */
    setLockMonthEdit: (state: Object, payload: { isEdit: boolean }) => {
      return {
        ...state,
        lockMonthIsEdit: payload.isEdit,
      };
    },
    /**
     * Clear / reset state
     */
    clear() {
      return INITIAL_STATE;
    },
  },
});

export default commonModel;
