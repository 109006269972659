import {
  capturedLoadingRematchModel,
  CapturedState,
  LoadingModel,
  loadingRematchModel,
  updateCapturedApiLoadingModel,
} from "../loading";
import * as userApi from "../users/userApi";
import { UserIncludes } from "../users/userConst";
import { ApiResponse, PaginationQueryParams } from "../api/models";
import { EditUserType, ManagerType, UserType } from "../users/userType";
import find from "lodash/find";
import * as skillApi from "../skills/skillApi";

const USERS_INCLUDES = {
  include: [
    UserIncludes.OFFICE,
    UserIncludes.ROLES,
    UserIncludes.CURRENCY,
    UserIncludes.PRACTICE_ROLES,
  ],
};

export interface EmployeeFormParams {
  values: EditUserType;
  employeeId: number;
}

export const employeeFormModel = loadingRematchModel(
  async ({ employeeId }: { employeeId: number }) =>
    userApi.getUserById(employeeId, USERS_INCLUDES)
);

export const employeeOverviewModel = loadingRematchModel(
  async (employeeId: number) => userApi.getUserById(employeeId, USERS_INCLUDES)
);

export const employeeManagerUsers = loadingRematchModel(
  async (params?: PaginationQueryParams) =>
    userApi.getUsers(params) as Promise<ApiResponse<ManagerType[]>>,
  {
    reducers: {
      "employeeFormModel/setmodelSuccess": (state, payload: UserType) => {
        const users = [...(state.model.optionalSuccess?.data || [])];
        if (payload?.manager) {
          const isClient = find(users, {
            id: Number(payload.manager.id),
          });
          if (!isClient) {
            users.unshift(payload.manager);
          }
        }
        return {
          ...state,
          model: LoadingModel.success({
            meta: state.model.optionalSuccess?.meta,
            data: users,
          }),
        };
      },
    },
  }
);

export const employeeFormSubmitModel = capturedLoadingRematchModel(
  "employeeFormSubmitModel",
  async ({ employeeId, values }: EmployeeFormParams) => {
    return employeeId
      ? userApi.editUser(Number(employeeId), values, USERS_INCLUDES)
      : userApi.createUser(values, USERS_INCLUDES);
  }
);

export const employeeUsers = capturedLoadingRematchModel(
  "employeeUsers",
  async (params?: PaginationQueryParams) =>
    userApi.getUsers(params, USERS_INCLUDES),
  {
    reducers: {
      "employeeFormSubmitModel/setmodelSuccess": (
        state,
        payload: CapturedState<EmployeeFormParams, UserType>
      ) => ({
        ...state,
        model: updateCapturedApiLoadingModel(
          state.model,
          "id",
          payload,
          "employeeId"
        ),
      }),
    },
  }
);

export const employeeSkills = loadingRematchModel(
  async (params?: PaginationQueryParams) => skillApi.getSkills(params)
);
