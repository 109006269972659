import {
  Identity,
  OrganizationOffices,
  Projects,
  ProjectsAllocations,
  ProjectsClients,
  ProjectsItems,
  ProjectsMembers,
  ProjectsRates,
  TimePlanTracking,
  GuardLockedMonth,
} from "../identity/permissions/permissionConst";

export const ViewProjects = [
  ProjectsClients.LIST_READ,
  Identity.READ,
  Projects.READ,
];

export const CreateModifyProject = [
  Identity.READ,
  OrganizationOffices.LIST_READ,
  ProjectsClients.LIST_READ,
  Projects.WRITE,
];

export const ViewSpecificProject = [Projects.READ];

export const ViewProjectAllocation = [ProjectsAllocations.READ];
export const EditProjectAllocation = [ProjectsAllocations.WRITE];
export const DeleteProjectAllocation = [ProjectsAllocations.DELETE];

export const ViewModifyProjectRole = [OrganizationOffices.READ, Projects.WRITE];

export const ViewTeamMember = [ProjectsMembers.READ];
export const CreateModifyTeamMember = [
  ProjectsMembers.READ,
  ProjectsMembers.WRITE,
];

export const DeleteTeamMember = [ProjectsMembers.DELETE];

export const ExportTeamMemberOverview = [TimePlanTracking.READ];

export const ExportTeamMemberTimeSheet = [TimePlanTracking.READ];

export const CreateSalesItem = [Identity.READ, ProjectsItems.WRITE];

export const ViewSpecificSalesItem = [Identity.READ, ProjectsItems.READ];

export const CreateModifySpecificSalesItem = [ProjectsItems.WRITE];

export const DeleteSpecificSalesItem = [ProjectsItems.DELETE];

export const ViewProjectRates = [ProjectsRates.READ];

export const ModifyProjectRates = [ProjectsRates.WRITE];

export const GuardSalesItems = [GuardLockedMonth.WRITE];
