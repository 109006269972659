import {
  capturedLoadingRematchModel,
  CapturedState,
  loadingRematchModel,
  LoadingRematchModelState,
  wrapLoading,
} from "../../loading";
import { DEFAULT_API_PARAMS } from "api/apiConst";
import * as allocationsApi from "allocations/allocationApi";
import { PaginationQueryParams } from "api/models";
import {
  AllocationIncludeType,
  AllocationType,
} from "../../allocations/allocationType";
import * as trackingApi from "../trackingApi";
import * as userApi from "users/userApi";
import * as clientsApi from "clients/clientsApi";
import { ProjectIncludes } from "../../projects/projectConst";
import { createModel } from "@rematch/core";
import { TrackingType, WeekDayType } from "../trackingType";
import range from "lodash/range";
import { getAllDaysBetweenWeek } from "../../date/dateHelper";
import moment from "moment";
import { DateFormats } from "../../date/dateConst";
import { Slicer } from "@rematch/select";
import { ApiResponse } from "../../api/models";
import { hasValidId } from "../../api/localDataHelper";
import { defaultMemoize } from "reselect";
import { allocationToSuggestion } from "../tracking.conversions";
import cloneDeep from "lodash/cloneDeep";

export interface TimeSheetSuggestionsParams {
  userId: number;
  startAt: string;
  endAt: string;
}

export const timeSheetSuggestionsModel = capturedLoadingRematchModel(
  "timeSheetSuggestionsModel",
  async ({ userId, startAt, endAt }: TimeSheetSuggestionsParams) =>
    allocationsApi.getUserAllocationsWithProjects({
      start_at: startAt,
      end_at: endAt,
      user_id: userId,
      include: [
        AllocationIncludeType.Project,
        AllocationIncludeType.ProjectRateCard,
      ],
    }),
  {
    selectors: (slice: any, createSelector) => {
      const selector = createSelector(
        slice,
        (
          state: LoadingRematchModelState<
            CapturedState<string, ApiResponse<AllocationType[]>>
          >
        ) =>
          wrapLoading<CapturedState<string, ApiResponse<AllocationType[]>>>(
            state.model
          )
      );
      return {
        suggestions() {
          return createSelector(selector, state =>
            defaultMemoize((startAt: string, entries: TrackingType[]) => {
              const suggestionsData =
                state?.optionalSuccess?.response?.data || [];
              const pagination =
                state?.optionalSuccess?.response?.meta?.pagination || {};
              const data = suggestionsData
                .filter(v => v.days.find(d => d.date === startAt))
                // filter out suggestions that exist in the entries.
                .filter(
                  v =>
                    !entries.find(e => {
                      if (
                        e &&
                        e?.project_id &&
                        e?.project_rate_card?.name &&
                        e?.project &&
                        e?.project?.client.id
                      ) {
                        return (
                          v.project_id === e?.project_id &&
                          v.project_rate_card?.name ===
                            e?.project_rate_card.name &&
                          v.project?.client.id === e?.project?.client.id
                        );
                      }
                      return false;
                    })
                )
                // map to our data format.
                .map(a => allocationToSuggestion(startAt, a));
              return { data, pagination };
            })
          );
        },
      };
    },
  }
);

export interface MyTimeSheetParams {
  from: string;
  to: string;
  user_id: number;
  selectedDate: string;
  isRegisterTime: boolean;
  currentUser: boolean | undefined;
}

export type TimeSheetData = CapturedState<
  MyTimeSheetParams,
  ApiResponse<TrackingType[]>
>;
export const myTimeSheetModel = capturedLoadingRematchModel(
  "myTimeSheetModel",
  async ({
    from,
    to,
    user_id,
    currentUser,
    selectedDate,
    isRegisterTime,
  }: MyTimeSheetParams) =>
    trackingApi.getTracking({
      from,
      to,
      user_id,
      currentUser,
      selectedDate,
      isRegisterTime,
      include: [ProjectIncludes.CLIENT],
    })
);

export const myTimeSheetEmployee = loadingRematchModel(async (userId: number) =>
  userApi.getUserById(userId)
);

export const myTimeSheetClientRoles = loadingRematchModel(
  async (payload: { timeSheetUserId: number; month: string }) =>
    clientsApi.getClientProjectRoles(payload)
);

interface TimeSheetWeekDaysState {
  weekdays: WeekDayType[];
}

const initialWeekdayState: TimeSheetWeekDaysState = {
  weekdays: range(7).map(() => ({
    spent_at: "",
    entries: [],
  })),
};

export const myTimeSheetWeekDays = createModel({
  state: initialWeekdayState,
  selectors: (slice: Slicer<any>, createSelector) => {
    const weekdays = createSelector(
      slice,
      (state: TimeSheetWeekDaysState) => state.weekdays
    );
    return {
      weekdays() {
        return weekdays;
      },
      entries() {
        return createSelector(weekdays, weekdays =>
          defaultMemoize((weekDayIndex: number) => {
            return weekdays?.[weekDayIndex].entries;
          })
        );
      },
    };
  },
  reducers: {
    setWeekDays: (state: TimeSheetWeekDaysState, payload: WeekDayType[]) => {
      return {
        ...state,
        weekdays: payload,
      };
    },
    reset: () => {
      return {
        ...initialWeekdayState,
      };
    },
    add: (
      state: TimeSheetWeekDaysState,
      {
        weekDayIndex,
        tracking,
      }: { weekDayIndex: number; tracking: Omit<TrackingType, "spent_at"> }
    ) => {
      const newWeekDays = cloneDeep(state.weekdays);
      newWeekDays[weekDayIndex].entries.push({
        ...tracking,
        spent_at: newWeekDays[weekDayIndex]?.spent_at,
      });
      return {
        ...state,
        weekdays: newWeekDays,
      };
    },
    remove: (
      state: TimeSheetWeekDaysState,
      {
        weekDayIndex,
        trackingIndex,
      }: { weekDayIndex: number; trackingIndex: number }
    ) => {
      // copy only if exists.
      if (!!state.weekdays[weekDayIndex]?.entries[trackingIndex]) {
        const newWeekDays = cloneDeep(state.weekdays);
        newWeekDays[weekDayIndex].entries.splice(trackingIndex, 1);
        return {
          ...state,
          weekdays: newWeekDays,
        };
      }
      return state;
    },
    update: (
      state: TimeSheetWeekDaysState,
      {
        tracking,
        trackingIndex,
        weekDayIndex,
      }: {
        tracking: TrackingType;
        trackingIndex: number;
        weekDayIndex: number;
      }
    ) => {
      const newWeekDays = cloneDeep(state.weekdays);
      const trackingTypes = newWeekDays[weekDayIndex].entries;
      trackingTypes[trackingIndex] = tracking;
      trackingTypes[trackingIndex].isEdit = true;
      return {
        ...state,
        weekdays: newWeekDays,
      };
    },
    setEditing: (
      state: TimeSheetWeekDaysState,
      {
        weekDayIndex,
        trackingIndex,
        isEditing,
      }: { weekDayIndex: number; trackingIndex: number; isEditing: boolean }
    ) => {
      const newWeekdays = cloneDeep(state.weekdays);
      newWeekdays[weekDayIndex].entries[trackingIndex].isEdit = isEditing;
      return {
        ...state,
        weekdays: newWeekdays,
      };
    },
  },
  effects: (dispatch: any) => ({
    async updateRange(
      {
        from,
        to,
        trackings,
      }: { from: string; to: string; trackings: TrackingType[] },
      rootState
    ) {
      const dateList = getAllDaysBetweenWeek(
        moment(from, DateFormats.API_DATE),
        moment(to, DateFormats.API_DATE)
      );
      const weekDays = dateList.map(
        date =>
          ({
            spent_at: date,
            entries: [],
          } as WeekDayType)
      );
      trackings.forEach((tracking, index) => {
        weekDays.forEach((weekDay, index) => {
          if (weekDay?.spent_at === tracking?.spent_at) {
            tracking.isEdit = true;
            weekDays[index].entries.push(tracking);
          }
        });
      });
      this.setWeekDays(weekDays);
    },
    async saveTracking({
      tracking,
      trackingIndex,
      weekDayIndex,
    }: {
      tracking: TrackingType;
      trackingIndex: number;
      weekDayIndex: number;
    }) {
      const response = await (hasValidId(tracking?.id)
        ? trackingApi.editTracking(tracking?.id, tracking)
        : trackingApi.createTracking(tracking));
      await dispatch.myTimeSheetWeekDays.update({
        tracking: response,
        trackingIndex,
        weekDayIndex,
      });
      return response;
    },

    async deleteTracking({
      trackingId,
      weekDayIndex,
      trackingIndex,
    }: {
      trackingId: number;
      weekDayIndex: number;
      trackingIndex: number;
    }) {
      const response = await trackingApi.deleteTracking(trackingId);
      await dispatch.myTimeSheetWeekDays.remove({
        trackingIndex,
        weekDayIndex,
      });
      return response;
    },
  }),
});

export const userAllocationsModel = capturedLoadingRematchModel(
  "userAllocationsModel",
  async ({ userId, startAt, endAt, ...rest }: PaginationQueryParams) =>
    allocationsApi.getUserAllocations({
      start_at: startAt,
      end_at: endAt,
      user_id: userId,
      include: [
        AllocationIncludeType.Project,
        AllocationIncludeType.ProjectRole,
      ],
      ...DEFAULT_API_PARAMS,
      ...rest,
    })
);
