import {
  LOCAL_STORAGE_REDIRECT_URL,
  BUGSNAG_ANONYMOUS_ID,
  NSTACK_AVAILABLE_LANGUAGES,
  NSTACT_UUID,
  NSTACK_TRANSACTION,
  ZD_STORE,
  NSTACK_META,
} from "app/utils/storageConst";

const removeLocalStorage = () => {
  localStorage.removeItem(BUGSNAG_ANONYMOUS_ID);
  localStorage.removeItem(LOCAL_STORAGE_REDIRECT_URL);
  localStorage.removeItem(NSTACK_AVAILABLE_LANGUAGES);
  localStorage.removeItem(NSTACT_UUID);
  localStorage.removeItem(NSTACK_META);
  localStorage.removeItem(NSTACK_TRANSACTION);
  localStorage.removeItem(ZD_STORE);
};

export { removeLocalStorage };
