import {
  init,
  RematchDispatch,
  RematchRootState,
  RematchStore,
} from "@rematch/core";
import createLoadingPlugin from "@rematch/loading";
import createRematchPersist from "@rematch/persist";
import * as models from "redux/models";
import selectPlugin from "@rematch/select";

const loading = createLoadingPlugin();

const persistPlugin = createRematchPersist({
  whitelist: [
    "userModel",
    "authModel",
    "configModel",
    "allOfficesModel",
    "officesModel",
    "officesQueryModel",
    "projectRolesModel",
    "userRolesModel",
    "allRolesModel",
    "allPracticeRolesModel",
    "skillsModel",
    "currencyModel",
    "allCurrenciesModel",
    "clientsTableModel",
    "regionsModel",
    "countriesModel",
    "officeLockedMonthsModel",
    "myTimeSheetModel",
    "myTimeSheetWeekDays",
    "myTimeSheetClientRoles",
    "myTimeSheetEmployee",
    "projectsModel",
    "userScopesModel",
    "teamListModel",
    "teamFiltersModel",
    "projectListModel",
    "menuTutorialModel",
  ],
  version: 3,
});

const store: RematchStore<Models> = init({
  models,
  plugins: [loading, persistPlugin, selectPlugin()],
  redux: {
    devtoolOptions: {
      disabled: process.env.NODE_ENV === "production",
    },
  },
});

export type Store = typeof store;
export type Models = typeof models;
export type RootState = RematchRootState<Models>;
// @ts-ignore
export type RootDispatch = RematchDispatch<Models>;

export default store;
