import i18next, { nstackClient, DEFAULT_NS } from "./localization";

export const changeLanguage = (locale: string) => {
  nstackClient.setLanguageByString = locale;
  updateLocalization();
};

export const updateLocalization = async () => {
  const {
    translation,
    translationMeta,
    availableLanguages,
  } = await nstackClient.appOpen();

  if (!(translation && translationMeta)) return undefined;
  i18next.addResourceBundle(
    translationMeta.language.locale,
    DEFAULT_NS,
    translation
  );

  await i18next.changeLanguage(translationMeta.language.locale);
  return availableLanguages || [];
};
