import React, { PureComponent } from "react";
import styles from "./TabPane.module.scss";
import { Tabs } from "antd";
import { TabPaneProps } from "antd/lib/tabs/index";
import cx from "classnames";

const { TabPane: AntdTabPane } = Tabs;

interface IProps extends TabPaneProps {
  /** Contents of Tabpane */
  children?: React.ReactNode | string;
}

/**
 * Custom Ant Design TabPane
 */
class TabPane extends PureComponent<IProps> {
  render() {
    const { children, tab, key, className, ...rest } = this.props;
    return (
      <AntdTabPane
        forceRender
        tab={tab}
        key={key}
        className={cx(styles.container, className)}
        {...rest}
      >
        {children}
      </AntdTabPane>
    );
  }
}

export default TabPane;
