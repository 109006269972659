import { LoadingModel, loadingRematchModel, wrapLoading } from "../../loading";
import * as scopesApi from "../scopesApi";
import { RoleAdminType } from "../../roles/roleType";
import { ApiResponse } from "../../api/models";
import { UserScopeUpdate } from "./userScopeTypes";
import { UserScopesParams } from "./userScopesConst";

export const userScopesModel = loadingRematchModel(
  async () => scopesApi.getUserScopes(UserScopesParams),
  {
    reducers: {
      setChangedScope: (state, payload: RoleAdminType) => {
        const apiResponse = wrapLoading<ApiResponse<RoleAdminType[]>>(
          state.model
        ).optionalSuccess;
        const scopes = apiResponse?.data || [];
        const newScopes = scopes.map(r => {
          if (r.id === payload.id) {
            return payload;
          }
          return r;
        });

        return {
          ...state,
          model: LoadingModel.success({
            meta: apiResponse?.meta,
            data: newScopes,
          }),
        };
      },
    },
    effects: () => ({
      async updateScope(role: UserScopeUpdate) {
        this.requestmodel();
        try {
          const response = await scopesApi.updateScope(role);
          this.setChangedScope(response);
        } catch (e) {
          this.setmodelError(e);
        }
      },
    }),
  }
);
