import { lazy } from "react";
import { IMenuItemType } from "router/routerType";

const ThemeScreen = lazy(() => import("./ThemeScreen"));

/** Screen: Theme Page */

export const THEME_SCREEN: IMenuItemType = {
  id: "theme",
  path: "/theme",
  component: ThemeScreen,
  title: "theme",
  icon: "theme",
};
