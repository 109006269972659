import { api } from "../api/apiHelper";
import { Endpoints } from "../api/apiConst";
import {
  EditUserType,
  sendNotificationSettingType,
  UpdatePasswordBody,
  UserNotificationType,
  UserType,
  globalSearchType,
  MonthySalaryType,
  PracticeRoleType,
  PracticeRoleRateType,
  MenuTutorials,
} from "./userType";
import { UtilHelper } from "../app/utilHelper";
import { UserIncludes } from "users/userConst";
import { ApiResponse } from "api/models";
import { ProjectIncludes } from "projects/projectConst";

/**
 * Get all Users
 */
export const getUsers = async (
  params?: any,
  includes?: any
): Promise<ApiResponse<UserType[]>> => {
  const url = Endpoints.USERS;

  // If params query exists remove all undefined values
  params && UtilHelper.removeUndefined(params);
  includes && UtilHelper.removeUndefined(includes);

  let queryString = {};
  if (!params || (params && !params.off_boarded)) {
    queryString = { ...params, off_boarded: false };
  }

  if (params && params.off_boarded) {
    delete params.off_boarded;
    queryString = { ...params };
  }

  queryString && UtilHelper.removeUndefined(queryString);

  try {
    const resp = await api.get<ApiResponse<UserType[]>>(url, {
      params: { ...queryString, ...includes },
    });
    return await resp.data;
  } catch (err) {
    throw err;
  }
};

/**
 * Get user by ID
 * @param userId
 */
export const getUserById = async (
  userId: number,
  params?: any
): Promise<UserType> => {
  const url = `${Endpoints.USERS}/${userId}`;

  try {
    const resp = await api.get(url, {
      params: { ...params },
    });
    return await resp.data;
  } catch (err) {
    throw err;
  }
};

/**
 * Delete user by ID
 * @param userId
 */
export const deleteUserById = async (
  userId: number,
  params?: any
): Promise<UserType> => {
  const url = `${Endpoints.USERS}/${userId}`;

  try {
    const resp = await api.delete(url, {
      params: { ...params },
    });
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/** Organization: Create User */
export const createUser = async (
  user: EditUserType,
  params?: any
): Promise<UserType> => {
  const url = Endpoints.USERS;

  try {
    const resp = await api.post(url, user, {
      params,
    });
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Organization: Edit User
 */

export const editUser = async (
  userId: number,
  user: Partial<EditUserType>,
  params?: any
): Promise<UserType> => {
  const url = `${Endpoints.USERS}/${userId}`;
  try {
    if (user && user.id) {
      delete user.id;
    }
    const resp = await api.patch(url, user, {
      params,
    });
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const editUserByEndDate = async (
  userId: number,
  params?: any
): Promise<UserType> => {
  const url = `${Endpoints.USERS}/${userId}`;
  try {
    const resp = await api.patch(url, {
      ...params,
    });
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Organization: Send invitation to user
 */
export const inviteUser = (userId: number) => {
  const url = `${Endpoints.USERS}/${userId}/invite`;

  return api.post(url, {
    ids: [userId],
  });
};

/**
 * Sends an email with link to reset password
 * @param username
 */
export const resetPassword = (username: string) => {
  return api.post(Endpoints.USERS_RESET_PASSWORD, { username });
};

/**
 * Onboard an user
 * @param username
 */
export const onBoardUser = async (id: number): Promise<ApiResponse<any>> => {
  const url = `${Endpoints.USERS}/${id}/onboard`;
  try {
    const resp = await api.post(url, {
      id,
    });
    return resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Set new password, needs a confirmation_token
 */
export const setNewPassword = (values: {
  new_password: string;
  new_password_confirmation: string;
  confirmation_token: string;
}) => {
  return api.post(Endpoints.USERS_SET_NEW_PASSWORD, values);
};

/**
 * Change current password
 */
export const changePassword = (values: UpdatePasswordBody) => {
  return api.patch(Endpoints.USERS_CHANGE_PASSWORD, values);
};

/**
 * Gets latest user data
 * TODO: Should maybe refactors into a separate userApi file.
 */
export const getUser = (): Promise<UserType> => {
  const USERS_INCLUDES = {
    include: [
      UserIncludes.SELECTED_OFFICES,
      UserIncludes.USER_SKILLS,
      UserIncludes.AUTO_TRACKING,
    ],
  };

  const url = `${Endpoints.USERS_PROFILE}`;
  return api
    .get(url, {
      params: { ...USERS_INCLUDES },
    })
    .then(response => response.data);
};

export const updateProfile = (user: any) => {
  const USERS_INCLUDES = {
    include: [
      UserIncludes.SELECTED_OFFICES,
      UserIncludes.OFFICE,
      UserIncludes.CURRENCY,
      UserIncludes.USER_SKILLS,
      UserIncludes.AUTO_TRACKING,
    ],
  };

  if (user && user?.selected_offices && Array.isArray(user?.selected_offices)) {
    user.selected_offices =
      user &&
      user?.selected_offices.filter((item: any) => {
        return typeof item === "number";
      });
  }

  const url = `${Endpoints.USERS_PROFILE}`;
  return api
    .patch(url, user, {
      params: { ...USERS_INCLUDES },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Gets user notification setting
 */
export const getUserNotificationSetting = (): Promise<UserNotificationType[]> => {
  return api.get(Endpoints.USER_NOTIFICATIONS).then(response => response.data);
};

/**
 * Save user notification setting
 */
export const saveUserNotification = (
  id: number,
  setting: sendNotificationSettingType
): Promise<UserNotificationType> => {
  const url = `${Endpoints.USER_NOTIFICATIONS}/${id}`;
  return api
    .patch(url, setting)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Global Search
 */
export const getGlobalSearch = async (
  search?: string
): Promise<ApiResponse<globalSearchType>> => {
  const url = Endpoints.SEARCH;
  // If search exists remove all undefined values
  const params = { q: search };
  UtilHelper.removeUndefined(params);
  try {
    const resp = await api.get<ApiResponse<globalSearchType>>(url, {
      params,
    });
    return await resp.data;
  } catch (err) {
    throw err;
  }
};

/**
 * Get User Status
 */
export const getUserStatus = async (
  id: number,
  from: string
): Promise<ApiResponse<any>> => {
  const url = `${Endpoints.USERS}/${id}/status`;

  try {
    const resp = await api.get<ApiResponse<any>>(url, {
      params: {
        from,
        include: ProjectIncludes.PROJECT_CLIENT_RESPONSIBLE,
      },
    });
    return await resp.data;
  } catch (err) {
    throw err;
  }
};

/** Organization: get office Monthy Salary by year */
export const getMonthySalaryByYear = (
  userId: string,
  year: string
): Promise<ApiResponse<MonthySalaryType[]>> => {
  const url = `${Endpoints.USERS}/${userId}/monthly-salaries`;

  return api
    .get(url, {
      params: { year },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** Organization: edit office Monthy Salary by year */
export const editMonthySalaryByMonth = (
  userId: string,
  params: MonthySalaryType
): Promise<ApiResponse<MonthySalaryType>> => {
  const url = `${Endpoints.USERS}/${userId}/monthly-salary/${params.id}`;

  return api
    .patch(url, { ...params })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * GET user skills
 */
export const getUserSkills = (
  userId: number
): Promise<ApiResponse<MonthySalaryType[]>> => {
  const url = `${Endpoints.USERS}/${userId}/skill-requests`;

  return api
    .get(url, { params: { sort: "requested_at:ascend" } })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Create user skills request
 */
export const createUserSkillsRequest = (
  userId: number,
  skills: any
): Promise<ApiResponse<MonthySalaryType[]>> => {
  const url = `${Endpoints.USERS}/${userId}/skill-requests`;

  return api
    .post(url, {
      requested_by_user_id: userId,
      skills,
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Update user skills request
 */
export const updateUserSkillsRequest = (
  userId: number,
  skillRequestId: number,
  params: any
): Promise<ApiResponse<MonthySalaryType[]>> => {
  const url = `${Endpoints.USERS}/${userId}/skill-requests/${skillRequestId}`;

  return api
    .patch(url, {
      ...params,
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Delete user skills request
 */
export const deleteUserSkillsRequest = (
  userId: number,
  skillRequestId: number
): Promise<ApiResponse<MonthySalaryType[]>> => {
  const url = `${Endpoints.USERS}/${userId}/skill-requests/${skillRequestId}`;

  return api
    .delete(url)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * GET user skills
 */
export const getUsersSkills = (
  params: any
): Promise<ApiResponse<MonthySalaryType[]>> => {
  const url = `${Endpoints.USERS}/skill-requests`;

  return api
    .get(url, { params: { ...params, include: ProjectIncludes.OFFICE } })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * Get user practice rates
 */
export const getPracticeRoles = async (
  params?: any
): Promise<ApiResponse<PracticeRoleType[]>> => {
  const url = Endpoints.PRACTICE_RATES;

  // If params query exists remove all undefined values
  params && UtilHelper.removeUndefined(params.search);

  try {
    const resp = await api.get<ApiResponse<PracticeRoleType[]>>(url, {
      params: { ...params },
    });
    return await resp.data;
  } catch (err) {
    throw err;
  }
};

export const getPracticeRoleRates = async (
  params?: any
): Promise<ApiResponse<PracticeRoleRateType[]>> => {
  try {
    const resp = await api.get<ApiResponse<PracticeRoleRateType[]>>(
      Endpoints.PRACTICE_ROLE_RATES,
      {
        params: { ...params },
      }
    );
    return await resp.data;
  } catch (err) {
    throw err;
  }
};

/**
 * Update Tour
 */
export const updateTour = (params: any) => {
  return api
    .post(Endpoints.TOUR, {
      ...params,
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response;
    });
};

/**
 * Get user menu tutorials
 */
export const getMenuTutorials = async (): Promise<ApiResponse<
  MenuTutorials[]
>> => {
  try {
    const resp = await api.get<ApiResponse<MenuTutorials[]>>(Endpoints.TOUR, {
      params: {
        include: ["menu"],
        type: "collection",
        limit: 50,
      },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

/**
 * Reset Tour
 */
export const resetTour = (params: any) => {
  return api
    .patch(Endpoints.RESET_TOUR, {
      ...params,
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response;
    });
};
