import { ApiResponse } from "../api/models";
import { RevenueDataType } from "./revenueType";
import { capitalize } from "lodash";
export class RevenueDataHelper {
  /**
   * Takes the data from revenue api and sales api
   * and turns it into data that the table  can use
   */
  static getRevenueData = (
    results: ApiResponse<RevenueDataType[]> | undefined
  ) => {
    if (!results || !results.data) {
      return [];
    }
    return results?.data.map(item => {
      if (item?.countries) {
        // rename country key to children
        item.children = item?.countries.map(item => {
          if (item?.offices) {
            // rename country key to children
            item.children = item?.offices.map(k => ({
              ...k,
              id: k.name,
            }));
            delete item.offices;
          }

          return {
            ...item,
            id: capitalize(item.name),
          };
        });
        delete item.countries;
      }

      return {
        ...item,
        id: capitalize(item.name),
      };
    });
  };
}
