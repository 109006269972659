/**
 * Menu ID according to BE
 * @readonly
 * @enum {string}
 */
export enum MenuTutorialPage {
  MY_TIMESHEET_PAGE = 1,
  ALLOCATION_OVERVIEW = 2,
  ALLOCATION_REQUEST = 3,
  PROJECTS = 4,
  PROJECT_DETAIL = 5,
  CLIENTS = 6,
  CLIENTS_DETAIL = 7,
  TIMESHEETS_OVERVIEW = 8,
  TIMESHEET_DETAIL_SCREEN = 9,
  TIMESHEETS_CATEGORISATION_SCREEN = 10,
  TIMESHEET_CATEGORISATION_DETAIL_SCREEN = 11,
  INSIGHTS_PROJECT_SCREEN = 12,
  INSIGHTS_BILLABLE_SCREEN = 13,
  INSIGHTS_REVENUE_SCREEN = 14,
  INSIGHTS_ALLOCATION_SCREEN = 15,
  INSIGHTS_SKILLS_SCREEN = 16,
  EMPLOYEES_OVERVIEW_SCREEN = 17,
  EMPLOYEES_DETAILS_SCREEN = 18,
  EMPLOYEES_SKILL_REQUEST_SCREEN = 19,
  PROFILE = 20,
  CASES_SCREEN = 21,
}
