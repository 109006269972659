import { lazy } from "react";
import { RouterParameters } from "router/routerConst";
import { IMenuItemType } from "router/routerType";
import { ViewAuditLogsMenu } from "./auditlogs.permissions";

const AuditLogsScreen = lazy(() => import("./AuditLogsScreen"));

/** Screen: Audit logs */
export const AUDIT_LOGS_SCREEN: IMenuItemType = {
  id: "audits",
  path: `/audits`,
  title: "auditLogs",
  component: AuditLogsScreen,
  icon: "container",
  permissions: ViewAuditLogsMenu,
};

export const AUDIT_LOGS_SCREEN_TAB: IMenuItemType = {
  ...AUDIT_LOGS_SCREEN,
  path: `/audits/${RouterParameters.TAB}`,
};
