import { lazy } from "react";
import { IMenuItemType } from "router/routerType";
import { RouterParameters } from "router/routerConst";
import { CreateModifyCases } from "./cases.permissions";

const CasesScreen = lazy(() => import("cases/CasesScreen"));

/** Screen: Content Page */

export const CASES_LIST_SCREEN: IMenuItemType = {
  id: "content",
  path: "/settings/content",
  title: "content",
  component: CasesScreen,
  permissions: CreateModifyCases,
};

export const CASES_LIST_EDIT_SCREEN: IMenuItemType = {
  ...CASES_LIST_SCREEN,
  path: `${CASES_LIST_SCREEN.path}${RouterParameters.EDIT}${RouterParameters.ITEM_ID}`,
};

export const CASES_CREATE_SCREEN: IMenuItemType = {
  ...CASES_LIST_SCREEN,
  path: CASES_LIST_SCREEN.path + RouterParameters.CREATE,
};
