import { ProjectType } from "../projects/projectType";
import { RoleType } from "../roles/roleType";

export type AllocationType = {
  document_type: string;
  id: number;
  project_role_id: number;
  project_id: number;
  user_id: number;
  start_at: string;
  end_at: string;
  total_time: number;
  time_per_day: number;
  time?: number;
  hours_per_day: number;
  hours?: number;
  note: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  overwrite_off_days?: boolean;
  overwrite_closed_days?: boolean;
  manual_allocation?: boolean;
  project_rate_card_id?: number;
  project_rate_card_id_allocation?: number;
  level?: number;
  service_offering_id?: number;

  days: {
    id: number;
    allocation_id: number;
    created_at: string;
    time: number;
    updated_at: string;
    date: string;
    hours: number;
  }[];

  project_rate_card?: {
    id: number;
    name: string;
    project_rate_card_id: number;
  };
  service_offering?: {
    service_offering_id: number;
    level: number;
  };

  project?: ProjectType;
  project_role?: RoleType;
  primary_skill_id?: number | null;

  // locally added attribute
  userDailyWorkingHours: number;
  manual_allocation_days?: string[];
};

export type AllocationFilterValue = {
  key: number;
  label: string;
};

export interface AllocationFilters {
  start_at?: string;
  end_at?: string;
  include?: AllocationIncludeType[];
  user_id?: number;
}

export type FilteredAllocationType = {
  [key: string]: string | number;
};

export enum AllocationIncludeType {
  Project = "project",
  ProjectRole = "project_role",
  ProjectRateCard = "project_rate_card",
}

export enum OutputTypes {
  VALUE = "value",
  HOURS = "hours",
  PERCENTAGE = "percentage",
}

export type AllocationFormType = {
  overwrite_off_days: boolean;
  overwrite_closed_days: boolean;
} & Partial<AllocationType>;

export enum AvailabilityFilterKeys {
  "availability",
  "startDate",
  "endDate",
}

export type LocalAllocationType = {
  project_id: number;
  user_id: number;
};
