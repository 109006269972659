import { api } from "../api/apiHelper";
import { Endpoints } from "../api/apiConst";
import { ClientProjectRole, ClientType } from "./clientType";
import { UtilHelper } from "../app/utilHelper";
import { ApiResponse } from "../api/models";

/** Organization: Get all client */
export const getClients = async (
  params?: Object
): Promise<ApiResponse<ClientType[]>> => {
  const url = Endpoints.CLIENTS;

  // If params query exists remove all undefined values
  params && UtilHelper.removeUndefined(params);

  const resp = await api.get<ApiResponse<ClientType[]>>(url, {
    params: { ...params },
  });
  return resp.data;
};

/** Organization: Create client */
export const createClient = (client: ClientType) => {
  const url = Endpoints.CLIENTS;

  return api.post(url, client).then((resp: any) => resp.data);
};

/** Client: Get project budget value */
export const getProjectBudgetValues = (clientId: number) => {
  const url = `${Endpoints.CLIENTS}/${clientId}/project-budget-values`;

  return api.get(url).then((resp: any) => resp.data);
};

/** Organization: Edit client */
export const editClient = (clientId: number, client: ClientType) => {
  const url = `${Endpoints.CLIENTS}/${clientId}`;

  return api.patch(url, client).then((resp: any) => resp.data);
};

export const getClientById = async (clientId: string): Promise<ClientType> => {
  const url = `${Endpoints.CLIENTS}/${clientId}`;
  const resp = await api.get<ClientType>(url);
  return resp.data;
};

/** Organization: Delete client */
export const deleteClient = (clientId: number) => {
  const url = `${Endpoints.CLIENTS}/${clientId}`;

  return api.delete(url);
};

/**
 * Organization: Gets a list of clients, client projects, and project roles
 * that the user is allocated to
 * The data is used in the cascader in My Timesheet
 */
export const getClientProjectRoles = async (pyload: {
  timeSheetUserId: number;
  month: string;
}): Promise<ApiResponse<ClientProjectRole[]>> => {
  const url = Endpoints.CLIENTS_PROJECT_ROLES;

  const resp = await api.get<ApiResponse<ClientProjectRole[]>>(url, {
    params: {
      user: pyload.timeSheetUserId,
      month_date: pyload.month,
    },
  });
  return resp.data;
};
