import { AuditLogs } from "identity/permissions/permissionConst";

export const ViewAuditLogsMenu = [
  AuditLogs.READ,
  AuditLogs.ORGANIZATION_READ,
  AuditLogs.PROJECTS_READ,
  AuditLogs.TIME_PLAN_READ,
];
export const ViewAuditLogs = [AuditLogs.READ];
export const ViewOrganizationAudits = [AuditLogs.ORGANIZATION_READ];
export const ViewProjectsAudits = [AuditLogs.PROJECTS_READ];
export const ViewTimePlanAudits = [AuditLogs.TIME_PLAN_READ];
