import React, { PureComponent } from "react";
import { Result } from "antd";
import { withTranslation, WithTranslation } from "react-i18next";

/** Error screen */
class ErrorScreen extends PureComponent<WithTranslation> {
  render() {
    const { t } = this.props;

    return (
      <Result
        status="500"
        title={t("notFound.error")}
        subTitle={t("notFound.errorText")}
      />
    );
  }
}

export default withTranslation()(ErrorScreen);
