import { lazy } from "react";
import { IMenuItemType } from "router/routerType";

const AuthScreen = lazy(() => import("identity/AuthScreen"));

/** Screen: Login Page */
export const LOGIN_SCREEN: IMenuItemType = {
  id: "login",
  path: "/",
  component: AuthScreen,
};

/** Screen: Forgot Password Page */
export const FORGOT_PASSWORD_SCREEN: IMenuItemType = {
  id: "forgot-password",
  path: "/forgot-password",
  component: AuthScreen,
};

/** Screen: Set New Password Page */
export const SET_NEW_PASSWORD_SCREEN: IMenuItemType = {
  id: "set-new-password",
  path: "/set-new-password",
  component: AuthScreen,
};

export const JUMPCLOUD_AUTH: IMenuItemType = {
  id: "jump-cloud-auth",
  path: "/auth/callback",
  component: AuthScreen,
};
