import {
  ApiMeta,
  PaginationQueryConfig,
  PaginationQueryParams,
  PaginationType,
} from "./models";
import { PaginationConfig } from "antd/es/pagination";
import { SorterResult } from "antd/lib/table/interface";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useDeepMemo } from "../ui/DeepMemo";
import qs from "query-string";
import * as H from "history";
import { UtilHelper } from "../app/utilHelper";

/**
 * This is the type that a Table uses as the parameters callback.
 */
export type PaginationParams<T> = Partial<Record<keyof T, string[]>>;

/**
 * Converts a {@link PaginationType} into a {@link PaginationConfig} to use
 * for paginating tables.
 */
export const paginationTypeToConfig = (
  pagination: PaginationType
): PaginationConfig => ({
  current: pagination.current_page,
  pageSize: pagination.per_page,
  total: pagination.total,
});

/**
 * Helpful hook to convert an {@link ApiMeta} to {@link PaginationConfig} in a
 * functional component.
 * @param meta
 */
export const usePagination = (meta?: ApiMeta) =>
  useMemo(() => meta && paginationTypeToConfig(meta.pagination), [meta]);

/**
 * When a table update is received, this method converts the {@link PaginationConfig}
 * into a {@link PaginationQueryConfig} that can be used in an api call to filter,
 * sort, and index on the data.
 * @param pagination - passed into a {@link SortableTable} as the pagination object.
 * @param sorter - sorting result object, used for sorting order.
 * @param params - specify these to for the object {@link T} to convert data into
 * query params.
 */
export const convertTableUpdateToQueryParams = <T>(
  pagination: PaginationConfig,
  sorter: SorterResult<T>,
  params?: PaginationQueryParams
): PaginationQueryConfig => {
  if (params && params.q) {
    params.q = params.q.trim();
  }

  let tableUpdate: PaginationQueryConfig = {
    page: pagination.current,
    ...params,
  };

  if (sorter.order) {
    tableUpdate.sort = sorter.field + ":" + sorter.order;
  } else {
    tableUpdate.sort = undefined;
  }
  return tableUpdate;
};

/**
 * Takes the first element from the filters array and returns it.
 */
export const firstFilterParam = (
  filters: string[] | undefined
): string | undefined => (filters && filters.length ? filters[0] : undefined);

/**
 * Extract the query parameters.
 */
export const useQueryParams = (search: H.Search, params?: object) => {
  return useDeepMemo(
    () =>
      UtilHelper.removeUndefined({
        ...qs.parse(search, { arrayFormat: "comma" }),
        ...params,
      }),
    [search, params]
  );
};

export const useParamsState = (
  search: H.Search,
  extraParams?: object
): [PaginationQueryConfig, Dispatch<SetStateAction<PaginationQueryConfig>>] => {
  const defaultParams = useQueryParams(search, extraParams);
  const [params, setParams] = useState<PaginationQueryConfig>(defaultParams);
  return [params, setParams];
};
