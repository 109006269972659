import { api } from "../api/apiHelper";
import { Endpoints } from "../api/apiConst";

/**
 * Logs user in and returns a user object, with access tokens among other things
 * @param username
 * @param password
 * @returns {object}
 */
export const login = (username: string, password: string) => {
  return api
    .post(Endpoints.USERS_LOGIN, {
      username: username,
      password: password,
    })
    .then(response => response.data);
};

/**
 * Logs user out by invalidating the current access token.
 */
export const logout = () => {
  /**
   * Empty object as second parameter prevents an "Invalid content type" error from the API.
   * The API seems to fail when no body is set in the request.
   */
  return api.post(Endpoints.USERS_LOGOUT, {});
};

/**
 * Generates a new access token from the provided refresh token.
 * @param {string} refreshToken
 * @returns {object}
 */
export const refreshToken = (refreshToken: string) => {
  return api
    .post(Endpoints.USERS_REFRESH, { refresh_token: refreshToken })
    .then(response => response.data);
};
