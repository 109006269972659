import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button, Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { useStore } from "redux/hooks";
import { useSelector } from "react-redux";
import { ClickParam } from "antd/lib/menu";
import { changeLanguage } from "localization/localizationHelper";
import { UserType } from "users/userType";
import { LanguageDef } from "@nstack-io/javascript-sdk/dist/types/types";
import ErrorFallback from "ui/ErrorFallback";

const { ErrorBoundary } = require("react-error-boundary");

interface Props {}

const LanguageSwitcher: React.FC<Props> = () => {
  const { i18n } = useTranslation();
  const { select, dispatch } = useStore();
  const availableLanguages: LanguageDef[] = useSelector(
    select.localizationModel.optionalSuccess
  );
  const [currentLanguage, setCurrentLanguage] = useState(
    availableLanguages &&
      availableLanguages.find(language => language.locale === i18n.language)
  );
  const user: UserType = useSelector(select.userModel.success);

  useEffect(() => {
    if (availableLanguages && availableLanguages.length > 0) {
      const currentLanguage = availableLanguages?.find(
        language => language.locale === user.locale
      );
      setCurrentLanguage(currentLanguage);
      changeLanguage(user.locale);
    }
  }, [availableLanguages, user.locale]);

  const handleMenuClick = (e: ClickParam) => {
    try {
      dispatch.userModel.updateProfile({
        locale: e.key,
      });
    } catch (e) {
    } finally {
      changeLanguage(e.key);
    }
  };

  const handleSingleSwitcherClick = useCallback(
    (e: any) => {
      try {
        dispatch.userModel.updateProfile({
          locale: e.target.value,
        });
      } catch (e) {
      } finally {
        changeLanguage(e.target.value);
      }
    },
    [dispatch.userModel]
  );

  const menu = (
    <Menu onClick={handleMenuClick}>
      {availableLanguages &&
        availableLanguages.length > 0 &&
        availableLanguages
          // only show other locales than the currently selected one
          .filter(language => language.locale !== i18n.language)
          .map(language => (
            <Menu.Item key={language.locale}>
              {language.locale.slice(0, 2).toUpperCase()}
            </Menu.Item>
          ))}
    </Menu>
  );

  const renderSwitcher = useMemo(() => {
    if (!availableLanguages) return null;
    const languageOptions = availableLanguages.filter(
      language => language.locale !== i18n.language
    );
    /** In case if there are only two languages, render only a button
     *  which shows alternative language. Otherwise render button with current
     *  language and a dropdown to choose another language.
     */
    if (languageOptions.length === 1) {
      return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Button
            shape="circle-outline"
            value={languageOptions[0].locale}
            onClick={handleSingleSwitcherClick}
          >
            {user.locale.slice(0, 2).toUpperCase()}
            {/* {languageOptions[0].locale.slice(0, 2).toUpperCase()} */}
          </Button>
        </ErrorBoundary>
      );
    } else {
      return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
            <Button shape="circle-outline">
              {currentLanguage?.locale.slice(0, 2).toUpperCase()}
            </Button>
          </Dropdown>
        </ErrorBoundary>
      );
    }
  }, [
    availableLanguages,
    currentLanguage,
    user.locale,
    handleSingleSwitcherClick,
    i18n.language,
    menu,
  ]);

  return renderSwitcher;
};

export default LanguageSwitcher;
