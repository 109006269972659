import React, { FunctionComponent, memo, useMemo } from "react";
import { Redirect } from "react-router-dom";
import { UserScopes } from "users/userConst";
import {
  useCheckUserPermissions,
  useReturnFailingPermissions,
} from "./permission_hooks";
import { GroupOrScopeItem } from "./permissionTypes";
import { groupOrScopeToScopes } from "./permissionConst";
import NotAccessScreen from "../NotAccessScreen";

const PERMISSIONS_KEY = "permissions";

interface IProps {
  /**
   * Wrapped elements to be outputted given the required
   * permissions are met
   */
  children: React.ReactNode;
  /**
   * Element to display in case of required permissions
   * haven't been met
   */
  fallback?: React.ReactNode;
  /**
   * Toggles whether a user must have all of the required
   * permissions, or only one of them. Default is TRUE.
   */
  hasAll?: boolean;
  /**
   * Where to direct the user, if required permissions
   * aren't met. If no path is supplied, the user will
   * not be redirected.
   */
  redirect?: string;
  /**
   * An array of required permissions
   */
  required: GroupOrScopeItem[];
}

/**
 * The permission component allows you to only display
 * content to users that have the required permissions.
 * It can be used in two ways. First one is wrapping the
 * content that is restricted, and using it as a HOC. The
 * second way, is to use the hasPermission function, to then
 * enable or disable buttons, for example.
 */

const Permission = ({
  children,
  fallback,
  hasAll = true,
  redirect,
  required,
}: IProps) => {
  const allowed = useCheckUserPermissions(required, hasAll);
  if (allowed) {
    /**
     * In case there is more than one child element, we need
     * to wrap the whole thing in a fragment.
     */
    return <>{children}</>;
  } else if (!allowed && redirect) {
    return <Redirect to={redirect} />;
  } else if (!allowed && fallback) {
    return <>{fallback}</>;
  } else {
    return <></>;
  }
};

export default memo(Permission);

export const hasPermission = (
  scopes: UserScopes[],
  required: GroupOrScopeItem[],
  hasAll: boolean = true
) => hasScopesPermission(scopes, groupOrScopeToScopes(required), hasAll);

export const hasScopesPermission = (
  scopes: UserScopes[],
  required: (UserScopes | undefined)[],
  hasAll: boolean = true
) => {
  if (!required) {
    return new Error(
      "Missing 'required' parameter. No required permissions have been specified"
    );
  }

  return hasAll
    ? required.every(scope => scope && scopes.includes(scope))
    : required.some(scope => scope && scopes.includes(scope));
};

export const clearPermissions = () => {
  sessionStorage.removeItem(PERMISSIONS_KEY);
};

type PermissionScreenProps = Omit<IProps, "fallback" | "redirect">;

/**
 * Wraps a {@link Permission} to automatically fallback to permission denial.
 */
export const PermissionAccessGate: FunctionComponent<PermissionScreenProps> = ({
  children,
  /**
   * Gate will require user has all permission specified as
   * top-level page permissions should be clear.
   */
  hasAll = true,
  required,
}) => {
  const failingPermissions = useReturnFailingPermissions(required);
  const fallback = useMemo(
    () => <NotAccessScreen requiredPermissions={failingPermissions} />,
    [failingPermissions]
  );
  return (
    <Permission
      required={required}
      children={children}
      fallback={fallback}
      hasAll={hasAll}
    />
  );
};
