import React, { FunctionComponent, memo } from "react";
import _find from "lodash/find";
import _chunk from "lodash/chunk";
import _toLower from "lodash/toLower";
import { UserType } from "users/userType";
import { useStore } from "redux/hooks";
import { useSelector } from "react-redux";
import { CurrencyType } from "../currencies/currencyType";

export enum StyleEnum {
  THOUSANDS_SEPARATOR = ",",
  DECIMAL_SEPARATOR = ".",
  CURRENCY_LABEL = "$",
  POSITION = "left",
}

export const CURRENCY_ISO = "USD";
const FRACTION_DIGITS = "";

interface IProps {
  /** currency/decimal/percentage value */
  value: number;
  /** Currency IsO*/
  currencyIso: string | undefined;
  /** How much value want to show after fraction */
  fractionDigitsShow?: boolean;
  /** Return always positive number */
  isPositive?: boolean;
  /** Decimal Count Show */
  decimalCount?: number;
}

const CurrencyFormat: FunctionComponent<IProps> = ({
  value = 0,
  currencyIso = CURRENCY_ISO,
  fractionDigitsShow = true,
  isPositive = false,
  decimalCount = 0,
}) => {
  const { select } = useStore();
  const user: UserType | undefined = useSelector(
    select.userModel.optionalSuccess
  );
  const currencies: CurrencyType[] | undefined = useSelector(
    select.allCurrenciesModel.optionalResponse
  );
  const currencyFormat = currencyFormattor(
    value,
    currencyIso,
    fractionDigitsShow,
    user,
    currencies,
    isPositive,
    decimalCount
  );
  return <>{currencyFormat}</>;
};

export default memo(CurrencyFormat);

export const currencyFormattor = (
  value = 0,
  currencyIso = CURRENCY_ISO,
  fractionDigitsShow = true,
  // use instead of local storage
  user: UserType | undefined,
  currencies: CurrencyType[] | undefined,
  isPositive = false,
  decimalCount = 0
) => {
  let thousandsSeparator = StyleEnum.THOUSANDS_SEPARATOR;
  let decimalSeparator = StyleEnum.DECIMAL_SEPARATOR;
  let currencyLabel = StyleEnum.CURRENCY_LABEL;
  let position = StyleEnum.POSITION;
  let fractionDigits = FRACTION_DIGITS;
  let currencyValue = "";
  let isNegative = false;

  if (Number(value) < 0) {
    isNegative = true;
    value = Math.abs(Number(value));
  }

  value = value ? value : 0;
  const number =
    decimalCount && decimalCount > 0
      ? Number(value)
          .toFixed(decimalCount)
          .split(".")
      : Number(value)
          .toString()
          .split(".");

  if (number.length === 2) {
    fractionDigits = number[1];
  }

  if (decimalCount && decimalCount > 0 && number.length !== 2) {
    const zero = 0;
    fractionDigits = zero.toFixed(decimalCount).split(".")[1];
  }

  if (!(currencies && user)) {
    return undefined;
  }
  const currency = _find(
    currencies,
    currency => _toLower(currency.iso3) === _toLower(currencyIso)
  );
  thousandsSeparator = user.thousand_separator as StyleEnum;
  decimalSeparator = user.decimal_separator as StyleEnum;
  currencyLabel = currency
    ? (currency.label as StyleEnum)
    : StyleEnum.CURRENCY_LABEL;
  position = (currency?.position as StyleEnum) || StyleEnum.POSITION;

  value = Number(number[0]);

  const fractionArr: any = [];

  if (value > 999) {
    const count = value
      .toString()
      .split("")
      .reverse();
    _chunk(count, 3).forEach(fraction =>
      fractionArr.push(fraction.reverse().join(""))
    );
  }

  currencyValue =
    fractionArr.length > 0
      ? `${fractionArr.reverse().join(thousandsSeparator)}`
      : value.toString();

  /** check if decimal seperator show */
  if (fractionDigitsShow && fractionDigits !== FRACTION_DIGITS) {
    currencyValue = `${currencyValue}${decimalSeparator}${fractionDigits}`;
  }

  /** set position */
  currencyValue =
    _toLower(position) === _toLower(StyleEnum.POSITION)
      ? `${currencyLabel} ${currencyValue}`
      : `${currencyValue} ${currencyLabel}`;

  /** Negetavity check */
  if (isNegative && !isPositive) {
    currencyValue = `-${currencyValue}`;
  }

  return currencyValue;
};
