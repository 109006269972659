import * as configApi from "api/configApi";
import {
  LoadingModel,
  loadingRematchModel,
  LoadingRematchModelState,
  wrapLoading,
} from "../loading";
import { Selector } from "@rematch/select";
import { ConfigType } from "./apiType";

export const configModel = loadingRematchModel(async () => configApi.config(), {
  selectors: (slice: any, createSelector) => {
    const selector: Selector<
      any,
      LoadingModel<ConfigType>
    > = createSelector(slice, (state: LoadingRematchModelState<ConfigType>) =>
      wrapLoading<ConfigType>(state.model)
    );
    return {
      couchDbUrl() {
        return createSelector(
          selector,
          (state: LoadingModel<ConfigType>) =>
            state.optionalSuccess?.couch_db.url
        );
      },
      apiVersion() {
        return createSelector(
          selector,
          (state: LoadingModel<ConfigType>) => state.optionalSuccess?.version
        );
      },
      skillRatings() {
        return createSelector(
          selector,
          (state: LoadingModel<ConfigType>) =>
            state.optionalSuccess?.skills.rating
        );
      },
    };
  },
});
