import { createModel } from "@rematch/core";
import { ApiResponse } from "../../api/models";
import { api } from "../../api/apiHelper";
import { Endpoints, DEFAULT_RESULTS_PER_PAGE } from "../../api/apiConst";
import { InsightSkill } from "../insightsType";
import { getUserFilters } from "../../users/filterHelpers";
import moment from "moment";
import { DateFormats } from "../../date/dateConst";
import { LoadingModel, LoadingRematchState, wrapLoading } from "loading";
import { UserType } from "../../users/userType";

interface InsightSkillsState {
  skills: LoadingModel<ApiResponse<InsightSkill[]>>;
}

const skillsAction = new LoadingRematchState<
  any,
  InsightSkillsState,
  ApiResponse<InsightSkill[]>,
  any
>("skills");

const INITIAL_STATE: InsightSkillsState = {
  skills: LoadingModel.empty(),
};

export const insightSkillsModel = createModel({
  state: INITIAL_STATE,
  reducers: {
    ...skillsAction.reducers(),
  },
  selectors: (slice, createSelector) => ({
    skills() {
      return createSelector(slice, (state: any) => state.skills);
    },
    isLoadingSkills() {
      return createSelector(slice, (state: any) => state.isLoadingSkills);
    },
  }),
  effects: () => ({
    async loadSkills(params: any, rootState: any) {
      const user: UserType = wrapLoading(rootState.userModel.model).success;
      if (!user) {
        return;
      }
      return skillsAction.loadingEffect(
        this,
        params,
        async action => {
          const now = moment().format(DateFormats.API_YEAR);
          const res = await api.get<ApiResponse<InsightSkill[]>>(
            `${Endpoints.INSIGHTS_SKILLS_URL}/year/${now}`,
            {
              params: {
                ...getUserFilters(user),
                ...action.params,
                limit: DEFAULT_RESULTS_PER_PAGE,
              },
            }
          );
          return res.data;
        },
        e => e
      );
    },
  }),
});
