import { lazy } from "react";
import { IMenuItemType } from "../router/routerType";
import { RouterParameters } from "../router/routerConst";
import {
  CreateModifyCurrencies,
  CreateModifyCurrencyExchangeRates,
  ViewCurrencies,
  ViewCurrencyExchangeRates,
} from "./currencies.permissions";

const CurrenciesScreen = lazy(() => import("./CurrenciesScreen"));
const ExchangeRateScreen = lazy(() =>
  import("./exhangerate/ExchangeRateScreen")
);

/** Screen: Settings Currencies Page
 */
export const SETTINGS_CURRENCIES_SCREEN: IMenuItemType = {
  id: "currencies",
  title: "currencies",
  path: "/settings/currencies",
  component: CurrenciesScreen,
  permissions: ViewCurrencies,
};

export const SETTINGS_CURRENCIES_EDIT_SCREEN: IMenuItemType = {
  ...SETTINGS_CURRENCIES_SCREEN,
  path:
    SETTINGS_CURRENCIES_SCREEN.path +
    RouterParameters.EDIT +
    RouterParameters.ITEM_ID,
  permissions: CreateModifyCurrencies,
};
export const SETTINGS_CURRENCIES_CREATE_SCREEN: IMenuItemType = {
  ...SETTINGS_CURRENCIES_SCREEN,
  path: SETTINGS_CURRENCIES_SCREEN.path + RouterParameters.CREATE,
  permissions: CreateModifyCurrencies,
};

/** Screen: Settings Currencies Exchange Rates Page */
export const SETTINGS_CURRENCY_EXCHANGE_RATE_SCREEN: IMenuItemType = {
  id: "exchange-rate",
  title: "Exchange Rate",
  path:
    SETTINGS_CURRENCIES_SCREEN.path + `/${RouterParameters.ID}/exchange-rates`,
  component: ExchangeRateScreen,
  permissions: ViewCurrencyExchangeRates,
};
export const SETTINGS_CURRENCIES_EXCHANGE_RATE_EDIT_SCREEN: IMenuItemType = {
  ...SETTINGS_CURRENCY_EXCHANGE_RATE_SCREEN,
  path:
    SETTINGS_CURRENCY_EXCHANGE_RATE_SCREEN.path +
    RouterParameters.EDIT +
    RouterParameters.ITEM_ID,
  permissions: CreateModifyCurrencyExchangeRates,
};
export const SETTINGS_CURRENCIES_EXCHANGE_RATE_CREATE_SCREEN: IMenuItemType = {
  ...SETTINGS_CURRENCY_EXCHANGE_RATE_SCREEN,
  path: SETTINGS_CURRENCY_EXCHANGE_RATE_SCREEN.path + RouterParameters.CREATE,
  permissions: CreateModifyCurrencyExchangeRates,
};
