import React from "react";
import styles from "./Wrapper.module.scss";
import { Spin } from "antd";

interface Props {
  /** Content  */
  children: React.ReactNode;
  /** Show loading spinner on top of content */
  loading?: boolean;
}

/** Wrapper to apply proper spacing */
const Wrapper: React.FC<Props> = ({ children, loading = false }) => {
  return (
    <Spin wrapperClassName={styles.wrapper} spinning={loading}>
      <div className={styles.content}>{children}</div>
    </Spin>
  );
};

export default Wrapper;
