import { ApiResponse, PaginationQueryConfig } from "api/models";
import { RootState } from "redux/store";
import { UserType } from "users/userType";
import { getUserFilters } from "users/filterHelpers";
import { Endpoints } from "api/apiConst";
import { api } from "api/apiHelper";
import { ProjectSale } from "./projectSalesTypes";
import {
  capturedLoadingRematchModel,
  CapturedState,
  LoadingRematchModelState,
  wrapLoading,
} from "../../loading";
import { ModelConfig } from "@rematch/core";
import { Periods } from "../../date/dateConst";

export const insightProjectSalesModel: ModelConfig<LoadingRematchModelState<
  CapturedState<PaginationQueryConfig, ApiResponse<ProjectSale[]> | undefined>
>> = capturedLoadingRematchModel(
  "insightProjectSalesModel",
  async (params: PaginationQueryConfig, rootState: RootState, dispatch) => {
    const user: UserType | undefined = wrapLoading(rootState.userModel.model)
      .success;
    if (!user) {
      return;
    }
    const filters = getUserFilters(user);
    let url = `${Endpoints.PROJECT_SALES}`;
    const newParams = {
      ...filters,
      ...params,
    };
    if (!newParams.period_length) {
      newParams.period_length = Periods.ALL;
    }
    const response = await api.get<ApiResponse<ProjectSale[]>>(url, {
      params: newParams,
    });
    dispatch.currencyModel.setCurrency(response.headers["x-currency"]);
    return response.data;
  }
);
