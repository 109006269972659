import {
  TimePlanAllocations,
  Allocation,
  ProjectsAllocations,
  ProjectsAllocationsRequest,
} from "../identity/permissions/permissionConst";

export const ViewAllocations = [TimePlanAllocations.READ];

export const ViewAllocationsRequests = [ProjectsAllocations.READ];

export const CreateModifyAllocations = [TimePlanAllocations.WRITE];

export const DeleteAllocations = [TimePlanAllocations.DELETE];

export const requestAllocations = [Allocation.READ];

export const AllocationApproveReject = [
  ProjectsAllocations.WRITE,
  ProjectsAllocationsRequest.WRITE,
];
