import moment from "moment";
import * as insightsApi from "bi/insightsApi";
import { DateFormats } from "date/dateConst";
import { loadingRematchModel } from "../../loading";

export const revenueResultsModel = loadingRematchModel(
  async (
    {
      selectedYear,
      search,
    }: {
      selectedYear: moment.Moment;
      search?: any;
    },
    rootState,
    dispatch
  ) => {
    const year = selectedYear.format(DateFormats.API_YEAR);
    const response: any = await insightsApi.getBIResults(year, search);
    dispatch.currencyModel.setCurrency(response.headers["x-currency"]);
    return response.data.data;
  }
);
