import { lazy } from "react";
import { IMenuItemType } from "router/routerType";
import { RouterParameters } from "router/routerConst";
import { ViewClients, ViewModifySpecificClient } from "./clients.permissions";

const ClientsScreen = lazy(() => import("clients/ClientsScreen"));
const ClientOverviewScreen = lazy(() =>
  import("clients/overview/ClientOverviewScreen/ClientOverviewScreen")
);

/** Screen: Clients Page */

export const CLIENTS_SCREEN: IMenuItemType = {
  id: "clients",
  path: "/clients",
  component: ClientsScreen,
  title: "clients",
  icon: "solution",
  permissions: ViewClients,
};

export const CLIENTS_LIST_EDIT_SCREEN: IMenuItemType = {
  ...CLIENTS_SCREEN,
  path: `${CLIENTS_SCREEN.path}${RouterParameters.EDIT}${RouterParameters.ITEM_ID}`,
};

export const CLIENTS_CREATE_SCREEN: IMenuItemType = {
  ...CLIENTS_SCREEN,
  path: CLIENTS_SCREEN.path + RouterParameters.CREATE,
  permissions: ViewModifySpecificClient,
};

export const CLIENTS_OVERVIEW_SCREEN: IMenuItemType = {
  id: "clients-overview",
  path: `${CLIENTS_SCREEN.path}/${RouterParameters.ID}`,
  component: ClientOverviewScreen,
  permissions: ViewModifySpecificClient,
};

export const CLIENTS_EDIT_SCREEN: IMenuItemType = {
  ...CLIENTS_OVERVIEW_SCREEN,
  path: CLIENTS_OVERVIEW_SCREEN.path + RouterParameters.EDIT,
  permissions: ViewModifySpecificClient,
};
