import * as officeApi from "geography/office/officeApi";
import { loadingRematchModel } from "../../loading";
import { ApiParamsType } from "../../api/apiType";

export const officesQueryModel = loadingRematchModel(
  async (params?: ApiParamsType) => officeApi.getAllOffices(params)
);

export const allOfficesModel = loadingRematchModel(async () =>
  officeApi.getAllOffices()
);

type GlobalOfficeSelectorType = {
  selectedOffices: number[];
};

type GlobalOfficeSelectorState = Readonly<GlobalOfficeSelectorType>;

export const globalOfficeSelectorModel = {
  state: {
    selectedOffices: [],
  } as GlobalOfficeSelectorState,
  reducers: {
    setOffices: (_: GlobalOfficeSelectorState, payload: number[]) => ({
      selectedOffices: payload,
    }),
  },
};
