import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "redux/hooks";
import _find from "lodash/find";
import { useSelector } from "react-redux";
import { UserType } from "users/userType";
import { CapturedState, LoadingModel } from "loading";
import { CurrencyType } from "./currencyType";
import { FilterOptionsType } from "ui/uiTypes";
import styles from "./GlobalCurrencySelector.module.scss";
import ErrorFallback from "ui/ErrorFallback/ErrorFallbackReload";

const { ErrorBoundary } = require("react-error-boundary");

const { Option } = Select;

interface Props {}

const GlobalCurrencySelector: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { select, dispatch } = useStore();
  const [selectedCurrency, setSelectedCurrency] = useState<
    FilterOptionsType | undefined
  >(undefined);
  const user: UserType = useSelector(select.userModel.success);

  const allCurrenciesModel: LoadingModel<CapturedState<
    any,
    CurrencyType[]
  >> = useSelector(select.allCurrenciesModel.model);
  const currencies: CurrencyType[] =
    allCurrenciesModel.optionalSuccess?.response || [];
  const currencyId = user?.currency?.id;

  const handleCurrencySelect = async (currencyValue: FilterOptionsType) => {
    const currency = currencyValue ? currencyValue.key : null;

    dispatch.userModel.updateProfile({
      currency_id: currency,
    });
    dispatch.currencyModel.setCurrency(
      currencyValue && currencyValue.label ? currencyValue.label.toString() : ""
    );
    setSelectedCurrency(currencyValue);
  };

  useEffect(() => {
    const selectedCurrency = _find(currencies, { id: currencyId });
    if (selectedCurrency) {
      setSelectedCurrency({
        key: selectedCurrency.id.toString(),
        label: selectedCurrency.name_localized,
      });
    } else {
      setSelectedCurrency(undefined);
    }
  }, [currencyId, currencies]);

  useEffect(() => {
    dispatch.allCurrenciesModel.requestTimed();
  }, [dispatch.allCurrenciesModel]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Select
        className={styles.globalCurrencySelector}
        value={selectedCurrency}
        allowClear={false}
        labelInValue
        showSearch
        filterOption={true}
        onChange={handleCurrencySelect}
        optionFilterProp="children"
        placeholder={t("header.headerCurrencyPlaceholder")}
        notFoundContent={
          allCurrenciesModel.hasLoaded() && currencies.length === 0
            ? t("header.headerCurrencySearchNotFound")
            : null
        }
      >
        {currencies?.map((currency: any) => (
          <Option key={currency.id} value={currency.id.toString()}>
            {currency.iso3.toUpperCase()}
          </Option>
        ))}
      </Select>
    </ErrorBoundary>
  );
};

export default GlobalCurrencySelector;
