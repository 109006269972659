import qs, { ParseOptions } from "query-string";
import * as H from "history";
import { PaginationQueryConfig } from "./models";
import deepEqual from "fast-deep-equal";

const DEFAULT_OPTIONS: ParseOptions = { arrayFormat: "comma" };

export const parseSearch = (
  search: H.Search,
  options: ParseOptions = DEFAULT_OPTIONS
) => qs.parse(search, options);

export const stringifyValues = (config: PaginationQueryConfig): string =>
  qs.stringify(config, DEFAULT_OPTIONS);

/**
 * Takes in a {@link PaginationQueryConfig} that overrides the current location's
 * search query. Returns the combined object.
 */
export const overrideSearchConfigValues = (
  search: H.Search,
  values: PaginationQueryConfig,
  options?: ParseOptions
): PaginationQueryConfig => {
  const currentSearch = parseSearch(search, options);
  return {
    ...currentSearch,
    ...values,
  };
};

/**
 * Pushes into history the set of filter query parameters.
 */
export const applyPaginationFiltersToSearch = (
  search: H.Search,
  pathname: H.Pathname,
  history: H.History,
  values: PaginationQueryConfig,
  options?: ParseOptions
): PaginationQueryConfig => {
  const newValues = overrideSearchConfigValues(search, values, options);
  history.replace({
    pathname: pathname,
    search: stringifyValues(newValues),
  });
  return newValues;
};

/**
 * Consolidates logic to check equality between newParameters and values
 * to ensure they change before notifying onChange. This will also modify
 * the URL to reflect the passed in values.
 */
export const onParamChangeHandler = (
  search: H.Search,
  pathname: H.Pathname,
  history: H.History,
  values: PaginationQueryConfig,
  onChange: (newValues: PaginationQueryConfig) => void
) => {
  const existingValues = parseSearch(search);
  const newValues = overrideSearchConfigValues(search, values);
  const isEqual = deepEqual(newValues, values);
  if (
    !isEqual ||
    Object.keys(newValues).length !== Object.keys(values).length
  ) {
    onChange(newValues);
  }
  if (!deepEqual(existingValues, newValues)) {
    history.replace({
      pathname: pathname,
      search: stringifyValues(newValues),
    });
  }
};
