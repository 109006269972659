import { lazy } from "react";
import { IMenuItemType } from "../router/routerType";
import { RouterParameters } from "../router/routerConst";
import {
  ViewGeographyOverview,
  ViewModifySpecificOffice,
  ViewSpecificOffice,
} from "./geography.permissions";
import { GEOGRAPHY_BASE_PATH } from "./geographyConst";

const GeographyScreen = lazy(() => import("./GeographyScreen"));
const OfficeRateCardScreen = lazy(() =>
  import("./office/ratecard/OfficeRateCardScreen/OfficeRateCardScreen")
);

/** Screen: Settings Geography Page */
export const SETTINGS_GEOGRAPHY_SCREEN: IMenuItemType = {
  id: "geography",
  title: "geography",
  path: `${GEOGRAPHY_BASE_PATH}/${RouterParameters.TAB}`,
  component: GeographyScreen,
  permissions: ViewGeographyOverview,
};

export const SETTINGS_COUNTRY_SCREEN: IMenuItemType = {
  id: "country",
  title: "geography",
  path: `${GEOGRAPHY_BASE_PATH}/countries`,
  component: GeographyScreen,
  permissions: ViewGeographyOverview,
};

export const SETTINGS_GEOGRAPHY_EDIT_SCREEN: IMenuItemType = {
  ...SETTINGS_GEOGRAPHY_SCREEN,
  path:
    SETTINGS_GEOGRAPHY_SCREEN.path +
    RouterParameters.EDIT +
    RouterParameters.ITEM_ID,
};
export const SETTINGS_GEOGRAPHY_CREATE_SCREEN: IMenuItemType = {
  ...SETTINGS_GEOGRAPHY_SCREEN,
  path: SETTINGS_GEOGRAPHY_SCREEN.path + RouterParameters.CREATE,
};

/** Screen: Settings Office Rate Cards Page */
export const SETTINGS_OFFICE_RATE_CARD_SCREEN: IMenuItemType = {
  id: "office-rate-card",
  path: SETTINGS_GEOGRAPHY_SCREEN.path + `/${RouterParameters.ID}`,
  component: OfficeRateCardScreen,
  permissions: ViewSpecificOffice,
};

export const SETTINGS_OFFICE_EDIT_SCREEN: IMenuItemType = {
  ...SETTINGS_OFFICE_RATE_CARD_SCREEN,
  path:
    SETTINGS_GEOGRAPHY_SCREEN.path +
    "/offices" +
    `/${RouterParameters.ID}` +
    "/edit",
  component: OfficeRateCardScreen,
  permissions: ViewModifySpecificOffice,
};

export const SETTINGS_OFFICE_RATE_CARD_CREATE_SCREEN: IMenuItemType = {
  ...SETTINGS_OFFICE_RATE_CARD_SCREEN,
  path:
    SETTINGS_OFFICE_RATE_CARD_SCREEN.path +
    "/rate-card" +
    RouterParameters.CREATE,
};

export const SETTINGS_OFFICE_EXTERNAL_RATE_CARD_CREATE_SCREEN: IMenuItemType = {
  ...SETTINGS_OFFICE_RATE_CARD_SCREEN,
  path:
    SETTINGS_OFFICE_RATE_CARD_SCREEN.path +
    "/external-rate-card" +
    RouterParameters.CREATE,
};

export const SETTINGS_OFFICE_TRANSFER_RATE_CARD_CREATE_SCREEN: IMenuItemType = {
  ...SETTINGS_OFFICE_RATE_CARD_SCREEN,
  path:
    SETTINGS_OFFICE_RATE_CARD_SCREEN.path +
    "/transfer-rate-card" +
    RouterParameters.CREATE,
};

export const SETTINGS_OFFICE_RATE_CARD_EDIT_SCREEN: IMenuItemType = {
  ...SETTINGS_OFFICE_RATE_CARD_SCREEN,
  path:
    SETTINGS_OFFICE_RATE_CARD_SCREEN.path +
    "/rate-card" +
    RouterParameters.EDIT +
    RouterParameters.ITEM_ID,
};

export const SETTINGS_OFFICE_EXTERNAL_RATE_CARD_EDIT_SCREEN: IMenuItemType = {
  ...SETTINGS_OFFICE_RATE_CARD_SCREEN,
  path:
    SETTINGS_OFFICE_RATE_CARD_SCREEN.path +
    "/external-rate-card" +
    RouterParameters.EDIT +
    RouterParameters.ITEM_ID,
};

export const SETTINGS_OFFICE_TRANSFER_RATE_CARD_EDIT_SCREEN: IMenuItemType = {
  ...SETTINGS_OFFICE_RATE_CARD_SCREEN,
  path:
    SETTINGS_OFFICE_RATE_CARD_SCREEN.path +
    "/transfer-rate-card" +
    RouterParameters.EDIT +
    RouterParameters.ITEM_ID,
};

/** Screen: Settings office targets */
export const SETTINGS_OFFICE_PROJECT_TEARLY_CARD_NEW_SCREEN: IMenuItemType = {
  ...SETTINGS_OFFICE_RATE_CARD_SCREEN,
  path:
    SETTINGS_OFFICE_RATE_CARD_SCREEN.path +
    "/targets" +
    RouterParameters.CREATE,
};

export const SETTINGS_OFFICE_PROJECT_TEARLY_CARD_EDIT_SCREEN: IMenuItemType = {
  ...SETTINGS_OFFICE_RATE_CARD_SCREEN,
  path:
    SETTINGS_OFFICE_RATE_CARD_SCREEN.path +
    "/targets" +
    RouterParameters.EDIT +
    RouterParameters.ITEM_ID,
};
