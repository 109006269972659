import Wrapper from "ui/Wrapper/Wrapper";
import { Button, Card, Col, Row } from "antd";
import React, { FunctionComponent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form, { FormItem } from "../../ui/forms/Form";
import styles from "../../projects/ProjectsFilter/ProjectsFilter.module.scss";
import { ProjectStageFilter } from "../../projects/ProjectStageFilter";
import { ProjectClientFilter } from "../../projects/ProjectClientFilter";
import { ProjectFilter } from "../../projects/projectType";
import { ProjectStages } from "../../projects/projectConst";
import { ClientIncludes } from "../../clients/clientConst";

interface Props {
  onQueryParametersReceived: (config: ProjectFilter) => void;
  selectedFilters: ProjectFilter | undefined;
}

export const InsightsFilters: FunctionComponent<Props> = ({
  selectedFilters,
  onQueryParametersReceived,
}) => {
  const { t } = useTranslation();
  const handleFilterSubmit = useCallback(
    (config: { stages: ProjectStages[]; clients: ClientIncludes }) => {
      onQueryParametersReceived({
        stages: config.stages,
        clients: config.clients,
        page: 1,
      });
    },
    [onQueryParametersReceived]
  );
  return (
    <Wrapper>
      <Card
        className="tour-step-insights-project-filter"
        title={t("projectsOverview.filters")}
      >
        <Form onSubmitForm={handleFilterSubmit}>
          <Row type="flex" gutter={24}>
            <Col span={24} xl={24} className={styles.filterColumn}>
              <Col span={24} md={6} xl={6}>
                <ProjectStageFilter selectedFilters={selectedFilters} />
              </Col>
              <Col span={24} md={6} xl={6}>
                <ProjectClientFilter selectedFilters={selectedFilters} />
              </Col>
              <Col span={24} md={4} xl={4}>
                {/* FormItem component used here for styling purposes only */}
                <FormItem>
                  <Button type="primary" htmlType="submit" block>
                    {t("projectsOverview.apply")}
                  </Button>
                </FormItem>
              </Col>
            </Col>
          </Row>
        </Form>
      </Card>
    </Wrapper>
  );
};
