import { Periods } from "./dateConst";
import { TFunction } from "i18next";
import { PeriodItem } from "../ui/DateFilter/DateFilter";

/**
 * Maps the specified {@link Periods} with the set of options into a list of
 * {@link PeriodItem}
 * @param t - the Translation property.
 * @param period - the selected period.
 * @param options - the set of available options.
 */
export const getPeriodFilters = ({
  t,
  period,
  options,
}: {
  t: TFunction;
  period: Periods;
  options: Periods[];
}): PeriodItem[] => {
  return options.map(o => ({
    value: o,
    text: t(`dateFilter.${o.toLowerCase()}`),
    default: period === o,
  }));
};
