/** Version parameter for endpoint URL */
const VERSION_URL = "v1";

/** Private endpoint URLs */
const CONFIGS_URL = VERSION_URL + "/configs";
const SKILLS_URL = VERSION_URL + "/skills";
const COUNTRIES_URL = VERSION_URL + "/countries";
const REGIONS_URL = VERSION_URL + "/regions";
const ROLES_URL = VERSION_URL + "/roles";
const CURRENCIES_URL = VERSION_URL + "/currencies";
const USERS_URL = VERSION_URL + "/users";
const NOTIFICATION_URL = VERSION_URL + "/notifications";
const OFFICES_URL = VERSION_URL + "/offices";
const PROJECTS_URL = VERSION_URL + "/projects";
const INVOICES_URL = VERSION_URL + "/invoices";
const PROJECT_ACTIVITIES_URL = VERSION_URL + "/bi/project-activities";
const PROJECT_SALES_URL = VERSION_URL + "/bi/project-sales";
const REPORTS_URL = VERSION_URL + "/reports";
const TRACKINGS_URL = VERSION_URL + "/trackings";
const CLIENTS_URL = VERSION_URL + "/clients";
const ITEMS_URL = VERSION_URL + "/items";
const TIMEZONES_URL = VERSION_URL + "/timezones";
const ALLOCATIONS_URL = VERSION_URL + "/allocations";
const INSIGHTS_ALLOCATIONS_URL = VERSION_URL + "/bi/allocations";
const INSIGHTS_ALLOCATION_CAPACITIES_URL = "/capacity";
const INSIGHTS_REVENUES_URL = VERSION_URL + "/revenues";
const INSIGHTS_SKILLS_URL = `${VERSION_URL}/bi/skills`;
const INSIGHTS_SALES_URL = VERSION_URL + "/sales";
const TIMESHEETS_URL = VERSION_URL + "/trackings/users";
const INSIGHTS_ALLOCATION_BILLABLE_URL = VERSION_URL + "/billables";
const INSIGHTS_ALLOCATION_URL = VERSION_URL + "/bi";
const GLOBAL_SEARCH = VERSION_URL + "/search";
const CASES_URL = VERSION_URL + "/cases";
const DEV_LANGUAGE_URL = VERSION_URL + "/dev-languages";
const SERVICE_OFFERING_URL = VERSION_URL + "/service-offerings";
const IDENTITY_SERVICE_OFFERING_URL =
  VERSION_URL + "/identity/service-offerings";
const IDENTITY_USER_SERVICE_OFFERING_URL =
  VERSION_URL + "/identity/user-service-offerings";
const END_PRODUCTS_URL = VERSION_URL + "/end-products";
const FORMATS_URL = VERSION_URL + "/formats";
const TCHNOLOGY_URL = VERSION_URL + "/technologies";
const METHODOLOGIES_URL = VERSION_URL + "/methodologies";
const INDUSTRIES_URL = VERSION_URL + "/industries";
const VERTICALS_URL = VERSION_URL + "/verticals";
const ALL_CLOSED_DAYS_URL = OFFICES_URL + "/closed_days";
const AUDITS_URL = VERSION_URL + "/audits";
const VALUE_OBJECTS = VERSION_URL + "/value-objects";
const PRACTICE_ROLES_URL = VERSION_URL + "/practice-roles";
const PRACTICE_RATES_URL = VERSION_URL + "/user-practice-rates";
const PRACTICE_ROLE_RATES_URL = VERSION_URL + "/practice-role-rates";
const USER_TUTORIALS = VERSION_URL + "/user-menu-tutorials";
const RESET_TUTORIALS = USERS_URL + "/profile/reset-tutorials";
const PROJECT_RATE_CARDS = VERSION_URL + "/project-rate-cards";

/**
 * Enum with all api endpoints
 * @readonly
 * @enum {string}
 */
export const Endpoints = Object.freeze({
  SEARCH: GLOBAL_SEARCH,
  CONFIGS: CONFIGS_URL,
  METHODOLOGIES: METHODOLOGIES_URL,
  INDUSTRIES: INDUSTRIES_URL,
  VERTICALS: VERTICALS_URL,
  TCHNOLOGY: TCHNOLOGY_URL,
  DEV_LANGUAGE: DEV_LANGUAGE_URL,
  SERVICE_OFFERING: SERVICE_OFFERING_URL,
  END_PRODUCTS: END_PRODUCTS_URL,
  FORMATS: FORMATS_URL,
  SKILLS: SKILLS_URL,
  COUNTRIES: COUNTRIES_URL,
  REGIONS: REGIONS_URL,
  ROLES: ROLES_URL,
  CURRENCIES: CURRENCIES_URL,
  OFFICES: OFFICES_URL,
  CASES: CASES_URL,
  OFFICES_TARGET: "/targets",
  OFFICES_TARGET_YEARS: "/target_years",
  TRACKINGS: TRACKINGS_URL,
  ALLOCATIONS: ALLOCATIONS_URL,
  ALLOCATIONS_OVERVIEW_LIST: ALLOCATIONS_URL + "/overview/list",
  ALLOCATIONS_PROJECTS_LIST: ALLOCATIONS_URL + "/projects/overview",
  ALLOCATIONS_TEAM_LIST: ALLOCATIONS_URL + "/teams/overview",
  ALLOCATION_SPLIT: "split",
  ALLOCATION_OVERWRITE_CLOSED_DAYS: "/overwrite_off_days",
  ALLOCATION_PROJECT_RATE_CARDS: PROJECT_RATE_CARDS + "/allocation-overview",
  INSIGHTS_ALLOCATIONS: INSIGHTS_ALLOCATIONS_URL,
  USERS: USERS_URL,
  USERS_LOGIN: USERS_URL + "/login",
  USERS_LOGOUT: USERS_URL + "/logout",
  USERS_RESET_PASSWORD: USERS_URL + "/reset-password",
  USERS_SET_NEW_PASSWORD: USERS_URL + "/set-new-password",
  USERS_CHANGE_PASSWORD: USERS_URL + "/profile/change-password",
  USERS_PROFILE: USERS_URL + "/profile",
  USERS_REFRESH: USERS_URL + "/refresh-token",
  USERS_ROLES: USERS_URL + "/roles",
  USERS_SCOPES: USERS_URL + "/scopes",
  USER_NOTIFICATIONS: NOTIFICATION_URL + "/events",
  CLIENTS: CLIENTS_URL,
  CLIENTS_PROJECT_ROLES: CLIENTS_URL + "/project_roles_cascade",
  ALL_CLOSED_DAYS: ALL_CLOSED_DAYS_URL,
  PROJECTS: PROJECTS_URL,
  INVOICES: INVOICES_URL,
  PROJECT_INVOICES: "/invoices",
  REPORTS: REPORTS_URL + "/project",
  REPORTS_OFFICES: REPORTS_URL + "/offices",
  REPORTS_USERS: REPORTS_URL + "/users",
  REPORTS_CASES: REPORTS_URL + "/cases",
  REPORTS_TIME_SHEET: REPORTS_URL + "/tracking",
  REPORTS_CATEGORISATION_TIME_SHEET: REPORTS_URL + "/timesheet",
  REPORTS_PROJECTS: REPORTS_URL + "/projects",
  REPORTS_ALLOCATION: REPORTS_URL + "/allocations",
  REPORTS_TIME_SHEET_DOWNLOAD: REPORTS_URL + "/download",
  OFFICES_RATE_CARD_DOWNLOAD: REPORTS_URL + "/offices/rates/download",
  REPORTS_PROJECTS_DOWNLOAD: PROJECTS_URL + "/reports/download",
  REPORTS_ALLOCATIONS_DOWNLOAD: REPORTS_URL + "/download",
  REPORTS_USERS_DOWNLOAD: USERS_URL + "/reports/download",
  REPORTS_CASES_DOWNLOAD: CASES_URL + "/reports/download",
  PROJECTS_VALUE_BREAKDOWN: "/value_breakdown",
  PROJECTS_PROFIT_LOSS: "/profit_loss",
  PROJECTS_COST_BREAKDOWN: "/cost_breakdown",
  PROJECTS_ALLOCATION: "/allocation",
  PROJECTS_ALLOCATIONS: "/allocations",
  PROJECT_ACTIVITIES: PROJECT_ACTIVITIES_URL,
  PROJECT_SALES: PROJECT_SALES_URL,
  PROJECTS_ROLES: "/roles",
  PROJECTS_ROLES_REPORT: "/roles-report",
  PROJECTS_ROLES_RATES: "/rates",
  PROJECTS_TEAM_MEMBERS: "/team_members",
  PROJECTS_TEAM_MEMBERS_REPORT: "/team-members-report",
  PROJECT_MEMBERS: "/members",
  PROJECT_PROGRESS_GRAPH: "/progress-graph",
  PROJECT_HOURS_GRAPH: "/hours-graph",
  PROJECT_RATE_CARDS: PROJECT_RATE_CARDS,
  ITEMS: ITEMS_URL,
  TIMEZONES_URL: TIMEZONES_URL,
  VISITS: "/visits",
  PRACTICE_ROLES: PRACTICE_ROLES_URL,
  PRACTICE_RATES: PRACTICE_RATES_URL,
  PRACTICE_ROLE_RATES: PRACTICE_ROLE_RATES_URL,

  INSIGHTS_ALLOCATION_CAPACITIES: INSIGHTS_ALLOCATION_CAPACITIES_URL,
  INSIGHTS_REVENUES: INSIGHTS_REVENUES_URL,
  INSIGHTS_SALES: INSIGHTS_SALES_URL,
  TIMESHEETS_URL: TIMESHEETS_URL,
  INSIGHTS_ALLOCATION_BILLABLE: INSIGHTS_ALLOCATION_BILLABLE_URL,
  INSIGHTS_BI_PROJECTIONS: INSIGHTS_ALLOCATION_URL + "/projections",
  INSIGHTS_BILLABLE: INSIGHTS_ALLOCATION_URL + "/billables",
  INSIGHTS_ALLOCATION_RESULTS: INSIGHTS_ALLOCATION_URL + "/results",
  INSIGHTS_SKILLS_URL,
  EMPLOYEES_AUDIT: AUDITS_URL + "/users",
  CURRENCIES_AUDIT: AUDITS_URL + "/currencies",
  OFFICE_AUDIT: AUDITS_URL + "/offices",
  MY_TIME_SHEET_MONTHLY_REPORT: TRACKINGS_URL + "/monthly-report",
  TRACKING_AUDIT: AUDITS_URL + "/trackings",
  ALLOCATIONS_AUDIT: AUDITS_URL + "/allocations",
  VALUE_OBJECTS: VALUE_OBJECTS,
  IDENTITY_SERVICE_OFFERING: IDENTITY_SERVICE_OFFERING_URL,
  IDENTITY_USER_SERVICE_OFFERING_URL: IDENTITY_USER_SERVICE_OFFERING_URL,
  TOUR: USER_TUTORIALS,
  RESET_TOUR: RESET_TUTORIALS,
});

/**
 * API response status codes enum
 * @readonly
 * @enum {number}
 */
export const ResponseStatus = Object.freeze({
  SUCCESS: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  DENIED: 412,
  REDIRECT: 503,
});

/**
 * API can in some cases format the data:
 * * Objects: List of objects (recommended)
 * * Table: Table format with headers and rows (not recommended)
 */
export const APIDataFormat = Object.freeze({
  OBJECTS: "objects",
  TABLE: "table",
  XLSX: "xlsx",
  JSON: "json",
});

/**
 * Some path segment text for API
 * * Objects: List of objects (recommended)
 */
export const APIPathSegment = Object.freeze({
  USERS: "users",
});

/**
 * Default amount of record per page from API
 *
 */
export const DEFAULT_RESULTS_PER_PAGE = 20;
export const DEFAULT_ALL_RESULTS_PER_PAGE = 60;
export const PER_PAGE_SIZE = 20;

export const DEFAULT_API_PARAMS = {
  limit: DEFAULT_RESULTS_PER_PAGE,
  page: 1,
};

export const DEFAULT_PAGINATION = {
  count: 0,
  current_page: 0,
  per_page: 20,
  total: 0,
  total_pages: 0,
};

/**
 * API check white list
 */
export const API_CHECK_WHITELIST = [
  { name: "sale-items", checks: ["projects", "sale-items"] },
  { name: "progress-graph", checks: ["projects", "progress-graph"] },
  { name: "hours-graph", checks: ["projects", "hours-graph"] },
  { name: "value_breakdown", checks: ["projects", "value_breakdown"] },
  { name: "profit_loss", checks: ["projects", "profit_loss"] },
  { name: "roles", checks: ["projects", "roles"] },
  { name: "team-members-report", checks: ["projects", "team-members-report"] },
  { name: "allocations", checks: ["projects", "allocations"] },
  { name: "invoices", checks: ["projects", "invoices"] },
  { name: "roles-report", checks: ["projects", "roles-report"] },
  { name: "include", checks: ["offices", "include=admin"] },
  { name: "target_years", checks: ["offices", "target_years"] },
  { name: "external-rates", checks: ["offices", "external-rates"] },
  { name: "visits", checks: ["offices", "visits"] },
  { name: "users", checks: ["users", "off_boarded"] },
];
