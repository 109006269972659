import * as practicesApi from "practices/practicesApi";
import { DEFAULT_API_PARAMS } from "api/apiConst";
import { PaginationQueryParams } from "api/models";
import { capturedLoadingRematchModel } from "../loading";
import { PracticesType } from "./practicesType";

export interface EditPracticesFormParams {
  skillId: number | undefined;
  values: PracticesType;
}

export const practicesModel = capturedLoadingRematchModel(
  "practicesModel",
  async (params: PaginationQueryParams) =>
    practicesApi.getPractices({
      ...DEFAULT_API_PARAMS,
      ...params,
    }),
  {
    reducers: {
      removePractice: state => {
        return {
          ...state,
        };
      },
    },
  }
);

export const allPracticesModel = capturedLoadingRematchModel(
  "allPracticesModel",
  async (params: PaginationQueryParams) => practicesApi.getAllPracticeRoles()
);
