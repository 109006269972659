import React, { PureComponent } from "react";
import { Result } from "antd";
import { withTranslation, WithTranslation } from "react-i18next";

/** 404 Not found screen */
class NotFoundScreen extends PureComponent<WithTranslation> {
  render() {
    const { t } = this.props;

    return (
      <Result
        status="404"
        title={t("notFound.title")}
        subTitle={t("notFound.text")}
      />
    );
  }
}

export default withTranslation()(NotFoundScreen);
