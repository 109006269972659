import React, { memo } from "react";
import cx from "classnames";
import * as i18n from "i18next";
import styles from "./RevenueColumnData.module.scss";
import CurrencyFormat from "ui/CurrencyFormat";
import { Tooltip } from "antd";

interface IProps {
  value: i18n.TFunctionResult | number;
  isBold?: boolean;
  currencyIso: string;
}

const RevenueColumnData = ({ value, isBold, currencyIso }: IProps) => {
  const revenueData = (
    <CurrencyFormat value={Number(value)} currencyIso={currencyIso} />
  );
  return (
    <Tooltip placement="topRight" title={revenueData}>
      <div className={cx(styles.columnData, { [styles.bold]: isBold })}>
        {revenueData}
      </div>
    </Tooltip>
  );
};

export default memo(RevenueColumnData);
