import { capturedLoadingRematchModel } from "../../loading";
import * as scopesApi from "../scopesApi";
import * as rolesApi from "../../roles/rolesApi";
import { RoleIncludes } from "../../roles/roleConst";
import { RoleWithScopes } from "../../roles/roleType";
import { UpdateUserScope } from "./permissionTypes";

export const roleById = capturedLoadingRematchModel(
  "roleById",
  async ({ id }: { id: number }) => {
    return rolesApi.getRoleById(id, {
      include: RoleIncludes.SCOPES,
    }) as Promise<RoleWithScopes>;
  },
  {
    effects: () => ({
      async updateUsersScopes(data: UpdateUserScope) {
        await scopesApi.updateUsersScopes(data.id, {
          scopes: data.scopes,
        });
      },
    }),
  }
);
