import React, { ChangeEvent } from "react";
import { Button, Icon, Input } from "antd";
import i18next from "../../localization/localization";
import cx from "classnames";
import styles from "./SortableTable.module.scss";
import { FilterDropdownProps } from "antd/es/table";

let searchInput: any;

type FilterProps = Pick<
  Required<FilterDropdownProps>,
  "setSelectedKeys" | "selectedKeys" | "confirm" | "clearFilters"
>;

export const getColumnSearchProps = (
  columnName: string,
  defaultValue?: string,
  params?: {
    iconType?: string;
  }
) => {
  const { iconType = "search" } = params || {};

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterProps) => {
      const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        /** Check if string only contains whitespace (ie. spaces, tabs or line breaks) */
        const isWhiteSpaceString = !e.target.value.replace(/\s/g, "").length;
        setSelectedKeys(
          e.target.value && !isWhiteSpaceString ? [e.target.value] : []
        );
      };
      return (
        <div className={styles.searchContainer}>
          <Input
            ref={node => {
              searchInput = node;
            }}
            placeholder={`${i18next.t(
              "table.searchPlaceholder"
            )} ${columnName}`}
            value={selectedKeys[0]}
            onChange={onInputChange}
            onPressEnter={() => confirm()}
            className={styles.searchInput}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            icon="search"
            size="small"
            className={styles.searchButton}
          >
            {i18next.t("table.search")}
          </Button>
          <Button
            onClick={() => clearFilters()}
            size="small"
            className={styles.searchButton}
          >
            {i18next.t("table.reset")}
          </Button>
        </div>
      );
    },
    filterIcon: (filtered: boolean) => (
      <Icon
        role="img"
        type={iconType}
        className={cx({ [styles.isSearched]: filtered })}
      />
    ),
    filteredValue: [defaultValue],
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  };
};
