import React from "react";
import { Icon } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useStore } from "redux/hooks";
import cx from "classnames";
import styles from "./VersionIndicator.module.scss";
import packageJson from "../../../package.json";
import { Button } from "antd";

export interface Props {
  collapsed: boolean;
  toggle: () => void;
}

const VersionIndicator: React.FunctionComponent<Props> = ({
  collapsed,
  toggle,
}) => {
  const { t } = useTranslation();
  const { select } = useStore();
  const apiVersion: string = useSelector(select.configModel.apiVersion);
  return (
    <>
      <Button
        onClick={toggle}
        className={cx(styles.version, {
          [styles.collapsed]: collapsed,
        })}
      >
        {collapsed ? (
          <>
            {packageJson.version}
            <Icon
              role="img"
              className={styles.collapseIcon + " themeMenuCollapseIcon"}
              type="right"
            />
          </>
        ) : (
          <>
            {t("default.appVersion", {
              appVersion: packageJson.version,
              apiVersion,
            })}
            <Icon
              role="img"
              className={styles.collapseIcon + " themeMenuCollapseIcon"}
              type="left"
            />
          </>
        )}
      </Button>
    </>
  );
};

export default VersionIndicator;
