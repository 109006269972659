import { DEFAULT_API_PARAMS } from "api/apiConst";
import { PaginationQueryConfig } from "api/models";
import { capturedLoadingRematchModel } from "loading";
import * as officeApi from "./office/officeApi";
import { OfficeIncludes } from "./office/officeConst";

const OFFICE_INCLUDES = {
  include: OfficeIncludes.ADMIN,
};

export const officeAdminModel = capturedLoadingRematchModel(
  "officeAdminModel",
  async (params: PaginationQueryConfig) =>
    officeApi.getOffices(
      {
        ...DEFAULT_API_PARAMS,
        ...params,
      },
      OFFICE_INCLUDES
    )
);
