import React, { FunctionComponent, memo } from "react";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";

interface IProps {
  isOfficesChanged: boolean;
}

const OfficeModifiedAlert: FunctionComponent<IProps> = ({
  isOfficesChanged,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {isOfficesChanged ? (
        <p>
          <Alert
            message={t("identityNotification.officeModified")}
            type="info"
            showIcon
          />{" "}
        </p>
      ) : (
        ""
      )}
    </>
  );
};

export default memo(OfficeModifiedAlert);
