export enum AllocationTypes {
  PROJECT = "project",
  TEAM = "team",
}

/** Used for Bars and Tags */
export enum AllocationColors {
  GREEN = "green",
  BLUE = "blue",
  ORANGE = "orange",
  PURPLE = "purple",
  GRAY = "gray",
  BLACK = "black",
  RED = "red",
}

export enum ProjectFilterKeys {
  ID = "id",
  ARCHIVED = "archived",
  MEMBERS = "members",
}

export enum TeamFilterKeys {
  SKILLLEVELS = "skillLevels",
  SKILLS = "skills",
  MANAGERS = "managers",
  ROLES = "roles",
  FREELANCERS = "freelancers",
  BILLABLE = "billable",
  AVAILABILITY = "availability",
  STARTDATE = "startDate",
  ENDDATE = "endDate",
  ID = "id",
}

// Fallback working hours for a user in case something will go wrong
export const FALLBACK_WORKING_HOURS = 7.5;

export enum AllocationIncludes {
  UPDATED_BY = "updated_by_user",
  REQUEST_ID = "allocation_request_id",
  PROJECT_RATE_CARD = "project_rate_card",
  SERVICE_OFFERING = "service_offering",
}

// Fallback working hours for a user in case something will go wrong
export const APICALL_COUNTDOWN_TIME_IN_SEC = 30;
