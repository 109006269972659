import { useSelector } from "react-redux";
import { useStore } from "redux/hooks";
import { hasScopesPermission } from "./Permission";
import { useDeepMemo } from "../../ui/DeepMemo";
import { groupOrScopeToScopes } from "./permissionConst";
import { GroupOrScopeItem } from "./permissionTypes";
import { UserScopes } from "../../users/userConst";

/**
 * A hook to check if a user has permissions or not to perform an action.
 * @param requiredScopes - the set of scopes to check against current user.
 * @param hasAll - default false, if true, user needs all scopes provided.
 * @param requireOnEmpty - if false, it'll return true if required scopes is empty.
 */
export const useCheckUserPermissions = (
  requiredScopes: GroupOrScopeItem[],
  hasAll = false,
  requireOnEmpty = false
) => {
  const { select } = useStore();
  const scopes: UserScopes[] = useSelector(select.userModel.scopes);
  return useDeepMemo(() => {
    if (requiredScopes.length === 0) {
      return !requireOnEmpty;
    }
    return hasScopesPermission(
      scopes,
      groupOrScopeToScopes(requiredScopes),
      hasAll
    );
  }, [scopes, requiredScopes, hasAll]);
};

export const useReturnFailingPermissions = (
  requiredScopes: GroupOrScopeItem[]
) => {
  const { select } = useStore();
  const scopes: UserScopes[] = useSelector(select.userModel.scopes);
  return useDeepMemo(() => {
    const mappedScopes = groupOrScopeToScopes(requiredScopes);
    return scopes.filter(f => !mappedScopes.find(m => m === f));
  }, [scopes, requiredScopes]);
};
