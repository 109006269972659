import { lazy } from "react";
import { IMenuItemType } from "../router/routerType";
import { RouterParameters } from "../router/routerConst";
import { CreateModifyCases } from "cases/cases.permissions";

const CasesScreen = lazy(() => import("./CasesScreen"));

/** Screen: Settings Geography Page */
export const SETTINGS_CASES_SCREEN: IMenuItemType = {
  id: "altas-options",
  title: "atlasOptions",
  path: `/settings/atlas-options/${RouterParameters.TAB}`,
  component: CasesScreen,
  permissions: CreateModifyCases,
};
export const SETTINGS_CASES_EDIT_SCREEN: IMenuItemType = {
  ...SETTINGS_CASES_SCREEN,
  path:
    SETTINGS_CASES_SCREEN.path +
    RouterParameters.EDIT +
    RouterParameters.ITEM_ID,
};
export const SETTINGS_CASES_CREATE_SCREEN: IMenuItemType = {
  ...SETTINGS_CASES_SCREEN,
  path: SETTINGS_CASES_SCREEN.path + RouterParameters.CREATE,
};
