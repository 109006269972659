import themeStyles from "../index.module.scss";
import cx from "classnames";

export const changeThemeHandler = (theme: string) => {
  const color = cx({
    [themeStyles.dark]: theme === "dark",
    [themeStyles.light]: theme === "light",
    [themeStyles.bright]: theme === "bright",
  });

  const body = document.body;
  body.removeAttribute("class");
  body.classList.add(color || "default");
};
