import { api } from "../api/apiHelper";
import { Endpoints } from "../api/apiConst";
import { UtilHelper } from "../app/utilHelper";
import { ApiResponse } from "../api/models";
import { RoleAdminType, RoleType } from "../roles/roleType";
import { UserScopeUpdate } from "./userScopes/userScopeTypes";

/** Organization: Get all project roles */
export const getUserRoles = () => {
  const url = Endpoints.USERS_ROLES;

  return api
    .get(url)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};

/**
 * Organization: Update scopes
 */
export const updateUsersScopes = async (
  roleId: number,
  data: { scopes: number[] } & Partial<RoleType>
): Promise<RoleType> => {
  const url = `${Endpoints.USERS_ROLES}/${roleId}`;

  try {
    const resp = await api.patch(url, data);
    return await resp.data;
  } catch (err) {
    throw err.response.data;
  }
};

/**
 * Organization: Get scopes
 */
export const getUserScopes = (
  includes?: any
): Promise<ApiResponse<RoleAdminType[]>> => {
  const url = Endpoints.USERS_SCOPES;
  includes && UtilHelper.removeUndefined(includes);

  return api
    .get(url, {
      params: {
        ...includes,
      },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

export const updateScope = async (
  scope: UserScopeUpdate
): Promise<RoleType> => {
  try {
    const resp = await api.patch(
      `${Endpoints.USERS_SCOPES}/${scope.id}`,
      scope
    );
    return resp.data;
  } catch (e) {
    throw e.response.data;
  }
};
