import SortableTable, {
  getColumnSearchProps,
} from "ui/SortableTable/SortableTable";
import React, { FunctionComponent, useMemo, useCallback } from "react";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";
import { paginationTypeToConfig } from "../../api/paginationHelpers";
import { ApiResponse, PaginationQueryConfig } from "../../api/models";
import { InsightSkill } from "../insightsType";
import Column from "antd/es/table/Column";
import { mapSkillsWithRatingLevels } from "./utils";
import { LoadingModel } from "../../loading";
import OfficeModifiedAlert from "ui/OfficeModifiedAlert";
import store from "redux/store";
import {
  convertTableUpdateToQueryParams,
  firstFilterParam,
  PaginationParams,
} from "api/paginationHelpers";
import { PaginationConfig } from "antd/lib/table";
import { SorterResult } from "antd/es/table";
import { isNil } from "lodash";
import { useSelector } from "react-redux";
import { useStore } from "redux/hooks";
import { UserType } from "users/userType";

interface Props {
  skills: LoadingModel<ApiResponse<InsightSkill[]>>;
  /** Handle querystring update on pagination */
  onParamsChange: (values: PaginationQueryConfig) => void;
  columns?: any;
}

export const InsightsSkillsTable: FunctionComponent<Props> = ({
  skills,
  onParamsChange,
  columns,
}) => {
  const { t } = useTranslation();
  const { select } = useStore();
  const user: UserType = useSelector(select.userModel.success);
  const skillsSuccess = skills.optionalSuccess;
  const meta = skillsSuccess && skillsSuccess.meta;
  const pagination = useMemo(
    () => meta && paginationTypeToConfig(meta.pagination),
    [meta]
  );
  // map data to support dynamic level columns
  const skillsData = skillsSuccess && skillsSuccess.data;
  const { mapped, ratingLevels } = useMemo(
    () => mapSkillsWithRatingLevels(skillsData),
    [skillsData]
  );

  const handleTableUpdate = useCallback(
    (
      pagination: PaginationConfig,
      params: PaginationParams<any>,
      sorter: SorterResult<any>
    ) => {
      const queryParams = convertTableUpdateToQueryParams(pagination, sorter, {
        q: firstFilterParam(params.name),
      });
      onParamsChange(queryParams);
    },
    [onParamsChange]
  );

  const getSortOrder = (columnIndex: string) => {
    if (!isNil(columns.sort)) {
      const sortArr = columns.sort.split(":"); // sortedColumn:sortType
      if (sortArr[0] === columnIndex) {
        return sortArr[1];
      }
    }

    return null;
  };

  return (
    <>
      <OfficeModifiedAlert
        isOfficesChanged={store.getState().authModel.isOfficesChanged}
      />
      <SortableTable
        loading={skills.isLoading}
        data={mapped}
        onChange={handleTableUpdate}
        localization={{
          emptyListText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("insightsSkills.noSkills")}
            />
          ),
        }}
        pagination={pagination}
      >
        <Column
          sorter={true}
          title={t("insightsSkills.tableHeaderSkill")}
          render={(text: any, item: any) =>
            user.locale === "en-US"
              ? item?.name
              : item?.name_localized
              ? item?.name_localized
              : item?.name
          }
          key="name"
          dataIndex="name"
          width={300}
          {...getColumnSearchProps(
            t("insightsSkills.tableHeaderSkill"),
            columns && columns.q
          )}
          sortOrder={getSortOrder("name")}
        />
        {ratingLevels.map(level => (
          <Column
            sorter={true}
            title={t("insightsSkills.tableHeaderLevel", { level })}
            key={`level-${level}`}
            dataIndex={`level-${level}`}
            width={100}
            sortOrder={getSortOrder(`level-${level}`)}
          />
        ))}
        <Column
          sorter={true}
          title={t("insightsSkills.tableHeaderCount")}
          key="count"
          dataIndex="count"
          width={100}
          sortOrder={getSortOrder("count")}
        />
      </SortableTable>
    </>
  );
};
