import { api, getAll } from "../api/apiHelper";
import { DEFAULT_ALL_RESULTS_PER_PAGE, Endpoints } from "../api/apiConst";
import { PracticesType } from "./practicesType";
import { UtilHelper } from "app/utilHelper";
import { ApiResponse } from "../api/models";
import { ApiParamsType } from "api/apiType";

/** Organization: Get all skills */
export const getPractices = (
  params?: any
): Promise<ApiResponse<PracticesType[]>> => {
  const url = `${Endpoints.PRACTICE_ROLES}?include=practice`;

  // If params query exists remove all undefined values
  params && UtilHelper.removeUndefined(params);

  return api
    .get(url, {
      params: { ...params },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};

export /**
 * Get all currencies from all project roles
 *
 * @param {ApiOptionsType} [options]
 * @returns
 */
const getAllPracticeRoles = (
  params?: ApiParamsType
): Promise<PracticesType[]> => {
  return getAll(Endpoints.PRACTICE_ROLES, {
    ...params,
    limit: DEFAULT_ALL_RESULTS_PER_PAGE,
  });
};
