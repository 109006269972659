import {
  Card,
  Checkbox,
  Alert,
  Col,
  Icon,
  Row,
  Descriptions,
  Button,
  message,
} from "antd";
import { ApiResponse } from "api/models";
import { RevenueDataHelper } from "bi/revenueHelper";
import values from "lodash/values";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation, WithTranslation } from "react-i18next";
import { useStore } from "redux/hooks";
import { useSelector } from "react-redux";
import { RouteComponentProps, useLocation } from "react-router";
import { formatRoute } from "react-router-named-routes";
import { PRIVATE_ROUTES } from "router/Router.config";
import { TabParams } from "router/routerConst";
import BrowserTitle from "ui/BrowserTitle";
import SubHeader from "ui/SubHeader/SubHeader";
import Tabs, { TabPane } from "ui/tabs/Tabs";
import Wrapper from "ui/Wrapper/Wrapper";
import YearPicker from "ui/YearPicker/YearPicker";
import RevenueDataList from "./datalist/RevenueDataList/RevenueDataList";
import { TabsOptions } from "./revenueTypes";
import { RevenueDataType } from "../revenueType";
import { LoadingModel } from "../../loading";
import { UserType } from "users/userType";
import { getUserFilters } from "users/filterHelpers";
import styles from "./InsightsRevenueScreen.module.scss";
import ErrorFallback from "ui/ErrorFallback";
import { capitalize } from "lodash";
import * as billableApi from "bi/billableApi";
import { DateFormats } from "date/dateConst";
import qs from "query-string";
import { InsightsRevenueSteps } from "../InsightsUtils";
import Tour from "../../ui/tour/tour";
import { MenuTutorialPage } from "ui/tour/tourConstant";

const { Item } = Descriptions;

const { ErrorBoundary } = require("react-error-boundary");
interface IProps
  extends RouteComponentProps<TabParams<TabsOptions>>,
    WithTranslation {}

const progressText = {
  InProgress: "In Progress",
  NotInProgress: "Not in Progress",
};

/**
 * Revenue Screen
 */
export const InsightsRevenueScreen = (props: IProps) => {
  const { match, history } = props;
  const { t } = useTranslation();
  const steps = InsightsRevenueSteps(t);
  const location = useLocation();

  const { dispatch, select } = useStore();
  const currency = useSelector(select.currencyModel.current);
  const results: LoadingModel<ApiResponse<RevenueDataType[]>> = useSelector(
    select.revenueResultsModel.model
  );
  const user: UserType = useSelector(select.userModel.success);

  const [isConsolidate, setIsConsolidate] = useState<boolean>(false);
  const [meta, setMeta] = useState<any>(null);

  const [tabActiveKey, UpdateActiveKey] = useState(
    values(TabsOptions).includes(props.match.params.tab)
      ? props.match.params.tab
      : values(TabsOptions)[0]
  );
  const { selected_year } = qs.parse(location.search);
  const [selectedYear, setSelectedYear] = useState(
    (selected_year && moment(selected_year)) || moment()
  );

  let defaultRoute: string = "";
  if (match.path === PRIVATE_ROUTES.INSIGHTS_REVENUE_SCREEN.path) {
    defaultRoute = formatRoute(PRIVATE_ROUTES.INSIGHTS_REVENUE_SCREEN.path, {
      tab: tabActiveKey,
    });
  }

  useEffect(() => {
    // Default Route redirection
    if (defaultRoute) {
      history.push({ pathname: defaultRoute, search: location.search });
    }
  }, [defaultRoute, history, location.search]);

  useEffect(() => {
    setMeta(results?.optionalSuccess?.meta);
  }, [results]);

  useEffect(() => {
    const search = getUserFilters(user);
    const params = {
      selectedYear,
      search,
    };
    dispatch.revenueResultsModel.request(params);
  }, [dispatch.revenueResultsModel, selectedYear, user]);

  /** Change tabs  */
  const handleTabChange = (tabActiveKey: string) => {
    const isExistsTabKey = values(TabsOptions).includes(
      tabActiveKey as TabsOptions
    );
    if (isExistsTabKey) {
      const newRoute = formatRoute(
        PRIVATE_ROUTES.INSIGHTS_REVENUE_SCREEN.path,
        {
          tab: tabActiveKey,
        }
      );
      UpdateActiveKey(tabActiveKey as TabsOptions);
      history.push({ pathname: newRoute, search: location.search });
    }
  };

  const getTabTitle = (tabKey: string) => {
    switch (tabKey) {
      case TabsOptions.REVENUE:
        return t("insightsRevenue.title");
      case TabsOptions.SALES:
        return t("insightsSales.title");
      case TabsOptions.AVERAGE_HOUR:
        return t("insightsAverageHour.title");
      default:
        return "";
    }
  };

  const onYearChange = (year: moment.Moment) => {
    setSelectedYear(year);
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        ...qs.parse(location.search),
        selected_year: year.format("YYYY"),
      }),
    });
  };

  const revenueData = useMemo(
    () => RevenueDataHelper.getRevenueData(results.optionalSuccess),
    [results]
  );

  const onChangeConsolidate = (event: any) => {
    setIsConsolidate(event.target.checked);
  };

  const underConstructionMessage = useMemo(
    () => (
      <div className={styles.alertMessageBody}>
        <Row>
          <Col lg={1} xs={3}>
            <Icon
              role="img"
              className={styles.alertIcon}
              type="exclamation-circle"
            />
          </Col>
          <Col lg={21} xs={21}>
            <div className={styles.userName}>
              {t("insightsTargets.underConstructionMessage")}
            </div>
          </Col>
        </Row>
      </div>
    ),
    [t]
  );

  const handleRecalculate = async () => {
    try {
      message.success(t("insightsTargets.messageSuccessRecalculate"));
      const year = selectedYear.format(DateFormats.API_YEAR);
      const response = await billableApi.prjectionsCalculations(year);
      setMeta(response.meta);
    } finally {
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <BrowserTitle title={getTabTitle(tabActiveKey)} />
      <SubHeader title={t("insightsTargets.title")} />
      <Tour steps={steps} id={MenuTutorialPage.INSIGHTS_REVENUE_SCREEN} />
      <div className="tour-step-main">
        <Wrapper>
          <Alert
            message={underConstructionMessage}
            type="warning"
            className={styles.alertMessage}
          />
        </Wrapper>

        <Wrapper>
          <Card className="tour-step-insights-revenue-overview">
            <Tabs
              defaultActiveKey={tabActiveKey}
              onChange={handleTabChange}
              tabBarExtraContent={
                <div className={styles.tabExtraContent}>
                  <Row type="flex" justify="space-between" gutter={16}>
                    <Col lg={{ span: 6, order: 1 }}>
                      <Button
                        className="tour-step-insights-revenue-recalculate"
                        onClick={handleRecalculate}
                        type="primary"
                        ghost
                      >
                        {t("insightsTargets.recalculateButton")}
                      </Button>
                    </Col>

                    <Col lg={{ span: 13, order: 2 }}>
                      <Descriptions
                        className={styles.calculationStatus}
                        column={1}
                        size="small"
                      >
                        <Item label={t("insightsTargets.recalculatedAt")}>
                          {meta && meta?.last_populated_at
                            ? capitalize(
                                moment(
                                  meta && meta?.last_populated_at
                                    ? meta.last_populated_at
                                    : moment()
                                ).fromNow()
                              )
                            : "---"}
                        </Item>

                        <Item label={t("insightsTargets.calculationStatus")}>
                          {meta?.population_status ? (
                            <b style={{ color: "red" }}>
                              {progressText?.InProgress}
                            </b>
                          ) : (
                            <b style={{ color: "green" }}>
                              {progressText?.NotInProgress}
                            </b>
                          )}
                        </Item>
                      </Descriptions>
                    </Col>

                    <Col
                      xs={{ order: 3 }}
                      sm={{ span: 12, order: 2 }}
                      lg={{ span: 5, order: 3 }}
                    >
                      {tabActiveKey !== TabsOptions.AVERAGE_HOUR && false && (
                        <Checkbox
                          className={styles.consolidateCheckbox}
                          onChange={onChangeConsolidate}
                        >
                          {t("insightsTargets.consolidatedRevenue")}
                        </Checkbox>
                      )}
                      <YearPicker
                        defaultYear={selectedYear}
                        onYearChange={onYearChange}
                      />
                    </Col>
                  </Row>
                </div>
              }
            >
              <TabPane
                forceRender
                tab={getTabTitle(TabsOptions.REVENUE)}
                key={TabsOptions.REVENUE}
              >
                <RevenueDataList
                  selectedYear={selectedYear}
                  loading={results.isLoading}
                  currencyIso={currency}
                  items={revenueData}
                  type={tabActiveKey}
                  isConsolidate={isConsolidate}
                />
              </TabPane>
              <TabPane
                forceRender
                tab={getTabTitle(TabsOptions.SALES)}
                key={TabsOptions.SALES}
              >
                <RevenueDataList
                  selectedYear={selectedYear}
                  loading={results.isLoading}
                  currencyIso={currency}
                  items={revenueData}
                  type={tabActiveKey}
                  isConsolidate={isConsolidate}
                />
              </TabPane>
              {/* <TabPane
                forceRender
                tab={getTabTitle(TabsOptions.AVERAGE_HOUR)}
                key={TabsOptions.AVERAGE_HOUR}
              >
                <RevenueDataList
                  selectedYear={selectedYear}
                  loading={results.isLoading}
                  currencyIso={currency}
                  items={revenueData}
                  type={tabActiveKey}
                />
              </TabPane> */}
            </Tabs>
          </Card>
        </Wrapper>
      </div>
    </ErrorBoundary>
  );
};
