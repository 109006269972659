import moment from "moment";
import { api } from "api/apiHelper";
import { Endpoints } from "api/apiConst";
import { DateFormats, Periods } from "date/dateConst";
import { ProjectIncludes } from "projects/projectConst";
import {
  InvoicesType,
  ProjectSalesItemType,
  ProjectType,
  ProjectAllocationRequestType,
  AllocationRequestType,
} from "projects/projectType";
import { UtilHelper } from "app/utilHelper";
import { TeamMemberType } from "./ProjectTeamMembers/ProjectTeamMembers";
import { ApiResponse } from "../api/models";
import { OutputTypes } from "allocations/allocationType";
import { RolePrimarySkillType } from "roles/roleType";

const ALL_TIME_START_DATE = "2018-01-01";

const defaultSearch = {
  period_length: Periods.MONTH,
  period_start: moment()
    .startOf("month")
    .format(DateFormats.API_DATE),
};

export const getAllProjects = (search?: {
  period_length: Periods;
  period_start: string;
  is_system_generated?: boolean;
  include?: ProjectIncludes[];
}) => {
  return api
    .get(Endpoints.PROJECTS, {
      params: {
        ...defaultSearch,
        ...search,
      },
    })
    .then((response: any) => response);
};

export const getAllInterCompanyProjects = (search?: {
  q?: string;
  is_system_generated?: boolean;
}): Promise<ApiResponse<ProjectType[]>> => {
  // If search exists remove all undefined values
  search && UtilHelper.removeUndefined(search);

  return api
    .get(Endpoints.PROJECTS, {
      params: {
        inter_company_project: false,
        archived: false,
        ...search,
      },
    })
    .then(response => response.data);
};

export const getAllProjectsQuery = (search?: {
  q?: string;
  inter_company_project?: boolean;
  is_system_generated?: boolean;
  archived?: boolean;
}) => {
  // If search exists remove all undefined values
  search && UtilHelper.removeUndefined(search);

  return api
    .get(Endpoints.PROJECTS, {
      params: {
        ...search,
      },
    })
    .then(response => response.data);
};

export const getProjectById = (id: string, includes?: ProjectIncludes[]) => {
  return api
    .get(Endpoints.PROJECTS + "/" + id, {
      params: {
        include: includes,
        all_localizations: true,
      },
    })
    .then(response => response);
};

export const getCurrentRatesByProjectId = (
  projectId: string,
  yearMonth: string,
  includes?: ProjectIncludes[]
) => {
  return api
    .get(`${Endpoints.PROJECTS}/${projectId}/month/${yearMonth}`, {
      params: {
        include: includes,
        all_localizations: true,
      },
    })
    .then(response => response);
};

export const getProjectSaleItemsById = async (id: string, params: any) => {
  const url = `${Endpoints.PROJECTS}/${id}/sale-items`;
  try {
    const resp = await api.get<ApiResponse<any>>(url, {
      params: { ...params },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export const getValueBreakdown = (
  id: string,
  periodType: string = Periods.MONTH,
  startDate: string,
  endDate: string
) => {
  return api
    .get(Endpoints.PROJECTS + "/" + id + Endpoints.PROJECTS_VALUE_BREAKDOWN, {
      params: {
        period: periodType,
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then(response => response);
};

export const getPlSummary = (
  id: string,
  periodType: string = Periods.MONTH,
  consolidated: boolean,
  startDate: string,
  endDate: string
) => {
  return api
    .get(Endpoints.PROJECTS + "/" + id + Endpoints.PROJECTS_PROFIT_LOSS, {
      params: {
        period: periodType,
        consolidated,
        start_date: startDate,
        end_date: endDate,
      },
    })
    .then(response => response);
};

export const getCostBreakdown = (
  id: string,
  periodType: string = Periods.MONTH
) => {
  return api
    .get(Endpoints.PROJECTS + "/" + id + Endpoints.PROJECTS_COST_BREAKDOWN, {
      params: {
        period: periodType,
      },
    })
    .then(response => response);
};

export const getAllocation = (
  id: string,
  periodType: Periods = Periods.MONTH,
  dataType: OutputTypes = OutputTypes.HOURS,
  params?: any
) => {
  return api
    .get(Endpoints.PROJECTS + "/" + id + Endpoints.PROJECTS_ALLOCATIONS, {
      params: {
        period: periodType,
        output: dataType,
        ...params,
      },
    })
    .then(response => response);
};

export const createProject = (data: ProjectType) => {
  const url = Endpoints.PROJECTS;
  return api.post(url, data).then((resp: any) => resp.data);
};

export const editProject = (projectId: number, project: ProjectType) => {
  const url = `${Endpoints.PROJECTS}/${projectId}`;

  return api
    .patch(url, project)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

export const recalculateProject = (projectId: number) => {
  const url = `${Endpoints.PROJECTS}/${projectId}/trigger-calculation`;

  return api
    .get(url)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** archived project */
export const archivedProject = (projectId: string, isArchive: boolean) => {
  const url = `${Endpoints.PROJECTS}/${projectId}`;

  return api
    .patch(url, { archived: isArchive })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/** delete project */
export const deleteProjectById = (
  id: string
): Promise<ApiResponse<ProjectType>> => {
  const url = `${Endpoints.PROJECTS}/${id}`;
  return api.delete(url).then(response => response.data);
};

/** Get roles for specific project */
export const getRolesByProjectId = (search: {
  id: string | number;
  periodType?: string;
  startDate?: string;
  endDate?: string;
  format?: string;
}) => {
  if (!search.periodType) {
    search.periodType = Periods.ALL;
  }

  return api
    .get(Endpoints.PROJECTS + "/" + search.id + Endpoints.PROJECTS_ROLES, {
      params: {
        period: search.periodType,
        start_date_custom: search.startDate,
        end_date_custom: search.endDate,
        ...{ format: search.format },
      },
    })
    .then(response => response);
};

/** Get New API roles for specific project */
export const getProjectRatesReportByProjectId = (search: {
  id: string | number;
  periodType?: string;
  startDate?: string;
  endDate?: string;
  format?: string;
  params?: any;
}) => {
  if (!search.periodType) {
    search.periodType = Periods.ALL;
  }

  return api
    .get(Endpoints.PROJECT_RATE_CARDS, {
      params: {
        period: search.periodType,
        start_date_custom: search.startDate,
        end_date_custom: search.endDate,
        project_id: search.id,
        ...{ format: search.format },
        ...search.params,
      },
    })
    .then(response => response);
};

/** Get New API roles for specific project allocation */
export const getAllocationProjectRatesReportByProjectId = (search: {
  id: string | number;
  periodType?: string;
  startDate?: string;
  endDate?: string;
  format?: string;
  params?: any;
}) => {
  if (!search.periodType) {
    search.periodType = Periods.ALL;
  }

  return api
    .get(Endpoints.ALLOCATION_PROJECT_RATE_CARDS, {
      params: {
        period: search.periodType,
        start_date_custom: search.startDate,
        end_date_custom: search.endDate,
        project_id: search.id,
        ...{ format: search.format },
        ...search.params,
      },
    })
    .then(response => response);
};

export const getTeamMembers = (search: {
  id: string;
  periodType?: string;
  startDate?: string;
  endDate?: string;
  format?: string;
  params?: any;
}) => {
  if (!search.periodType) {
    search.periodType = Periods.ALL;
  }

  let dateParams =
    search.periodType === Periods.CUSTOM
      ? {
          start_date_custom: search.startDate,
          end_date_custom: search.endDate,
        }
      : { start_date: search.startDate };

  return api
    .get(
      Endpoints.PROJECTS +
        "/" +
        search.id +
        Endpoints.PROJECTS_TEAM_MEMBERS_REPORT,
      {
        params: {
          period: search.periodType,
          ...dateParams,
          ...{ format: search.format },
          ...search.params,
        },
      }
    )
    .then(response => response);
};

export const getTeamMemberById = (search: {
  id?: string;
  itemId?: string;
  periodType?: string;
  startDate?: string;
  endDate?: string;
  format?: string;
}): Promise<ApiResponse<TeamMemberType>> => {
  if (!search.periodType) {
    search.periodType = Periods.ALL;
  }

  let dateParams =
    search.periodType === Periods.CUSTOM
      ? {
          start_date_custom: search.startDate,
          end_date_custom: search.endDate,
        }
      : { start_date: search.startDate };

  const url = `${Endpoints.PROJECTS}/${search.id}${Endpoints.PROJECTS_TEAM_MEMBERS}/${search.itemId}`;

  return api
    .get(url, {
      params: {
        period: search.periodType,
        ...dateParams,
        ...{ format: search.format },
      },
    })
    .then(response => response.data);
};

export const deleteTeamMemberFromProject = (
  projectId: string,
  memberId: string
) => {
  const url = `${Endpoints.PROJECTS}/${projectId}${Endpoints.PROJECT_MEMBERS}/${memberId}`;

  return api.delete(url);
};

export const deleteProjectsRateCard = (
  id: number,
  projectId: string,
  month: string
) => {
  const url = `${Endpoints.PROJECTS}/${projectId}${Endpoints.PROJECTS_ROLES}/${id}/month/${month}`;

  return api.delete(url);
};

export const deleteNewProjectsRateCard = (id: number | string) => {
  const url = `${Endpoints.PROJECT_RATE_CARDS}/${id}`;

  return api.delete(url);
};

export const getTeamMembersExportData = (search: {
  id: string;
  startDate?: string;
  period?: string;
  format?: string;
}) => {
  let dateParams = {};

  dateParams = {
    start_date:
      search.period === Periods.ALL
        ? moment(ALL_TIME_START_DATE).format(DateFormats.API_DATE)
        : search.startDate,
  };

  return api
    .get(Endpoints.REPORTS + "/" + search.id, {
      params: {
        ...dateParams,
        period: search.period,
        ...{ format: search.format },
      },
      responseType: "arraybuffer",
    })
    .then(response => response.data);
};

export const assignTeamMember = (
  projectId: string,
  memberId: number,
  projectRateCards: RolePrimarySkillType[]
) => {
  return api.post(
    Endpoints.PROJECTS +
      "/" +
      projectId +
      Endpoints.PROJECT_MEMBERS +
      "/" +
      memberId,
    {
      project_rate_cards: projectRateCards,
    }
  );
};

export const editTeamMember = (
  projectId: string,
  memberId: number,
  projectRateCards: RolePrimarySkillType[]
) => {
  return api.patch(
    Endpoints.PROJECTS +
      "/" +
      projectId +
      Endpoints.PROJECT_MEMBERS +
      "/" +
      memberId,
    {
      project_rate_cards: projectRateCards,
    }
  );
};

export const setRolesRates = (
  projectId: string,
  rates: any[],
  date: string
) => {
  const url = `${Endpoints.PROJECTS}/${projectId}${Endpoints.PROJECTS_ROLES_RATES}/${date}`;

  return api.patch(url, { rates });
};

export const setProjectRates = (
  projectId: string,
  rate_cards: any[],
  month: string
) => {
  const url = `${Endpoints.PROJECT_RATE_CARDS}/project/${projectId}/month/${month}`;

  return api.patch(url, { rate_cards });
};

export const createProjectSalesItem = (item: ProjectSalesItemType) => {
  return api.post(Endpoints.ITEMS, item);
};

/**
 * Get sales item by ID
 * @param salesItemId
 */
export const getProjectSalesItemById = (salesItemId: number) => {
  const url = `${Endpoints.ITEMS}/${salesItemId}`;

  return api
    .get(url)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * update sales item by ID
 * @param salesItemId
 */
export const updateProjectSalesItemById = (
  salesItemId: number,
  params?: object
) => {
  const url = `${Endpoints.ITEMS}/${salesItemId}`;

  return api
    .patch(url, { ...params })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err.response.data;
    });
};

/**
 * delete sales item by ID
 * @param salesItemId
 */
export const deleteProjectSalesItemById = (
  salesItemId: number
): Promise<void> => {
  const url = `${Endpoints.ITEMS}/${salesItemId}`;

  return Promise.resolve(
    api
      .delete(url)
      .then((resp: any) => resp.data)
      .catch((err: any) => {
        throw err.response.data;
      })
  );
};

/**
 * Get progress graph data
 */

export const getProgressGraph = (
  projectId: string,
  startDate?: string,
  endDate?: string
) => {
  const url = `${Endpoints.PROJECTS}/${projectId}${Endpoints.PROJECT_PROGRESS_GRAPH}/${startDate}/${endDate}`;
  return api.get(url).then(response => response.data);
};

export const getHoursGraph = (
  projectId: string,
  startDate?: string,
  endDate?: string
) => {
  const url = `${Endpoints.PROJECTS}/${projectId}${Endpoints.PROJECT_HOURS_GRAPH}/${startDate}/${endDate}`;
  return api.get(url).then(response => response.data);
};

/** Get time sheet report between two dates */
export const getTimeSheetReport = (
  project_id: number,
  from: string,
  to: string,
  period: string
) => {
  const url = `${Endpoints.REPORTS_TIME_SHEET}`;

  return api
    .get(url, {
      params: {
        project_id,
        from,
        to,
        period,
      },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};

/** Get invoices data by invoices id */
export const getInvoiceById = (
  id: string
): Promise<ApiResponse<InvoicesType>> => {
  const url = `${Endpoints.INVOICES}/${id}`;
  return api.get(url).then(response => response.data);
};

/** create invoices */
export const createInvoice = (
  data: InvoicesType
): Promise<ApiResponse<InvoicesType>> => {
  const url = `${Endpoints.INVOICES}`;
  return api.post(url, data).then(response => response.data);
};

/** update invoices */
export const updateInvoice = (
  data: InvoicesType,
  id: string
): Promise<ApiResponse<InvoicesType>> => {
  const url = `${Endpoints.INVOICES}/${id}`;
  return api.patch(url, data).then(response => response.data);
};

/** Get invoices data by project id */
export const getInvoicesByProjectId = (id: string, params?: any) => {
  const url = `${Endpoints.PROJECTS}/${id}${Endpoints.PROJECT_INVOICES}`;
  return api
    .get(url, { params: { ...params } })
    .then(response => response.data);
};

/** delete invoices */
export const deleteInvoice = (
  id: string
): Promise<ApiResponse<InvoicesType>> => {
  const url = `${Endpoints.INVOICES}/${id}`;
  return api.delete(url).then(response => response.data);
};

/** Get tracking report between two dates */
export const getTrackingReport = (period_start: string, params?: any) => {
  const url = `${Endpoints.REPORTS_PROJECTS}`;

  return api
    .get(url, {
      params: {
        period_start,
        ...params,
      },
    })
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};

/** get all allocation requests */
export const getAllocationRequests = (
  params: any
): Promise<ApiResponse<AllocationRequestType>> => {
  const url = `${Endpoints.PROJECTS}/allocation-requests`;
  return api.get(url, { params }).then(response => response.data);
};

/** get  allocation request */
export const getAllocationRequest = (
  id: number,
  projectId: number
): Promise<ApiResponse<AllocationRequestType>> => {
  const url = `${Endpoints.PROJECTS}/${projectId}/allocation-requests/${id}`;
  return api.get(url).then(response => response.data);
};

/** get  allocation request by role */
export const getAllocationRequestsByRole = (
  roleId: number,
  projectId: string,
  params?: any
): Promise<ApiResponse<AllocationRequestType>> => {
  const url = `${Endpoints.PROJECTS}/${projectId}/roles/${roleId}/allocation-requests`;
  return api
    .get(url, {
      params: {
        ...params,
      },
    })
    .then(response => response.data);
};

/** get  allocation request by role */
export const getAllocationRequestsByProjectRateId = (
  roleId: number | null,
  rateId: number | null,
  projectId: string | number,
  params?: any
): Promise<ApiResponse<AllocationRequestType>> => {
  const url = `${Endpoints.PROJECT_RATE_CARDS}/allocation-requests`;
  return api
    .get(url, {
      params: {
        role_id: roleId ? roleId : "null",
        project_rate_card_id: rateId ? rateId : "null",
        project_id: projectId,
        ...params,
      },
    })
    .then(response => response.data);
};

/** create allocation request */
export const createAllocationRequest = (
  id: string,
  data: ProjectAllocationRequestType
): Promise<ApiResponse<AllocationRequestType>> => {
  const url = `${Endpoints.PROJECTS}/${id}/allocation-requests`;
  return api.post(url, data).then(response => response.data);
};

/** update allocation request */
export const updateAllocationRequest = (
  id: number,
  projectId: string,
  data: ProjectAllocationRequestType
): Promise<ApiResponse<AllocationRequestType>> => {
  const url = `${Endpoints.PROJECTS}/${projectId}/allocation-requests/${id}`;
  return api.patch(url, data).then(response => response.data);
};

/** delete allocation request */
export const deleteAllocationRequest = (
  id: number,
  projectId: string
): Promise<ApiResponse<AllocationRequestType>> => {
  const url = `${Endpoints.PROJECTS}/${projectId}/allocation-requests/${id}`;
  return api.delete(url).then(response => response.data);
};

/** Get signed url for report download */
export const getProjectsReportSignedUrl = (key: string) => {
  const url = `${Endpoints.REPORTS_PROJECTS_DOWNLOAD}/${key}`;

  return api
    .get(url)
    .then((resp: any) => resp.data)
    .catch((err: any) => {
      throw err;
    });
};
