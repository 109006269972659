/** Private route imports */
import * as MY_TIMESHEET_ROUTES from "../time-plan/my/router.mytimesheets";
import * as ALLOCATION_ROUTES from "../allocations/router.allocation";
import * as PROJECTS_ROUTES from "../projects/router.projects";
import * as CLIENTS_ROUTES from "../clients/router.clients";
import * as CONTENT_ROUTES from "../cases/router.cases";
import * as PUBLISH_CASES from "../publish-cases/router.cases";
import * as TIMESHEETS_ROUTES from "../time-plan/router.timesheets";
import * as INSIGHTS_ROUTES from "../bi/router.insights";
import * as SETTINGS_ROUTES from "../settings/router.settings";
import * as CURRENCY_SETTINGS_ROUTES from "../currencies/router.currencies";
import * as GEOGRAPHY_SETTINGS_ROUTES from "../geography/router.geography";
import * as CASES_SETTINGS_ROUTES from "../geography/router.cases";
import * as EMPLOYEES_ROUTES from "../employees/router.employees";
import * as PERMISSION_SETTINGS_ROUTES from "../identity/permissions/router.permissions";
import * as USERS_PROFILE_ROUTES from "../identity/Profile/router.profile";
import * as USER_SCOPES_ROUTES from "identity/userScopes/router.userScopes";
import * as ABOUT_ROUTES from "about/router.about";
import * as AUDIT_LOGS_ROUTES from "../audit-logs/router.auditLogs";
import * as TRAINING_ROUTES from "training/router.training";
import * as THEME_ROUTES from "theme/router.theme";
/** Public route imports */
import * as AUTH_ROUTES from "../identity/router.authentication";

/** Private Routes */
export const PRIVATE_ROUTES = {
  ...MY_TIMESHEET_ROUTES,
  ...ALLOCATION_ROUTES,
  ...PROJECTS_ROUTES,
  ...CLIENTS_ROUTES,
  ...TIMESHEETS_ROUTES,
  ...INSIGHTS_ROUTES,
  ...SETTINGS_ROUTES,
  ...CURRENCY_SETTINGS_ROUTES,
  ...GEOGRAPHY_SETTINGS_ROUTES,
  ...CASES_SETTINGS_ROUTES,
  ...EMPLOYEES_ROUTES,
  ...PERMISSION_SETTINGS_ROUTES,
  ...USERS_PROFILE_ROUTES,
  ...USER_SCOPES_ROUTES,
  ...ABOUT_ROUTES,
  ...CONTENT_ROUTES,
  ...PUBLISH_CASES,
  ...AUDIT_LOGS_ROUTES,
  ...TRAINING_ROUTES,
  ...THEME_ROUTES,
};

/** Public Routes */
export const PUBLIC_ROUTES = { ...AUTH_ROUTES };

/** Error Routes */
export const ERROR_ROUTES = {
  ERROR_PATH: "/error",
  NOT_FOUND_PATH: "/notfound",
  REDIRECT_PATH: "/service-maintaince",
};

/** SiderMenu Items */
export const SIDER_MENU = [
  MY_TIMESHEET_ROUTES.MY_TIMESHEET_SCREEN,
  ALLOCATION_ROUTES.ALLOCATION,
  PROJECTS_ROUTES.PROJECTS_SCREEN,
  CLIENTS_ROUTES.CLIENTS_SCREEN,
  TIMESHEETS_ROUTES.TIMESHEETS,
  INSIGHTS_ROUTES.INSIGHTS_SCREEN,
  EMPLOYEES_ROUTES.EMPLOYEES,
  SETTINGS_ROUTES.SETTINGS_SCREEN,
  PUBLISH_CASES.PUBLISH_CASES_LIST_SCREEN,
  AUDIT_LOGS_ROUTES.AUDIT_LOGS_SCREEN,
  TRAINING_ROUTES.TRAINING_SCREEN,
];
