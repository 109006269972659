import { loadingRematchModel } from "../../loading";
import * as clientsApi from "../clientsApi";
import * as projectsApi from "../../projects/projectsApi";

export const clientOverviewModel = loadingRematchModel(async (client: any) =>
  clientsApi.getClientById(client.id)
);

export const clientProjectsOverviewModel = loadingRematchModel(
  async (params: any) => {
    const projects = await projectsApi.getAllProjects(params);
    return projects.data;
  }
);
