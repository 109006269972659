import AllocationCircle from "../AllocationCircle/AllocationCircle";
import { Link } from "react-router-dom";
import React from "react";
import styles from "./AllocationLink.module.scss";
import { AllocationColors } from "allocations/allocationConst";
import ShowSpace from "ui/ShowSpace";

interface Props {
  color: AllocationColors;
  text: any;
  link: string;
}

const AllocationLink = ({ color, link, text }: Props) => (
  <div className={styles.allocationLink}>
    <AllocationCircle color={color} />
    <Link to={link}>
      <ShowSpace str={text} />
    </Link>
  </div>
);

export default AllocationLink;
