/**
 * Project includes.
 * Used for querying the service with arguments such as,
 * creator, project_responsible, currency, and so on.
 * @readonly
 * @enum
 */
export enum ProjectIncludes {
  BUDGET_OVERVIEW = "budget_overview",
  CLIENT = "client",
  CLIENT_RESPONSIBLE = "client_responsible",
  RESPONSIBLE = "responsible",
  CURRENT_RATES = "current_rates",
  CREATOR = "creator",
  CURRENCY = "currency",
  ITEMS = "items",
  OFFICE = "office",
  PROJECT_RESPONSIBLE = "project_responsible",
  PROJECT_CLIENT_RESPONSIBLE = "project_client_responsible",
  SALES_ITEMS = "sales_items",
  SOURCE_PROJECT = "source_project",
  LOCAL_PROJECTS = "local_projects",
  REGION = "region",
}

/**
 * Stages that the user can pick between when creating projects
 * @readonly
 * @enum {string}
 */
export enum ProjectStages {
  POTENTIAL = "potential",
  ACTIVE = "active",
  PRODUCTION = "production",
  ARCHIVED = "archived",
}

/**
 * Stages that the project can have, that are read-only
 * @readonly
 * @enum {string}
 */
export enum ProjectStagesRemaining {
  TIME_OFF = "time-off",
  INTERNAL = "internal",
}

/**
 * Project Types
 * @readonly
 * @enum {string}
 */
export enum ProjectTypes {
  FIXED = "fixed",
  TIME_MATERIAL = "time-material",
  TIME_BOX = "time-box",
  SPRINT_PRICE = "sprint-price",
  TIME_MATERIAL_HOURLY = "time-material-hourly",
  TIME_MATERIAL_MONTHLY = "time-material-monthly",
  FIXED_PRICE_MANUAL_HOURLY = "fixed-price-manual-hourly",
  FIXED_PRICE_MANUAL_MONTHLY = "fixed-price-manual-monthly",
}

/**
 * Project drawers. Used for showing
 * the correct drawer on the project
 * details page.
 * @readonly
 * @enum {string}
 */
export enum ProjectDrawers {
  RATE_CARD = "rate_card",
  TEAM_MEMBER = "team_member",
  EDIT_TEAM_MEMBER = "edit_team_member",
  SALES_ITEMS = "sales_items",
  EDIT_SALES_ITEMS = "edit_sales_items",
  INVOICES = "invoices",
  EDIT_INVOICES = "edit_invoices",
  PROJECT_STAGE = "project_stage",
  CREATE_ALLOCATION = "create_alocation",
  EDIT = "edit",
  CLOSE = "close",
}

/**
 * ProjectSalesItemTypes
 * @readonly
 * @enum {string}
 */
export enum ProjectSalesItemTypes {
  BUDGET = "budget",
  VALUE = "value",
  COST = "cost",
  BURN = "burn",
}

export enum ProjectCostItemTypes {
  FREELANCE = "freelance",
  REINVOICE = "reinvoice",
  OTHER = "other",
}

export enum ProjectValueItemTypes {
  HOSTING = "hosting",
  MONITORING = "monitoring",
  ALWAYS = "always",
  OTHER = "other",
}

export enum ProjectEstimationItemTypes {
  ROLE = "role",
  BUDGET = "budget",
}

export enum ProjectDistributionType {
  AUTO = "auto",
  MANUAL = "manual",
  FIXED_PRICE_MANUAL = "fixed-price-manual-",
}

export enum ProjectEstimationUnitItemTypes {
  HOURS = "hour",
  DAYS = "day",
  WEEKS = "week",
  MONTHS = "month",
}

export enum ProjectColumnGroups {
  PROJECT_TOTALS = "projectTotals",
  RUNNING_TOTALS = "runningTotals",
  PERIOD_TOTALS = "periodTotals",
}

export enum ProjectTableApiKeys {
  ALLOCATED_HOURS = "allocated_hours",
  FUTURE_ALLOCATED_HOURS = "future_allocated_hours",
  TRACKED_HOURS = "tracked_hours",
  PROJECTED_TRACKED_HOURS = "projected_tracked_hours",
  ALLOCATED_VALUE = "allocated_value",
  FUTURE_ALLOCATED_VALUE = "future_allocated_value",
  TRACKED_VALUE = "tracked_value",
  PROJECTED_TRACKED_VALUE = "projected_tracked_value",
  VALUE_ITEMS = "value_items",
  REVENUE_RECOGNISED = "revenue_recognised",
  TOTAL_DISTRIBUTED_REVENUE = "total_distributed_revenue",
  PROJECTED_SPEND = "projected_spend",
  PROJECTED_VARIANCE = "projected_variance",
  GROSS_PROFIT = "actual_gross_profit",
  GROSS_PROFIT_PERCENTAGE = "gross_profit_percentage",
  DIRECTED_COST = "direct_cost",
  NET_SOLD = "net_sold",
  ROLE_NAME = "role_name",
  ALLOCATED_AMOUNT = "allocated_amount",
  TRACKED_AMOUNT = "tracked_amount",
  CURRENT_RATE = "current_rate",
}

export const ProjectValueBreakDownHoursHeaderKeys = [
  ProjectTableApiKeys.ALLOCATED_HOURS,
  ProjectTableApiKeys.FUTURE_ALLOCATED_HOURS,
  ProjectTableApiKeys.TRACKED_HOURS,
  ProjectTableApiKeys.PROJECTED_TRACKED_HOURS,
];

export const ProjectValueBreakDownGrossProfitPercentage = [
  ProjectTableApiKeys.GROSS_PROFIT_PERCENTAGE,
];

export const ProjectRolesHoursHeaderKeys = [
  ProjectTableApiKeys.ALLOCATED_HOURS,
  ProjectTableApiKeys.TRACKED_HOURS,
];

export const ProjectRolesAmountHeaderKeys = [
  ProjectTableApiKeys.TRACKED_AMOUNT,
  ProjectTableApiKeys.ALLOCATED_AMOUNT,
  ProjectTableApiKeys.CURRENT_RATE,
];

/**
 * Columns for the projects overview screen,
 * that can be toggled.
 * @readonly
 * @enum {string}
 */
export enum ProjectColumns {
  BUDGET = "budget",
  BUDGET_STATUS = "budgetStatus",
  BUDGET_REMAINING = "budgetRemaining",
  AVG_HOURLY_RATE = "avgHourlyRate",
  PROFITABILITY = "profitability",
  TRACKED = "tracked_value_period",
  TRACKED_HOUR = "trackedHours",
  ALLOCATED = "allocated",
  REMAINING_ALLOCATION = "remainingAllocation",
  VARIANCE = "variance",
  TOTAL_SOLD = "totalSold",
  PROJECTED_VARIANCE = "projected_variance_period",
  PROJECT_PROJECTED_VARIANCE = "projectedVariance",
  // FUTURE_ALLOCATION = "future_allocated_value_period",
  FUTURE_ALLOCATION_HOUR = "futureAllocatedHours",
  EXTERNAL_COST = "externalCost",
  DISTRIBUTED_REVENUE = "projected_revenue_period",
  NET_SOLD = "netSold",
  DIRECT_COST = "directCost",
  TOTAL_COST = "totalCost",
  // GROSS_PROFIT = "actual_gross_profit_period",
  GROSS_PROFIT_PERCENTAGE = "grossProfitPercentage",
  PROJECT_SPEND = "projectedSpend",
  // TOTAL_ALLOCATION_HOUR = "totalAllocatedHours",
  // TOTAL_ALLOCATION = "total_allocated_value_period",
  PROJECT_SPEND_VALUE = "projectedSpendValue",
  PROJECT_SPEND_HOUR = "projectedSpendHours",
  PROJECTED_TRACKED_VALUE = "projected_tracked_value_period",
  PROJECTED_TRACKED_HOUR = "projectedHours",
  AVG_HOURLY_RATE_TRACKED = "avgHourlyRateTracked",
  AVG_HOURLY_RATE_PRJECTED = "avgHourlyRateProjected",
  REVENUE_RECOGNISED = "revenueRecognised",
  REVENUE_PROJECTED = "revenueProjected",
  INVOICED = "invoiced",
  ACCRUED_REVENUE = "accruedRevenue",
  UNINVOICED = "uninvoiced",
  TYPE = "Type",
  INVOICED_TERMS = "Invoice Terms",
}

export enum ProjectGraphTypes {
  "Progress" = 0,
  "Hours" = 1,
}

export const InitialQueryParams = {
  projectTotals: [
    ProjectColumns.TOTAL_SOLD,
    ProjectColumns.PROJECT_PROJECTED_VARIANCE,
  ],
  periodTotals: [
    // ProjectColumns.GROSS_PROFIT,
    ProjectColumns.PROJECTED_VARIANCE,
  ],
};

export const CheckedColumns = [
  ProjectColumns.TYPE,
  ProjectColumns.INVOICED_TERMS,
];

export const PeriodTotals = [
  // ProjectColumns.TOTAL_ALLOCATION,
  ProjectColumns.TRACKED,
  // ProjectColumns.FUTURE_ALLOCATION,
  // ProjectColumns.GROSS_PROFIT,
  ProjectColumns.PROJECTED_VARIANCE,
  ProjectColumns.PROJECTED_TRACKED_VALUE,
  ProjectColumns.DISTRIBUTED_REVENUE,
];

export const ProjectTotals = [
  // ProjectColumns.TOTAL_ALLOCATION_HOUR,
  ProjectColumns.TRACKED_HOUR,
  ProjectColumns.FUTURE_ALLOCATION_HOUR,
  ProjectColumns.PROJECTED_TRACKED_HOUR,
  // ProjectColumns.ALLOCATED,
  ProjectColumns.TOTAL_SOLD,
  ProjectColumns.REVENUE_RECOGNISED,
  ProjectColumns.REVENUE_PROJECTED,
  ProjectColumns.PROJECT_SPEND,
  ProjectColumns.TOTAL_COST,
  ProjectColumns.PROJECT_PROJECTED_VARIANCE,
  ProjectColumns.ACCRUED_REVENUE,
  ProjectColumns.INVOICED,
  ProjectColumns.UNINVOICED,
];

export const DEFAULT_PROJECTS_LIMIT = 20;

export const Priority = ["high", "low", "medium"];

export const FILTER_OPTION_ALL = 3;

export const projectVarietyFilterOptions = [
  { value: 1, name: "allProjects" },
  { value: 2, name: "interCompanyProjects" },
  { value: 3, name: "sourceProjects" },
];
