import { StepType } from "../ui/tour/tourType";

export const InsightsProjectSteps = (t: any) => {
  const steps: StepType[] = [
    {
      title: t("tourSteps.insightsProjectPageTitle"),
      content: t("tourSteps.insightsProjectPageDescription"),
      target: ".tour-step-main",
      disableBeacon: true,
      placement: "center",
    },
    {
      title: t("tourSteps.insightsProjectTitleFilter"),
      content: t("tourSteps.insightsProjectDescriptionFilter"),
      target: ".tour-step-insights-project-filter",
    },
    {
      title: t("tourSteps.insightsProjectTitleOverview"),
      content: t("tourSteps.insightsProjectDescriptionOverview"),
      target: ".tour-step-tab",
    },
  ];
  return steps;
};

export const InsightsBillableSteps = (t: any) => {
  const steps: StepType[] = [
    {
      title: t("tourSteps.insightsBillablePageTitle"),
      content: t("tourSteps.insightsBillablePageDescription"),
      target: ".tour-step-main",
      disableBeacon: true,
      placement: "center",
    },
    {
      title: t("tourSteps.insightsBillableTitleFilter"),
      content: t("tourSteps.insightsBillableDescriptionFilter"),
      target: ".tour-step-insights-billable-filter",
    },
    {
      title: t("tourSteps.insightsBillableTitleOverview"),
      content: t("tourSteps.insightsBillableDescriptionOverview"),
      target: ".tour-step-insights-billable-overview",
    },
  ];
  return steps;
};

export const InsightsAllocationSteps = (t: any) => {
  const steps: StepType[] = [
    {
      title: t("tourSteps.insightsAllocationPageTitle"),
      content: t("tourSteps.insightsAllocationPageDescription"),
      target: ".tour-step-main",
      disableBeacon: true,
      placement: "center",
    },
    {
      title: t("tourSteps.insightsAllocationTitleFilter"),
      content: t("tourSteps.insightsAllocationDescriptionFilter"),
      target: ".tour-step-insights-allocation-filter",
    },
    {
      title: t("tourSteps.insightsAllocationTitleOverview"),
      content: t("tourSteps.insightsAllocationDescriptionOverview"),
      target: ".tour-step-insights-allocation-overview",
    },
  ];
  return steps;
};

export const InsightsRevenueSteps = (t: any) => {
  const steps: StepType[] = [
    {
      title: t("tourSteps.insightsRevenuePageTitle"),
      content: t("tourSteps.insightsRevenuePageDescription"),
      target: ".tour-step-main",
      disableBeacon: true,
      placement: "center",
    },
    {
      title: t("tourSteps.insightsRevenueTitleOverview"),
      content: t("tourSteps.insightsRevenueDescriptionOverview"),
      target: ".tour-step-insights-revenue-overview",
    },
    {
      title: t("tourSteps.insightsRevenueTitleRecalculateButton"),
      content: t("tourSteps.insightsRevenueDescriptionRecalculateButton"),
      target: ".tour-step-insights-revenue-recalculate",
    },
  ];
  return steps;
};

export const InsightsSkillsSteps = (t: any) => {
  const steps: StepType[] = [
    {
      title: t("tourSteps.insightsSkillsPageTitle"),
      content: t("tourSteps.insightsSkillsPageDescription"),
      target: ".tour-step-main",
      disableBeacon: true,
      placement: "center",
    },
    {
      title: t("tourSteps.insightsSkillsTitleOverview"),
      content: t("tourSteps.insightsSkillsDescriptionOverview"),
      target: ".tour-step-insights-skills-overview",
    },
  ];
  return steps;
};
